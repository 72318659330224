// src/features/auth/authReducer.js
import { createReducer } from '@reduxjs/toolkit';
import {
    clearMessages,
    loginStart, loginSuccess, loginFailure,
    registerStart, registerSuccess, registerFailure,
    forgotPasswordStart, forgotPasswordSuccess, forgotPasswordFailure,
    verifyEmailStart, verifyEmailSuccess, verifyEmailFailure,
    resetPasswordStart, resetPasswordSuccess, resetPasswordFailure,
    logout
} from './authActions';

const initialState = {
    user: null,
    loading: false,
    successMessage: null,
    errorMessage: null,
};

const authReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(clearMessages, (state) => {
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(loginStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(loginSuccess, (state, action) => {
            state.user = action.payload.data;
            state.loading = false;
            state.successMessage = action.payload.message;
        })
        .addCase(loginFailure, (state, action) => {
            state.errorMessage = action.payload.message;
            state.loading = false;
        })

        .addCase(logout, (state) => {
            state.errorMessage = null;
            state.successMessage = null;
            state.user = null;
            state.loading = false;
        })

        .addCase(registerStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(registerSuccess, (state, action) => {
            state.loading = false;
            state.successMessage = action.payload.message;
        })
        .addCase(registerFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })

        .addCase(forgotPasswordStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(forgotPasswordSuccess, (state, action) => {
            state.loading = false;
            state.successMessage = action.payload.message;
        })
        .addCase(forgotPasswordFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })

        .addCase(verifyEmailStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(verifyEmailSuccess, (state, action) => {
            state.loading = false;
            state.successMessage = action.payload.message;
        })
        .addCase(verifyEmailFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })

        .addCase(resetPasswordStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(resetPasswordSuccess, (state, action) => {
            state.loading = false;
            state.successMessage = action.payload.message;
        })
        .addCase(resetPasswordFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        });
});

export default authReducer;
