import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import ForgotPasswordIcon from "../assets/images/password.svg";
import BackIcon from "../assets/images/back-icon.svg";

import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, clearMessages } from "../features/auth/authActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify"; // Import toast
import { Helmet } from 'react-helmet-async';


function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  const { loading, successMessage, errorMessage } = useSelector(
    (state) => state.auth
  );

  const validate = () => {
    const errors = {};

    // Check for empty fields
    if (!email) errors.email = "Email is required.";
    // Check for valid email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailRegex.test(email))
      errors.email = "Invalid email format.";
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Initial blank errors
    setErrors({});

    // Validate form inputs
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Dismiss any currently visible toasts
    toast.dismiss();

    // Dispatch Forgot password action
    let data = {
      email_or_mobile: email,
    };
    dispatch(forgotPassword(data));
  };

  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage, { autoClose: 3000, hideProgressBar: true });
      setEmail("");
    }
    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
    }
    dispatch(clearMessages());
  }, [successMessage, errorMessage, dispatch]);

  return (
    <>
      <Helmet>
        <title>Forgot Password | HealthGonia</title>
        <meta name="description" content="Reset your password to regain access to your HealthGonia account. Enter your email to receive password reset instructions." />
        <meta name="keywords" content="Forgot Password, Reset Password, Account Recovery, HealthGonia, Password Assistance" />
      </Helmet>

      <Header />
      <div className="sign-in-main password-main">
        <div className="sign-box">
          <div className="sign-box-wrap">
            <Row>
              <Col lg="12" className="text-center">
                <img
                  className="forgot-password-icon"
                  src={ForgotPasswordIcon}
                  alt=""
                />
                <h2 className="text-center">Forgot Password?</h2>
                <p className="text-center">
                  Enter your registered email address for verify your self to
                  get change password link in to your email address.
                </p>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      placeholder="Email Address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      isInvalid={!!errors.email}
                    />

                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="button-group">
                    <Link className="cutsom-bttn back-btn" to="/sign-in">
                      <img src={BackIcon} alt="" />
                    </Link>

                    <Button
                      disabled={loading}
                      className="cutsom-bttn"
                      type="submit"
                    >
                      {loading ? (
                        <FontAwesomeIcon
                          icon={faSpinner}
                          spin
                          size="lg"
                          className="spinner-icon"
                        />
                      ) : (
                        "Send Verification Link"
                      )}
                    </Button>
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
export default ForgotPassword;
