import React, { useState, useEffect } from "react";
import Header from "../component/Header";
import { Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Google from "../assets/images/google-icon.svg";
import Facebook from "../assets/images/facebook-icon.svg";
import Apple from "../assets/images/apple-icon.svg";

import { useDispatch, useSelector } from "react-redux";
import { register } from "../features/auth/authActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify"; // Import toast
import { formatPhoneNumber } from '../helpers/helpers';

import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "../Constants";
import { Helmet } from 'react-helmet-async';

function SignUp() {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const { loading, successMessage, errorMessage} = useSelector((state) => state.auth);


  const handlePhoneChange = (e) => {
    const { value } = e.target;
    setPhone(formatPhoneNumber(value));
  };

  const validate = () => {
    const errors = {};

    // Check for empty fields
    if (!fullName) errors.fullName = "Full Name is required.";
    if (!email) errors.email = "Email is required.";
    if (!phone) errors.phone = "Phone Number is required.";
    if (!password) errors.password = "Password is required.";
    if (password !== confirmPassword)
      errors.confirmPassword = "Passwords must match.";
    if (!termsAccepted)
      errors.termsAccepted = "You must accept the terms and conditions.";

    // Check for valid email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailRegex.test(email))
      errors.email = "Invalid email format.";

    // Check password length
    if (password && password.length < 8)
    errors.password = "Password must be at least 8 characters long.";

    if (phone && phone.length !== 12)
    errors.phone = "Please enter valid phone number.";

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Initial blank errors
    setErrors({});

    // Validate form inputs
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Dismiss any currently visible toasts
    toast.dismiss();

    // Dispatch registration action
    let data = {
      name: fullName,
      email: email,
      mobile_number: phone,
      password: password,
      confirm_password: password,
      terms_conditions: termsAccepted ? 1 : 0,
    };

    dispatch(register(data));
  };


  // Show success and error toasts
  useEffect(() => {
    if (successMessage) {
      setFullName('');
      setEmail('');
      setPhone('');
      setPassword('');
      setConfirmPassword('');
      setTermsAccepted(false);
      setErrors({});
      toast.success(successMessage, { autoClose: 3000, hideProgressBar: true });
    }
    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
    }
  }, [successMessage, errorMessage]);


  return (
    <>
      <Helmet>
        <title>Sign Up | HealthGonia</title>
        <meta name="description" content="Create a new HealthGonia account to access personalized healthcare services and manage your orders." />
        <meta name="keywords" content="Sign Up, Register, HealthGonia, Account, Healthcare, Create Account" />
      </Helmet>
      
      <Header />
      <div className="sign-in-main sign-up-main">
        <div className="sign-box">
          <div className="sign-box-wrap">
            <Row>
              <Col lg="12">
                <h2>Sign-Up</h2>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formFullNamel">
                    <Form.Control
                      type="text"
                      placeholder="Full Name"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      isInvalid={!!errors.fullName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.fullName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      placeholder="Email Address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicPhoneNumbber"
                  >
                    <Form.Control
                      type="tel"
                      placeholder="Phone Number"
                      maxLength={12}
                      value={phone}
                      onChange={handlePhoneChange}
                      //onChange={(e) => setPhone(e.target.value)}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="formBasicConfirmPassword"
                  >
                    <Form.Control
                      type="password"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      isInvalid={!!errors.confirmPassword}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.confirmPassword}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group
                    className="mb-3 form-group"
                    controlId="formBasicCheckbox"
                  >
                    <Form.Check
                      className="custom-checkbox"
                      type="checkbox"
                      checked={termsAccepted ? "checked" : ""}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                      isInvalid={!!errors.termsAccepted}
                      label={
                        <span>
                          By continuing, you’ve read and agree to our
                          <a
                            href={TERMS_AND_CONDITIONS}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Terms and Conditions
                          </a>{" "}
                          and
                          <a
                            href={PRIVACY_POLICY}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Privacy Policy
                          </a>
                          .
                        </span>
                      }
                    />
                  </Form.Group>
                    
                  <Form.Control.Feedback type="invalid" className={termsAccepted ? '' : 'error-show' }>
                      {errors.termsAccepted}
                  </Form.Control.Feedback>
                    
                  <Button
                    className="cutsom-bttn"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        size="lg"
                        className="spinner-icon"
                      />
                    ) : (
                      "Register"
                    )}
                  </Button>

                  <Form.Text className="or-text">OR</Form.Text>
                  <Link className="cutsom-bttn login-btn">
                    <img src={Google} alt="" /> Sign In with Google
                  </Link>
                  <Link className="cutsom-bttn login-btn">
                    <img src={Facebook} alt="" /> Sign In with Facebook
                  </Link>
                  <Link className="cutsom-bttn login-btn">
                    <img src={Apple} alt="" /> Sign In with Apple
                  </Link>
                  <p className="text-center mb-0">
                    Already have an account?  <Link to="/sign-in">Sign In</Link>
                  </p>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
export default SignUp;
