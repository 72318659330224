import React, {useEffect} from 'react'
import Header from '../component/Header'  
import ProductDetailsComp from '../component/ProductDetailsComp'  
import ProductInformation from '../component/ProductInformation' 
// import MedicineHealthProducts from '../component/MedicineHealthProducts' 
// import OfferSection from '../component/OfferSection' 
// import PowerNature from '../component/PowerNature' 
// import Testimonials from '../component/Testimonials' 
// import OurBlogArticle from '../component/OurBlogArticle' 
import ATC from '../component/ATC' 
import ProductDetailsSkeleton from '../component/skeleton/ProductDetailsSkeleton';


import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { clearMessages, fetchProductDetail } from "../features/product/productActions";
import { toast } from "react-toastify";
import { Helmet } from 'react-helmet-async';

function ProductDetails() {

   // Get the slug from the URL
   const { slug } = useParams();

   const dispatch = useDispatch();
   const { errorMessage, productRow } = useSelector((state) => state.product);
 
   useEffect(() => {
     let data = {slug : slug};
     dispatch(fetchProductDetail(data));
   }, [dispatch, slug]);
 
   useEffect(() => {
     if (errorMessage) {
       toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
       dispatch(clearMessages()); // Reset the messages after showing the toast
     }
   }, [errorMessage, dispatch]);


  
  return (
    <>
      <Helmet>
        <title>{productRow?.meta_title}</title>
        <meta name="description" content={productRow?.meta_description} />
        <meta name="keywords" content={productRow?.meta_keywords} />
      </Helmet>

      <div className='main product-details-page'>
        <Header/>
        {(productRow) ? (
          <>
          <ProductDetailsComp productRow={productRow}/>
          <ProductInformation productRow={productRow}/>
          </>
        ) : (
          <ProductDetailsSkeleton></ProductDetailsSkeleton>
        )}
        {/* 
        <MedicineHealthProducts />
        <OfferSection />
        <Testimonials />
        <OurBlogArticle blogTitle="Our Blog & Article" /> */}
        <ATC />
      </div>
    </>
  )
}
export default ProductDetails


