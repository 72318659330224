import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import Visa from '../assets/images/profile/visa.svg'
import MasterCard from '../assets/images/profile/master-card.svg'
import AmericanExpress from '../assets/images/profile/american-express.svg'
import Maestro from '../assets/images/profile/maestro.svg'
import { Link } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import PlusIcon from '../assets/images/profile/plus.svg'
import BackImg from '../assets/images/cart/back-icon.svg' 
import EditIcon from '../assets/images/cart/edit-icon.svg';
import DeleteIcon from '../assets/images/cart/delete-icon.svg';

function PaymentInfoComp() {
  const [isActive, setIsActive] = useState(false);
  const handleToggle = () => {
    setIsActive(prevState => !prevState);
  };
  return (
    <>  
    <div className={`${isActive ? 'isHide' : ''}`}>    
      <div className="tab-content-header">
        <h3>Payment Info</h3>
        <Link to="" className='cutsom-bttn' onClick={handleToggle}><img src={PlusIcon} alt="" /> Add New Card</Link>
      </div>
      <div className="tab-content-body">
          <div className="card-list">
              <div className="card-list-item">
                <div className="card-cell"><img className='card-img' src={Visa} alt="" /></div>
                <div className="card-cell">
                  <span>Card on Name</span>
                  <h4>Emily Taylor</h4>
                </div>
                <div className="card-cell">
                  <span>Card Number</span>
                  <h4>****   ****   ****  3456</h4>
                </div>
                <div className="card-cell">
                  <span>Expiry Date</span>
                  <h4>25 Apr, 2029</h4>
                </div>
                <div className="card-cell flex-row">
                  <Link className='edit-btn' to=""  onClick={handleToggle}><img src={EditIcon} alt="" /></Link>
                  <Link className='delete-btn' to=""><img src={DeleteIcon} alt="" /></Link>
                </div>
              </div>

              <div className="card-list-item">
                <div className="card-cell"><img className='card-img' src={MasterCard} alt="" /></div>
                <div className="card-cell">
                  <span>Card on Name</span>
                  <h4>Emily Taylor</h4>
                </div>
                <div className="card-cell">
                  <span>Card Number</span>
                  <h4>****   ****   ****  3456</h4>
                </div>
                <div className="card-cell">
                  <span>Expiry Date</span>
                  <h4>25 Apr, 2029</h4>
                </div>
                <div className="card-cell flex-row">
                  <Link className='edit-btn' to=""  onClick={handleToggle}><img src={EditIcon} alt="" /></Link>
                  <Link className='delete-btn' to=""><img src={DeleteIcon} alt="" /></Link>
                </div>
              </div>

              <div className="card-list-item">
                <div className="card-cell"><img className='card-img' src={AmericanExpress} alt="" /></div>
                <div className="card-cell">
                  <span>Card on Name</span>
                  <h4>Emily Taylor</h4>
                </div>
                <div className="card-cell">
                  <span>Card Number</span>
                  <h4>****   ****   ****  3456</h4>
                </div>
                <div className="card-cell">
                  <span>Expiry Date</span>
                  <h4>25 Apr, 2029</h4>
                </div>
                <div className="card-cell flex-row">
                  <Link className='edit-btn' to=""  onClick={handleToggle}><img src={EditIcon} alt="" /></Link>
                  <Link className='delete-btn' to=""><img src={DeleteIcon} alt="" /></Link>
                </div>
              </div>

              <div className="card-list-item">
                <div className="card-cell"><img className='card-img' src={Maestro} alt="" /></div>
                <div className="card-cell">
                  <span>Card on Name</span>
                  <h4>Emily Taylor</h4>
                </div>
                <div className="card-cell">
                  <span>Card Number</span>
                  <h4>****   ****   ****  3456</h4>
                </div>
                <div className="card-cell">
                  <span>Expiry Date</span>
                  <h4>25 Apr, 2029</h4>
                </div>
                <div className="card-cell flex-row">
                  <Link className='edit-btn' to=""  onClick={handleToggle}><img src={EditIcon} alt="" /></Link>
                  <Link className='delete-btn' to=""><img src={DeleteIcon} alt="" /></Link>
                </div>
              </div>
          </div>
      </div>
    </div>  
    <div className={`${isActive ? '' : 'isHide'}`}>
      <div className="tab-content-header">
        <h3><Link to="" className='backToList' onClick={handleToggle}><img src={BackImg} alt="" /></Link> Add New Credit/Debit Card</h3>
      </div>
      <div className="new-address">
        <Form>
          <Row>
            <Col lg="6">
              <Form.Group className="mb-4" controlId="formFullNamel">
                <Form.Label>Name on Card <span>*</span></Form.Label>
                <Form.Control type="text" placeholder="Enter name on card" required />
              </Form.Group>
              <Form.Group className="mb-4" controlId="formFullNamel">
                <Form.Label>Card Number <span>*</span></Form.Label>
                <Form.Control type="text" maxLength={16} placeholder="Enter 16 digit card number" required />
              </Form.Group>
              <Row>
                <Col md="6">
                  <Form.Group controlId="formFullNamel">
                    <Form.Label>Expiry Date <span>*</span></Form.Label>
                    <Form.Control type="tel" placeholder="MM/YY" required />
                  </Form.Group>
                </Col>
                <Col md="6">
                  <Form.Group controlId="formFullNamel">
                    <Form.Label>Security Code <span>*</span></Form.Label>
                    <Form.Control type="tel" maxLength={3} placeholder="CVV" required />
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col md="12">              
              <div className="bttn-group">
                <Link className='cutsom-bttn cancel-bttn' onClick={handleToggle}>Cancel</Link>
                <Button className='cutsom-bttn' type="submit" onClick={handleToggle}>Save Card</Button>
              </div>              
            </Col>
          </Row>
        </Form>
      </div>
    </div>  
    
    </>
  )
}
export default PaymentInfoComp