import React from 'react'
import Header from '../component/Header' 
import SubBanner from '../component/SubBanner' 
import ProductList from '../component/ProductList' 
// import MedicineHealthProducts from '../component/MedicineHealthProducts' 
// import OfferSection from '../component/OfferSection' 
// import PowerNature from '../component/PowerNature' 
// import Testimonials from '../component/Testimonials' 
// import OurBlogArticle from '../component/OurBlogArticle' 
import ATC from '../component/ATC' 
import { Helmet } from 'react-helmet-async';

function Products() {
  return (
    <>
      <Helmet>
        <title>Products | HealthGonia</title>
        <meta name="description" content="Browse through our wide range of healthcare products at HealthGonia. Find the right products for your health and well-being." />
        <meta name="keywords" content="Healthcare Products, HealthGonia, Medical Supplies, Wellness Products, Health Products" />
      </Helmet>

      <div className='main'>
        <Header/>
        <SubBanner HeaderTitle="Our Products" HeaderSubtitle=""/>        
        <ProductList />        
        {/* 
        <MedicineHealthProducts />
        <OfferSection />
        <PowerNature />
        <Testimonials />
        <OurBlogArticle blogTitle="Our Blog & Article" /> */}
        <ATC />
      </div>
    </>
  )
}
export default Products


