import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'swiper/css'; 
import 'swiper/css/bundle';
import './scss/font-family.scss'; 
import './scss/veraible.scss';
import './scss/header.scss';
import './scss/banner.scss';
import './scss/home-today-offer.scss';
import './scss/wellness.scss';
import './scss/product-slider.scss';
import './scss/why-choose-us.scss'; 
import './scss/offer-section.scss'; 
import './scss/power-nature.scss'; 
import './scss/testimonials.scss'; 
import './scss/blog-article.scss';
import './scss/act.scss';
import './scss/footer.scss';
import './scss/common.scss';
import './scss/sign.scss';
import './scss/sub-banner.scss';
import './scss/product.scss';
import './scss/product-details.scss';
import './scss/product-information.scss';
import './scss/legal-policies.scss';
import './scss/about-section.scss';
import './scss/blog-list.scss';
import './scss/blog-details.scss';
import './scss/faqs-list.scss';
import './scss/contact-us.scss';
import './scss/cart.scss';
import './scss/checkout.scss';
import './scss/profile.scss';
import './scss/modal.scss';
import './scss/career.scss';
import './scss/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux'; // Import Provider
import store from './store/store'; // Import your store
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import styles
import 'react-loading-skeleton/dist/skeleton.css'; // Skeleton styles


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <App />
//       <ToastContainer />
//     </Provider>
//   </React.StrictMode>
// );

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <App />
    <ToastContainer />
  </Provider>
);

reportWebVitals();
