import React from 'react'
import Header from '../component/Header'
import BannerOne from '../component/BannerOne' 
import HomeTodayOffer from '../component/HomeTodayOffer' 
import Wellness from '../component/Wellness' 
import BestSeller from '../component/BestSeller' 
import MedicineHealthProducts from '../component/MedicineHealthProducts' 
import WhyChooseUs from '../component/WhyChooseUs' 
import OfferSection from '../component/OfferSection' 
import PowerNature from '../component/PowerNature' 
import Testimonials from '../component/Testimonials' 
import OurBlogArticle from '../component/OurBlogArticle' 
import ATC from '../component/ATC' 
function HomeOne() {
  return (
    <>
      <div className='main home-page'>
        <Header/>
        <BannerOne/>
        <HomeTodayOffer/>
        <Wellness/>
        <BestSeller />
        <WhyChooseUs />
        <MedicineHealthProducts />
        <OfferSection />
        <PowerNature />
        <Testimonials />
        <OurBlogArticle blogTitle="Our Blog & Article" />
        <ATC />
      </div>
    </>
  )
}
export default HomeOne


