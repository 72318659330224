import React, { useEffect, useState } from "react";
import { Row, Col } from 'react-bootstrap';
// import Blog1 from '../assets/images/blog-1.png';
// import Blog2 from '../assets/images/blog-2.png';
// import Blog3 from '../assets/images/blog-3.png';
import Calendar from '../assets/images/calendar.svg';
import whiteArrow from '../assets/images/button-arrow.svg';
import Skeleton  from "react-loading-skeleton";
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import { clearMessages, fetchBlogs } from "../features/blog/blogActions";
import { toast } from "react-toastify";
import { stripHtmlTags, truncateText, formatDate } from '../helpers/helpers';

function OurBlogArticle({ blogCategoryId, blogTitle }) {
  const [allBlogs, setAllBlogs] = useState([]);

  const dispatch = useDispatch();
  const { errorMessage, blogs } = useSelector((state) => state.blog);

  useEffect(() => {
    const categoryId = blogCategoryId || '';
    const data = { page: 1, limit: 4, blog_category_id: categoryId };
    dispatch(fetchBlogs(data));
  }, [dispatch, blogCategoryId]);

  useEffect(() => {
    if (blogs?.data) {
      setAllBlogs(blogs.data);
    }
  }, [blogs]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
      dispatch(clearMessages()); // Reset the messages after showing the toast
    }
  }, [errorMessage, dispatch]);

  

  return (
    <>
      <section className='blog-article'>
        <div className="common-header">
          <h2>{blogTitle}</h2>
          <p>Bring the wisdom of herbs to your routine with these plant-powered picks.</p>
        </div>

        {!blogs ? ( // Check if loading is true
          <Row>
            {Array(4).fill().map((_, index) => ( // Adjust the number of skeletons as needed
              <Col md="6" lg="3" key={`skeleton-${index}`}>
                <div className="">
                  <div className="">
                    <Skeleton height={180} /> {/* Skeleton for image */}
                  </div>
                  <div className="">
                    <div className="date">
                      <Skeleton height={20} width="40%" /> {/* Skeleton for date */}
                    </div>
                    <Skeleton height={20} width="80%" /> {/* Skeleton for title */}
                    <Skeleton height={15} width="100%" /> {/* Skeleton for description */}
                    <Skeleton height={15} width="100%" /> {/* Skeleton for description */}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <Row>
            {allBlogs.map((blog) => (
              <Col md="6" lg="3" key={blog.id}>
                <Link to={`/blog-details/${blog.slug}`} className="blog-article-box">
                  <div className="blog-article-box-img">
                    <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${blog.image}`} alt={blog.title} />
                  </div>
                  <div className="blog-article-box-text">
                    <div className="date">
                      <span><img src={Calendar} alt="Calendar Icon" /></span>
                      <p>{formatDate(blog.created_at)}</p>
                    </div>
                    <h3>{blog.title}</h3>
                    <p>
                      {truncateText(stripHtmlTags(blog.description), 100)}
                    </p>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        )}
        <Link to="/blogs" className='cutsom-bttn'>View More Article <img src={whiteArrow} alt="" /></Link>
      </section>     
    </>
  );
}

export default OurBlogArticle;
