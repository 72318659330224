// src/features/career/careerReducer.js
import { createReducer } from '@reduxjs/toolkit';
import {
    clearMessages,
    getJobsStart, getJobsSuccess, getJobsFailure,
    applyJobStart, applyJobSuccess, applyJobFailure,
    getJobDetailsStart, getJobDetailsSuccess, getJobDetailsFailure
} from './careerActions';

const initialState = {
    jobListData: null,
    jobDetails: null,
    loading: false,
    jobAppliedLoading: false,
    successMessage: null,
    errorMessage: null,
};

const careerReducer = createReducer(initialState, (builder) => {
    builder
        // Clear success and error messages
        .addCase(clearMessages, (state) => {
            state.successMessage = null;
            state.errorMessage = null;
        })

        // Get Jobs
        .addCase(getJobsStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getJobsSuccess, (state, action) => {
            state.loading = false;
            state.jobListData = action.payload.data;
        })
        .addCase(getJobsFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })

        // Apply for Job
        .addCase(applyJobStart, (state) => {
            state.jobAppliedLoading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(applyJobSuccess, (state, action) => {
            state.jobAppliedLoading = false;
            state.successMessage = action.payload.message;
        })
        .addCase(applyJobFailure, (state, action) => {
            state.jobAppliedLoading = false;
            state.errorMessage = action.payload.message;
        })

        // Get Job Details by Slug
        .addCase(getJobDetailsStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getJobDetailsSuccess, (state, action) => {
            state.loading = false;
            state.jobDetails = action.payload.data;
        })
        .addCase(getJobDetailsFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        });
});

export default careerReducer;
