// src/features/product/productReducer.js
import { createReducer } from '@reduxjs/toolkit';
import {
    clearMessages,
    getBrandsStart, getBrandsSuccess, getBrandsFailure,
    getCategoriesStart, getCategoriesSuccess, getCategoriesFailure,
    getSubCategoriesStart, getSubCategoriesSuccess, getSubCategoriesFailure,
    getMedicineFormsStart, getMedicineFormsSuccess, getMedicineFormsFailure,
    getAgeGroupsStart, getAgeGroupsSuccess, getAgeGroupsFailure,
    getDietaryNeedsStart, getDietaryNeedsSuccess, getDietaryNeedsFailure,
    getHealthInterestStart, getHealthInterestSuccess, getHealthInterestFailure,
    getProductsStart, getProductsSuccess, getProductsFailure,
    getProductsByKeywordStart, getProductsByKeywordSuccess, getProductsByKeywordFailure,
    getProductDetailStart, getProductDetailSuccess, getProductDetailFailure,
    getBestSellerProductStart, getBestSellerProductSuccess, getBestSellerProductFailure,
} from './productActions';

const initialState = {
    brands: [],
    categories: [],
    subCategories: [],
    medicineForms: [],
    ageGroups: [],
    dietaryNeeds: [],
    healthInterests: [],
    products: [],
    searchProducts: [],
    bestSellerProducts: [],
    productRow: null,
    loading: false,
    productFetchLoading: false,
    successMessage: null,
    errorMessage: null,
};

const productReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(clearMessages, (state) => {
            state.successMessage = null;
            state.errorMessage = null;
        })

        .addCase(getBrandsStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getBrandsSuccess, (state, action) => {
            state.loading = false;
            state.brands = action.payload.data;
            state.successMessage = action.payload.message;
        })
        .addCase(getBrandsFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })

        .addCase(getCategoriesStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getCategoriesSuccess, (state, action) => {
            state.loading = false;
            state.categories = action.payload.data;
            state.successMessage = action.payload.message;
        })
        .addCase(getCategoriesFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })

        .addCase(getSubCategoriesStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getSubCategoriesSuccess, (state, action) => {
            state.loading = false;
            state.subCategories = action.payload.data;
            state.successMessage = action.payload.message;
        })
        .addCase(getSubCategoriesFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })

        .addCase(getMedicineFormsStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getMedicineFormsSuccess, (state, action) => {
            state.loading = false;
            state.medicineForms = action.payload.data;
            state.successMessage = action.payload.message;
        })
        .addCase(getMedicineFormsFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })

        .addCase(getAgeGroupsStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getAgeGroupsSuccess, (state, action) => {
            state.loading = false;
            state.ageGroups = action.payload.data;
            state.successMessage = action.payload.message;
        })
        .addCase(getAgeGroupsFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })

        .addCase(getDietaryNeedsStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getDietaryNeedsSuccess, (state, action) => {
            state.loading = false;
            state.dietaryNeeds = action.payload.data;
            state.successMessage = action.payload.message;
        })
        .addCase(getDietaryNeedsFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })

        .addCase(getHealthInterestStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getHealthInterestSuccess, (state, action) => {
            state.loading = false;
            state.healthInterests = action.payload.data;
            state.successMessage = action.payload.message;
        })
        .addCase(getHealthInterestFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        }) 
        
        .addCase(getProductsStart, (state) => {
            state.loading = true;
            state.products = [];
            state.productFetchLoading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getProductsSuccess, (state, action) => {
            state.loading = false;
            state.productFetchLoading = false;
            state.products = action.payload.data;
            state.successMessage = action.payload.message;
        })
        .addCase(getProductsFailure, (state, action) => {
            state.loading = false;
            state.productFetchLoading = false;
            state.errorMessage = action.payload.message;
        })


        .addCase(getProductsByKeywordStart, (state) => {
            state.searchProducts = [];
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getProductsByKeywordSuccess, (state, action) => {
            state.searchProducts = action.payload.data.data;
            state.successMessage = action.payload.message;
        })
        .addCase(getProductsByKeywordFailure, (state, action) => {
            state.searchProducts = [];
            state.errorMessage = action.payload.message;
        })
        
        .addCase(getProductDetailStart, (state) => {
            state.loading = true;
            state.productRow = null;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getProductDetailSuccess, (state, action) => {
            state.loading = false;
            state.productRow = action.payload.data;
            state.successMessage = action.payload.message;
        })
        .addCase(getProductDetailFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })

        .addCase(getBestSellerProductStart, (state) => {
            state.bestSellerProducts = [];
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getBestSellerProductSuccess, (state, action) => {
            state.loading = false;
            state.bestSellerProducts = action.payload.data?.data;
            state.successMessage = action.payload.message;
        })
        .addCase(getBestSellerProductFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        });


});

export default productReducer;
