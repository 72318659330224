import React from 'react'

function SubBanner(props) {
  return (
    <>
    <section className='sub-banner-section'>
      <div className="sub-banner-box">               
        <h2>{props.HeaderTitle}</h2>
        <p> {props.HeaderSubtitle}</p>
        <div className='breadcrumbs'>
          <ul>
            <li>
              <a href="/">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1.09942 5.51896C0.666748 6.3094 0.666748 7.26291 0.666748 9.16994V10.4375C0.666748 13.6882 0.666748 15.3136 1.64306 16.3235C2.61937 17.3333 4.19072 17.3333 7.33341 17.3333H10.6667C13.8094 17.3333 15.3808 17.3333 16.3571 16.3235C17.3334 15.3136 17.3334 13.6882 17.3334 10.4375V9.16994C17.3334 7.26291 17.3334 6.3094 16.9007 5.51896C16.4681 4.72851 15.6776 4.23793 14.0967 3.25678L12.4301 2.2224C10.7589 1.18525 9.92336 0.666672 9.00008 0.666672C8.0768 0.666672 7.24124 1.18525 5.57011 2.2224L3.90344 3.25678C2.32254 4.23793 1.53208 4.72851 1.09942 5.51896ZM6.50008 13.375C6.1549 13.375 5.87508 13.6548 5.87508 14C5.87508 14.3452 6.1549 14.625 6.50008 14.625H11.5001C11.8453 14.625 12.1251 14.3452 12.1251 14C12.1251 13.6548 11.8453 13.375 11.5001 13.375H6.50008Z" fill="white" fillOpacity="0.4"/>
                </svg>
                Home
              </a>
            </li>
            <li>
              {props.HeaderTitle}
            </li>
          </ul>
        </div>
      </div>
    </section>
    </>
  )
}
export default SubBanner