import React, { useEffect, useState } from "react";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import {
  addAddresses,
} from "../features/user/userActions";
import { fetchStates, fetchCities } from "../features/geo/geoLocationActions";
import { toast } from "react-toastify";
import { formatPhoneNumber } from "../helpers/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import ReactSelectStyles from "../component/styles/ReactSelect";
import { DEFAULT_COUNTRY } from "../Constants";


const AddAddressModal = ({ show, onHide }) => {
  const [addressType, setAddressType] = useState("Home");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [addressSecondLine, setAddressSecondLine] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");
  const [asDefault, setAsDefault] = useState(false);
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();
  const { loading, successMessage } = useSelector(
    (state) => state.user
  );
  const { states, cities } = useSelector((state) => state.geo);

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    setPhone(formatPhoneNumber(value));
  };

  const validate = () => {
    const errors = {};

    if (!addressType) errors.addressType = "Address Type is required.";
    if (!fullName) errors.fullName = "Full Name is required.";
    if (!email) errors.email = "Email is required.";
    if (!phone) errors.phone = "Phone Number is required.";
    if (!address) errors.address = "Address is required.";
    if (!state) errors.state = "State is required.";
    if (!zipcode) errors.zipcode = "Zipcode is required.";

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailRegex.test(email)) {
      errors.email = "Invalid email format.";
    }

    if (phone && phone.length !== 12) {
      errors.phone = "Please enter a valid phone number.";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors({});

    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    toast.dismiss();

    const data = {
      address_type: (addressType === "Home") ? 1 : 2,
      name: fullName,
      email: email,
      mobile_number: phone,
      address: address,
      address_second_line: addressSecondLine,
      country: DEFAULT_COUNTRY,
      state: state,
      city: city,
      zip_code: zipcode,
      is_default_address: asDefault ? 1 : 0,
    };

    dispatch(addAddresses(data));
  };

  const handleChangeState = (selectedOption) => {
    setState(selectedOption.value);
    if (selectedOption.value) {
      dispatch(fetchCities(selectedOption.value));
    }
  };

  const handleChangeCities = (selectedOption) => {
    setCity(selectedOption.value);
  };

  useEffect(() => {
    dispatch(fetchStates(DEFAULT_COUNTRY));
  }, [dispatch]);

  useEffect(() => {
    if (successMessage) {
      setFullName("");
      setEmail("");
      setPhone("");
      setAddress("");
      setAddressSecondLine("");
      setState("");
      setCity("");
      setZipcode("");
      setAsDefault(false);
      setErrors({});
      onHide(); // Close the modal on success
    }
  }, [successMessage, onHide]);

  return (
    <Modal
      className="address-modal"
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Address
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md="6">
              <Form.Group className="mb-4">
                <Form.Label>
                  Address Type <span>*</span>
                </Form.Label>
                <Row>
                  <Col md="3">
                    <Form.Check
                      type="radio"
                      id="homeAddress"
                      name="addressType"
                      label="Home"
                      value="Home"
                      checked={addressType === "Home"}
                      onChange={(e) => setAddressType(e.target.value)}
                      isInvalid={!!errors.addressType}
                    />
                  </Col>
                  <Col md="9">
                    <Form.Check
                      type="radio"
                      id="officeAddress"
                      name="addressType"
                      label="Office"
                      value="Office"
                      checked={addressType === "Office"}
                      onChange={(e) => setAddressType(e.target.value)}
                      isInvalid={!!errors.addressType}
                    />
                  </Col>
                </Row>
                {errors.addressType && (
                  <Form.Control.Feedback type="invalid">
                    {errors.addressType}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group className="mb-3" controlId="formFullName1">
                <Form.Label>
                  Full Name <span>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Full Name"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  isInvalid={!!errors.fullName}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.fullName}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md="6">
              <Form.Group className="mb-3">
                <Form.Label>
                  Email Address <span>*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3" controlId="formBasicPhoneNumber">
                <Form.Label>
                  Phone Number <span>*</span>
                </Form.Label>
                <Form.Control
                  type="tel"
                  placeholder="Phone Number"
                  maxLength={12}
                  value={phone}
                  onChange={handlePhoneChange}
                  isInvalid={!!errors.phone}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="12">
              <Form.Group className="mb-3">
                <Form.Label>
                  Address <span>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Address"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  isInvalid={!!errors.address}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.address}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="12">
              <Form.Group className="mb-3">
                <Form.Label>Address 1</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Apartment, suite, etc (optional)"
                  value={addressSecondLine}
                  onChange={(e) => setAddressSecondLine(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3">
                <Form.Label>
                  State <span>*</span>
                </Form.Label>
                <Select
                  options={states?.data?.map((state) => ({
                    value: state.value,
                    label: state.label,
                  }))}
                  onChange={handleChangeState}
                  placeholder="Select a State"
                  className={
                    errors.state ? "is-invalid form-select" : "form-select"
                  }
                  styles={ReactSelectStyles}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.state}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>

                <Select
                  options={cities?.data?.map((city) => ({
                    value: city.value,
                    label: city.label,
                  }))}
                  onChange={handleChangeCities}
                  placeholder="Select a City"
                  className="form-select"
                  styles={ReactSelectStyles}
                />
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group className="mb-3">
                <Form.Label>
                  Zipcode <span>*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Zip Code"
                  value={zipcode}
                  onChange={(e) => setZipcode(e.target.value)}
                  isInvalid={!!errors.zipcode}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.zipcode}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6" className="d-flex align-items-center mt-4">
                <Form.Group className="form-group" controlId="formBasicCheckbox">
                    <Form.Check
                    className="custom-checkbox"
                    type="checkbox"
                    label="Set as default address"
                    checked={asDefault ? "checked" : ""}
                    onChange={(e) => setAsDefault(e.target.checked)}
                    />
                </Form.Group>
            </Col>

            <Col md="12">
             
              <div className="bttn-group justify-content-center">
                <Link
                  onClick={onHide}
                  className="cutsom-bttn cancel-bttn"
                  type="button"
                >
                  Cancel
                </Link>

                <Button
                  className="cutsom-bttn"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      size="lg"
                      className="spinner-icon"
                    />
                  ) : (
                    "Save Address"
                  )}
                </Button>
              </div>
            </Col>

          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddAddressModal;
