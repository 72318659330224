import React from 'react'
import Header from '../component/Header'
import SubBanner from '../component/SubBanner' 
import BlogList from '../component/BlogList' 
//import Testimonials from '../component/Testimonials' 
import ATC from '../component/ATC' 
import { Helmet } from 'react-helmet-async';

function Blog() {
  return (
    <>
      <Helmet>
        <title>Our Blog & Articles | HealthGonia</title>
        <meta name="description" content="Explore insightful articles and blogs on our site. Stay updated with the latest news and trends." />
        <meta name="keywords" content="Blog, Articles, News, Insights" />
      </Helmet>
      <div className='main blog-page'>
        <Header/>
        <SubBanner HeaderTitle="Our Blog & Article" HeaderSubtitle=""/>
        <BlogList />
        {/* <Testimonials className="border-bottom-none" />      */}
        <ATC />
      </div>
    </>
  )
}
export default Blog


