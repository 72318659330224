// src/services/productService.js
import axios from 'axios';
import { toFormData } from './utils'; // Utility function
import { setAuthToken } from './setAuthToken';

const productService = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Assuming you have a token from somewhere (e.g., after login)
const token = localStorage.getItem('token') || sessionStorage.getItem('token');
setAuthToken(productService, token);

export const getBrands = (data) => {
    const formData = toFormData(data);
    return productService.post('/brands', formData);
};

export const getCategories = (data) => {
    const formData = toFormData(data);
    return productService.post('/categories', formData);
};

export const getSubCategories = (data) => {
    const formData = toFormData(data);
    return productService.post('/sub-categories', formData);
};

export const getMedicineForms = (data) => {
    const formData = toFormData(data);
    return productService.post('/medicine-forms', formData);
};

export const getAgeGroups = (data) => {
    const formData = toFormData(data);
    return productService.post('/age-groups', formData);
};

export const getDietaryNeeds = (data) => {
    const formData = toFormData(data);
    return productService.post('/dietary-needs', formData);
};

export const getHealthInterest = (data) => {
    const formData = toFormData(data);
    return productService.post('/health-interests', formData);
};

export const getProducts = (data) => {
    const formData = toFormData(data);
    return productService.post('/products', formData);
};

export const getProductByKeyword = (data) => {
    const formData = toFormData(data);
    return productService.post('/products', formData);
};

export const getProductDetail = (data) => {
    return productService.get('/products/'+data.slug);
};

export const getBestSellerProducts = (data) => {
    const formData = toFormData(data);
    return productService.post('/products/top-sold-categories', formData);
};


export default productService;
