// src/features/wishlist/wishlistActions.js
import { createAction } from '@reduxjs/toolkit';
import {
    getWishlists,
    addRemoveProductFromWishlist
} from '../../services/wishlistService';

// Action Creators
export const clearMessages = createAction('wishlist/clearMessages');

// Get Wishlist Products
export const getWishlistProductsStart = createAction('wishlist/getWishlistProductsStart');
export const getWishlistProductsSuccess = createAction('wishlist/getWishlistProductsSuccess');
export const getWishlistProductsFailure = createAction('wishlist/getWishlistProductsFailure');

// Add to Wishlist
export const addRemoveWishlistStart = createAction('wishlist/addRemoveWishlistStart');
export const addRemoveWishlistSuccess = createAction('wishlist/addRemoveWishlistSuccess');
export const addRemoveWishlistFailure = createAction('wishlist/addRemoveWishlistFailure');


// Thunks
export const fetchWishlistProducts = (data) => async (dispatch) => {
    dispatch(getWishlistProductsStart());
    try {
        const response = await getWishlists(data);
        if(response.data.status === false){
            dispatch(getWishlistProductsFailure(response.data));
        }else{
            dispatch(getWishlistProductsSuccess(response.data));
        }
    } catch (error) {
        dispatch(getWishlistProductsFailure(error.message));
    }
};

export const addRemoveWishlist = (data) => async (dispatch) => {
    dispatch(addRemoveWishlistStart());
    try {
        const response = await addRemoveProductFromWishlist(data);
        if(response.data.status === false){
            dispatch(addRemoveWishlistFailure(response.data));
        }else{
            dispatch(addRemoveWishlistSuccess(response.data));
        }
    } catch (error) {
        dispatch(addRemoveWishlistFailure(error.message));
    }
};

