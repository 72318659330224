import React, { useState, useEffect } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate } from 'react-router-dom'

function LegalPoliciesComp({legalPageData, slug}) {  

  const navigate = useNavigate();
  const [key, setKey] = useState(slug);
  
  const tabChange = (k) => {
    navigate('/page/'+k);
    setKey(k);
  }

  useEffect(() => {
    setKey(slug)
  }, [slug]);

  if(!legalPageData){
    return null;
  }

  return (
    <>
     <section className='legal-policies'>        
        <div className="legal-policies-wrap">          
          <Tabs
            defaultActiveKey="terms-conditions"
            activeKey={key}
            onSelect={(k) => tabChange(k)}
            id="uncontrolled-tab-example"
          >
            <Tab eventKey="terms-conditions" title="Terms & Conditions">
                <div className='legal-policies-content' dangerouslySetInnerHTML={{__html: legalPageData?.page_description}}></div>
            </Tab>
            <Tab eventKey="privacy-policy" title="Privacy Policy">
              <div className='legal-policies-content' dangerouslySetInnerHTML={{__html: legalPageData?.page_description}}></div>
            </Tab>
            <Tab eventKey="legal" title="Legal">
              <div className='legal-policies-content' dangerouslySetInnerHTML={{__html: legalPageData?.page_description}}></div>
            </Tab>
            <Tab eventKey="return-policy" title="Return Policy">
              <div className='legal-policies-content' dangerouslySetInnerHTML={{__html: legalPageData?.page_description}}></div>
            </Tab>            
          </Tabs>
        </div>
     </section>     
    </>
  )
}
export default LegalPoliciesComp 