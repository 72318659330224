import React ,{ useState }from 'react'
import Accordion from 'react-bootstrap/Accordion';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import { Row, Col } from 'react-bootstrap'
// import WhyChooseUsIcon from '../assets/images/why-choose-us-icon.svg'
// import { Table } from 'react-bootstrap';
import ReviewOne from '../assets/images/review-1.svg'
// import ReviewTwo from '../assets/images/review-2.svg'
// import ReviewThree from '../assets/images/review-3.svg'
// import ReviewImg from '../assets/images/review-img.svg'
// import DownIcon from '../assets/images/down-arrow.svg'
import { Rating } from 'react-simple-star-rating'


function ProductInformation({productRow}) {  

  const [key, setKey] = useState('productDetails');

  /*
  const data = [
    { nutrient: 'Total Carbohydrate', amount: '<1g', dv: '<1%' },
    { nutrient: 'Vitamin A (as 50% [675 mcg] beta-carotene, 50% [675 mcg] retinyl acetate)', amount: '1,350mcg', dv: '150%' },
    { nutrient: 'Vitamin C (ascorbic acid)', amount: '203mg', dv: '226%' },
    { nutrient: 'Vitamin D3 (as cholecalciferol)', amount: '50mcg', dv: '250%' },
    { nutrient: 'Vitamin E (as d-alpha tocopheryl succinate)', amount: '15mg', dv: '100%' },
    { nutrient: 'Vitamin K (as phytonadione)', amount: '120mcg', dv: '100%' },
    { nutrient: 'Thiamin (as thiamin mononitrate)', amount: '20mg', dv: '1,667%' },
    { nutrient: 'Riboflavin', amount: '20mg', dv: '1,538%' },
    { nutrient: 'Niacin (as niacinamide)', amount: '40mg', dv: '250%' },
    { nutrient: 'Vitamin B6 (as pyridoxine HCl)', amount: '20mg', dv: '1,176%' },
  ];
  */

  // const [rating, setRating] = useState(1);
  /*
  const handleRating = (rate) => {
    setRating(rate); // Set rating value
  };
  */

  return (
    <>
     <section className='product-information'>        
        <div className="product-information-wrap">
          <h2>Product Information</h2>
          <p>Find the perfect products for every chapter.</p>
          <Tabs
            defaultActiveKey="productDetails"
            id="uncontrolled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="productDetails" title="Product Details">
              {/* <h2>Product Information</h2> */}
              <div className="product-desc-content" dangerouslySetInnerHTML={{__html: productRow?.description}}></div>

            </Tab>
            <Tab eventKey="ingredients" title="Ingredients">
              <div className="ingredients-wrap">
                <div className="ingredients-desc-content" dangerouslySetInnerHTML={{__html: productRow?.ingredients}}></div>
                {/* 
                <Table striped hover responsive>
                  <thead>
                    <tr>
                      <th>Serving Size: 1 Tablet</th>
                      <th>Amount/Serving</th>
                      <th>%DV</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => (
                      <tr key={index}>
                        <td>{item.nutrient}</td>
                        <td>{item.amount}</td>
                        <td>{item.dv}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <span>†Percent daily value is based on a 2000 calorie diet</span>
                 */}
                {productRow?.other_ingredients !== "" && (
                <div className='other-group'>
                  <h3>OTHER INGREDIENTS</h3>
                  <p>{productRow?.other_ingredients}</p>
                </div>
                )}
                {productRow?.does_not_contain !== "" && (
                <div className='other-group'>
                  <h3>{productRow?.title} DOES NOT CONTAIN</h3>
                  <p>{productRow?.does_not_contain}</p>
                </div>
                )}

                <div className='other-group'>
                  <h3>OTHER DETAILS</h3>
                  <p>UPC: {productRow?.UPC}</p>
                  <p>SKU: {productRow?.SKU}</p>
                </div>
              </div>
            </Tab>
            <Tab eventKey="directions" title="Directions">
              <div className='other-group'>
               <div className="directions-desc-content" dangerouslySetInnerHTML={{__html: productRow?.direction}}></div>
              </div>

              {/* 
              <div className='other-group'>
                <h3>Alive!® Men's 50+ Ultra Multivitamin Directions and/or Dosage</h3>
                <p>Men take 1 tablet daily, preferably with food. Not formulated for women or children. Do not exceed recommended dose.</p>
              </div>
              <div className='other-group'>
                <h3>Warning</h3>
                <p>Do not take if you are pregnant, nursing, or under the age of 18. If you have a blood clotting issue, or if you are taking any medications, consult a healthcare professional before use. Keep out of reach of children. Safety sealed with inner seal. Do not use if seal is broken or missing. Keep tightly closed. Store at room temperature. Avoid excessive heat and direct sunlight.</p>
              </div> */}
            </Tab>
            <Tab eventKey="faqs" title="FAQ’s">
              <div className='faqs-wrap'>
                
              {productRow?.faqs?.length > 0 && (
                <Accordion defaultActiveKey="0">
                  
                  {productRow?.faqs?.map((faq, indexFaq) => (
                    <Accordion.Item eventKey={indexFaq.toString()} key={faq.id}>
                      <Accordion.Header>
                        <span>{indexFaq + 1 < 10 ? `0${indexFaq + 1}` : indexFaq + 1}</span>
                        {faq.question}
                      </Accordion.Header>
                      <Accordion.Body>
                        <p dangerouslySetInnerHTML={{__html: faq.answer }}/>
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              )}

              </div>
            </Tab>
            <Tab eventKey="reviews" title="Reviews">
              {productRow?.reviews?.length > 0 && (
                <div className="reviews-wrap">
                  {productRow?.reviews?.map((review, indexReview) => (
                    <div className="reviews-item" key={"r"+indexReview}>
                      <div className="reviews-item-header">
                        <img src={ReviewOne} alt="" />
                        <h3>{review.name}</h3>
                        <span>{review.time_since}</span>
                      </div>
                      <div className="reviews-item-body">
                        <div className="product-review">
                        <Rating
                          readonly={true}
                          initialValue={review.rating} 
                          showTooltip 
                          tooltipDefaultText={`${review.rating}`} 
                        /> 
                        </div>
                        <p>{review.description}</p>
                        {(review?.images?.length > 0) && (
                          <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${review.images[0]}`} alt="" />
                        )}
                      </div>
                  </div>
                  ))}
                </div>
              )}

              {/* 
              <div className="reviews-wrap">
                <div className="reviews-item">
                    <div className="reviews-item-header">
                      <img src={ReviewOne} alt="" />
                      <h3>John Doe</h3>
                      <span>3 month ago</span>
                    </div>
                    <div className="reviews-item-body">
                      <div className="product-review">
                      <Rating
                        onClick={handleRating}
                        ratingValue={rating} 
                        showTooltip 
                        tooltipDefaultText={`${rating}`} 
                      /> 
                      </div>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                      <img src={ReviewImg} alt="" />
                    </div>
                </div>
                <div className="reviews-item">
                    <div className="reviews-item-header">
                      <img src={ReviewTwo} alt="" />
                      <h3>Smith Martin</h3>
                      <span>2 month ago</span>
                    </div>
                    <div className="reviews-item-body">
                      <div className="product-review">
                      <Rating
                        onClick={handleRating}
                        ratingValue={rating} 
                        showTooltip 
                        tooltipDefaultText={`${rating}`} 
                      /> 
                      </div>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                      
                    </div>
                </div>
                <div className="reviews-item">
                    <div className="reviews-item-header">
                      <img src={ReviewThree} alt="" />
                      <h3>Ketty Oreao</h3>
                      <span>2 month ago</span>
                    </div>
                    <div className="reviews-item-body">
                      <div className="product-review">
                      <Rating
                        onClick={handleRating}
                        ratingValue={rating} 
                        showTooltip 
                        tooltipDefaultText={`${rating}`} 
                      /> 
                      </div>
                      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                    
                    </div>
                </div>
              </div>      
              */}
            </Tab>
          </Tabs>
        </div>
     </section>     
    </>
  )
}
export default ProductInformation 