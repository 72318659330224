import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
// import Blog1 from '../assets/images/blog-1.png';
import Calendar from "../assets/images/calendar.svg";
import ShowMoreProductsIcon from "../assets/images/show-more-products-icon.svg";
import Skeleton  from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearMessages, fetchBlogs } from "../features/blog/blogActions";
import { toast } from "react-toastify";
import { stripHtmlTags, truncateText, formatDate } from "../helpers/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function OurBlogArticle() {
  const dispatch = useDispatch();
  const { errorMessage, blogs, loading } = useSelector((state) => state.blog);

  // State to manage pagination
  const [page, setPage] = useState(1);

  // State to store all fetched blogs
  const [allBlogs, setAllBlogs] = useState([]);

  // visible show more
  const [isVisibleShowMore, setIsVisibleShowMore] = useState(false);


  useEffect(() => {
    let data = { "page": page, limit: 12, blog_category_id: "" };
    dispatch(fetchBlogs(data));
  }, [dispatch, page]);

  useEffect(() => {
    if (blogs?.data) {
      setAllBlogs((prevBlogs) => [...prevBlogs, ...blogs.data]);

      if (blogs?.current_page < blogs?.total_pages) {
        setIsVisibleShowMore(true);
      } else {
        setIsVisibleShowMore(false);
      }

    }
  }, [blogs]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
      dispatch(clearMessages()); // Reset the messages after showing the toast
    }
  }, [errorMessage, dispatch]);

  const loadMoreBlogs = () => {
    const nextPage = page + 1;
    setPage(nextPage); // Update the page number
  };

  // Reset the state when component mounts or re-mounts
  useEffect(() => {
    // Reset all jobs and page number when component loads
    setAllBlogs([]); // Clear all jobs
    setPage(1); // Reset page to 1
    setIsVisibleShowMore(false);
  }, []);

  return (
    <>
      <section className="blog-list">
        <Row>
          {loading ? ( // Check if loading is true
            <>
              {Array.from({ length: 12 }).map(
                (
                  _,
                  index // Adjust the length based on how many skeletons you want
                ) => (
                  <Col md="6" lg="3" key={index}>
                    <div className="">
                      <div className="">
                        <Skeleton height={200} />{" "}
                        {/* Skeleton for blog image */}
                      </div>
                      <div className="">
                        <div className="">
                          <span>
                            <Skeleton width={20} height={20} />
                          </span>
                          <Skeleton width={100} />
                        </div>
                        <Skeleton height={20} />
                        <Skeleton height={60} />{" "}
                        {/* Skeleton for blog description */}
                      </div>
                    </div>
                  </Col>
                )
              )}
            </>
          ) : (
            allBlogs.map((blog, index) => (
              <Col md="6" lg="3" key={blog.id}>
                <Link
                  to={"/blog-details/" + blog.slug}
                  className="blog-list-box"
                >
                  <div className="blog-list-box-img">
                    <img
                      src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${blog.image}`}
                      alt={blog.title}
                    />
                  </div>
                  <div className="blog-list-box-text">
                    <div className="date">
                      <span>
                        <img src={Calendar} alt="" />
                      </span>
                      <p>{formatDate(blog.created_at)}</p>
                    </div>
                    <h3>{blog.title}</h3>
                    <p>{truncateText(stripHtmlTags(blog.description), 100)}</p>
                  </div>
                </Link>
              </Col>
            ))
          )}
        </Row>
        
        {/* {!loading && (
        <>
        {blogs?.data?.length > 0 && (
          <button onClick={loadMoreBlogs} className="cutsom-bttn">
            Load More <img src={ShowMoreProductsIcon} alt="" />
          </button>
        )}
        </>
        )} */}


          {isVisibleShowMore && (
            <Button
              className="cutsom-bttn"
              type="button"
              disabled={loading}
              onClick={loadMoreBlogs}
            >
              {loading ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  size="lg"
                  className="spinner-icon"
                />
              ) : (
                <>
                  Load More <img src={ShowMoreProductsIcon} alt="" />
                </>
              )}
            </Button>
          )}

      </section>
    </>
  );
}

export default OurBlogArticle;
