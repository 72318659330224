import React, {useEffect} from 'react'
import Header from '../component/Header'
import Banner from '../component/Banner' 
import HomeTodayOffer from '../component/HomeTodayOffer' 
import Wellness from '../component/Wellness' 
import BestSeller from '../component/BestSeller' 
import MedicineHealthProducts from '../component/MedicineHealthProducts' 
import WhyChooseUs from '../component/WhyChooseUs' 
import OfferSection from '../component/OfferSection' 
import PowerNature from '../component/PowerNature' 
// import Testimonials from '../component/Testimonials' 
import OurBlogArticle from '../component/OurBlogArticle' 
import ATC from '../component/ATC' 

import { useDispatch, useSelector } from "react-redux";
import { getPageContent } from "../features/visitor/visitorActions";
import { Helmet } from 'react-helmet-async';


function Home() {

  const dispatch = useDispatch();
  const { pageResponse } = useSelector((state) => state.visitor);

  useEffect(() => {
    let homeData = { code: 'home' };
    dispatch(getPageContent(homeData));
  }, [dispatch]);


  return (
    <>
      <Helmet>
        <title>Welcome | HealthGonia</title>
        <meta name="description" content="Discover top-quality healthcare products and services at HealthGonia. Your health and well-being are our top priorities. Shop now!" />
        <meta name="keywords" content="Healthcare, HealthGonia, Health Products, Wellness, Medical Services" />
      </Helmet>

      <div className='main home-page'>
        <Header/>
        <Banner/>
        <HomeTodayOffer offerSection={(pageResponse?.home_offer_section) ? JSON.parse(pageResponse.home_offer_section) : null}/>
        <Wellness categorySection={(pageResponse?.home_category_section) ? JSON.parse(pageResponse.home_category_section) : null}/>
        <BestSeller />
        <WhyChooseUs whyChooseUsSection={(pageResponse?.home_why_choose_us_section) ? JSON.parse(pageResponse.home_why_choose_us_section) : null} />
        <MedicineHealthProducts categoryOfferProductSection={(pageResponse?.home_product_list_section) ? JSON.parse(pageResponse.home_product_list_section) : null} />
        <OfferSection />
        <PowerNature powerNatureSection={(pageResponse?.home_power_of_section) ? JSON.parse(pageResponse.home_power_of_section) : null} />
        {/* <Testimonials /> */}
        <OurBlogArticle blogTitle="Our Blog & Article" />
        <ATC subscribeSection={(pageResponse?.home_subscribe_section) ? JSON.parse(pageResponse.home_subscribe_section) : null} />
      </div>
    </>
  )
}
export default Home


