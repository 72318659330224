import React, { useState, useEffect } from 'react';
import { Row, Col, Table, Button, Form } from 'react-bootstrap';
// import EditIcon from '../assets/images/cart/edit-icon.svg';
// import CartImg from '../assets/images/cart/cart-img.png' 
import { Link } from 'react-router-dom';

import DeleteIcon from '../assets/images/cart/delete-icon.svg';
import PlusIcon from '../assets/images/pd-plus.svg'
import MinusIcon from '../assets/images/pd-minus.svg'

// import TooltipIcon from '../assets/images/cart/tooltip.svg' 
// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';

import { clearMessages, fetchCart, updateCartQty, moveCartToCheckout, removeFromCart, fetchTaxRate, fetchPromocode } from '../features/cart/cartActions';
import { useDispatch, useSelector } from 'react-redux';
import { formatPrice } from '../helpers/helpers';
import CartSummary from '../component/CartSummary'
import { toast } from "react-toastify";


const ShoppingCart = () => {

  const { successMessage, errorMessage,  cart, taxRate, promoCode } = useSelector((state) => state.cart);
  
  const [checkedItems, setCheckedItems] = useState({});
  const [checkAll, setCheckAll] = useState(false);
  const [coupon, setCoupon] = useState('');  // State to hold the coupon code
  const [isCouponApplied, setIsCouponApplied] = useState(false); // State to track coupon status

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(fetchTaxRate());
  }, [dispatch])

  // Effect to initialize checkedItems when cart updates
  useEffect(() => {
    const initialCheckedItems = cart.reduce((acc, item) => {
      acc[`${item.product_id}_${item.variation_id}`] = item.is_selected_to_checkout; // Set based on actual checkout state
      return acc;
    }, {});
  
    setCheckedItems(initialCheckedItems);
    setCheckAll(cart.every(item => item.is_selected_to_checkout)); // Update checkAll state
  }, [cart]);


  useEffect(() => {
    if(promoCode !== null){
      setIsCouponApplied(true);
    }else{
      setIsCouponApplied(false);
    }
  }, [promoCode])


  useEffect(() => {
    setCoupon('');
    setIsCouponApplied(false)
  }, [])



  // Handle coupon code input
  const handleCouponChange = (e) => {
    let input = e.target.value.toUpperCase();  // Convert input to uppercase
    // Only allow uppercase letters and digits
    const regex = /^[A-Z0-9]*$/;
    
    if (regex.test(input)) {
      setCoupon(input); // Update state if input is valid
    }
  };


  // Handle coupon submission
  const handleCouponSubmit = async (e) => {
    e.preventDefault();

    if (isCouponApplied) {
      // Clear the coupon
      setCoupon('');
      setIsCouponApplied(false);
    } else {

      try {
        let formData = {"code" : coupon};
        dispatch(fetchPromocode(formData));
      } catch (err) {
        toast.error("Failed to verify coupon code. Please try again.", { autoClose: 3000, hideProgressBar: true });
      }

    }
  };

 
  // Handle individual item check/uncheck
  const handleCheck = (productId, attributeId) => {
    const key = `${productId}_${attributeId}`;
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems, [key]: !prevCheckedItems[key] };
      setCheckAll(Object.values(newCheckedItems).every(Boolean)); // Update checkAll based on all items' states
      return newCheckedItems;
    });


    // Dispatch the Redux action to update the is_selected_to_checkout key only when the checkbox is changed
    dispatch(moveCartToCheckout(productId, attributeId));
  };


  const handleCheckAll = () => {
    const updatedCheckAll = !checkAll; // Toggle the current checkAll state
    setCheckAll(updatedCheckAll);
  
    const updatedCheckedItems = cart.reduce((acc, item) => {
      const key = `${item.product_id}_${item.variation_id}`;
      acc[key] = updatedCheckAll; // Set the checkbox state
      return acc;
    }, {});
  
    setCheckedItems(updatedCheckedItems);
  
    // Dispatch action to update is_selected_to_checkout for all items
    dispatch(moveCartToCheckout(null, null, updatedCheckAll)); // Assuming your action can handle bulk updates
  };


  // Count the number of true values
  const countCheckedItems = Object.values(checkedItems).filter(value => value === true).length;
  
  // Calculate total for selected items
  const total = cart.reduce((acc, item) => {
    const key = `${item.product_id}_${item.variation_id}`;
    return checkedItems[key] ? acc + item.sub_total : acc;
  }, 0);

  // Calculate total for selected items
  const discountTotal = cart.reduce((acc, item) => {
    const key = `${item.product_id}_${item.variation_id}`;
    return checkedItems[key] ? acc + item.regular_price : acc;
  }, 0);



  // Handle quantity increase
  const handleIncrease = (index) => {
    let updatedCart = cart.map((item, i) => {
      // If the current item is the one we're updating, return a new object with updated quantity and subtotal
      if (i === index) {
        return {
          ...item, // Spread the existing properties
          qty: item.qty + 1, // Update the quantity
          sub_total: (item.qty + 1) * item.price, // Recalculate subtotal
        };
      }
      // Otherwise, return the item as it is
      return item;
    });
  
    // Dispatch the Redux action to update the cart quantity in the backend
    dispatch(updateCartQty(updatedCart[index].product_id, updatedCart[index].variation_id, updatedCart[index].qty));
    dispatch(fetchCart());
  };


  const handleDecrease = (index) => {
    let updatedCart = cart.map((item, i) => {
      if (i === index && item.qty > 1) {
        // Return a new object with the decreased quantity and updated subtotal
        return {
          ...item,
          qty: item.qty - 1,
          sub_total: (item.qty - 1) * item.price,
        };
      }
      return item; // Return the item as it is if no change
    });
  
    // Dispatch the Redux action to update the cart quantity in the backend
    const updatedItem = updatedCart[index];
    if (updatedItem.qty !== cart[index].qty) {
      dispatch(updateCartQty(updatedItem.product_id, updatedItem.variation_id, updatedItem.qty));
      dispatch(fetchCart());
    }
  };

  
  // Update handleChange
  const handleChange = (event, index) => {
    const newValue = parseInt(event.target.value, 10); // Parse the input value
    if (!isNaN(newValue) && newValue > 0) {
      // Clone the cart to prevent direct mutation
      const updatedCart = cart.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            qty: newValue,
            sub_total: newValue * item.price, // Update sub_total based on new quantity
          };
        }
        return item;
      });
  
      // Dispatch Redux action to update the cart quantity in the backend
      const updatedItem = updatedCart[index];
      dispatch(updateCartQty(updatedItem.product_id, updatedItem.variation_id, updatedItem.qty));
      dispatch(fetchCart());
    }
  };
  

  // Remove item from cart
  const removeItem = (product_id, variation_id) => {
    dispatch(removeFromCart(product_id, variation_id));
  }


  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage, { autoClose: 3000, hideProgressBar: true });
      dispatch(clearMessages());
    }

    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
      dispatch(clearMessages());
    }
  }, [successMessage, errorMessage,  dispatch]);

  return (
    <div className="shopping-cart">
      <Row>
        <Col xl={9}>
          <div className="shopping-cart-box">
            <h3>Shopping Cart</h3>

            {cart.length > 0  ? (
              <>
              <Table>
                <thead>
                  <tr>
                    <th>
                      <Form.Check className='custom-checkbox' type="checkbox" checked={checkAll} onChange={handleCheckAll} />
                    </th>
                    <th>Item</th>
                    <th>Price</th>
                    <th>Qty</th>
                    <th>Sub Total</th>
                    {/* <th><Link className='edit-btn' to="" style={{ cursor: 'auto' }}><img src={EditIcon} alt="" /></Link></th> */}
                    <th><Link className='delete-btn' to="" style={{ cursor: 'auto' }}><img src={DeleteIcon} alt="" /></Link></th>
                  </tr>
                </thead>
                <tbody>
                  {cart.length > 0 && (
                    <>
                    {cart.map((item, index) => (
                      <tr key={index}>
                        <td data-th="Select">
                          <Form.Check
                            className="custom-checkbox"
                            type="checkbox"
                            checked={checkedItems[`${item.product_id}_${item.variation_id}`] || false} // Default to false if undefined
                            onChange={() => handleCheck(item.product_id, item.variation_id)}
                          />
                        </td>
                        <td data-th="Item">
                          <div className="cart-box">
                            <div className="cart-box-img">
                              <Link to={"/products/"+item.slug}>
                                <img
                                  src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${item.image}`}
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="cart-box-content">
                              <span>{item.category_title}</span>
                              <h2>
                                <Link to={"/products/"+item.slug}>{item.title}</Link>
                              </h2>
                              <Link to={"/products/"+item.slug}>{item.variation_title}</Link>
                            </div>
                          </div>
                        </td>
                        <td data-th="Price">
                          <span className="price-text">{item.currency}{formatPrice(item.price)}</span>
                        </td>
                        <td data-th="Qty">
                          <div className="plus-minus-input">
                            <button onClick={() => handleDecrease(index)}>
                              <img src={MinusIcon} alt="-" />
                            </button>
                            <input
                              className="form-control"
                              type="number"
                              value={item.qty || 0} // Default to 0 if qty is undefined
                              onChange={(e) => handleChange(e, index)}
                            />
                            <button onClick={() => handleIncrease(index)}>
                              <img src={PlusIcon} alt="+" />
                            </button>
                          </div>
                        </td>
                        <td data-th="Sub Total">
                          <span className="price-text">{item.currency}{formatPrice(item.sub_total)}</span>
                        </td>
                        {/* <td data-th="Action">
                          <Link className="edit-btn" to="">
                            <img src={EditIcon} alt="Edit" />
                          </Link>
                        </td> */}
                        <td data-th="Action">
                          <Link className="delete-btn" to="" onClick={() => removeItem(item.product_id, item.variation_id)}>
                            <img src={DeleteIcon} alt="Delete" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                    </>
                  )}
                </tbody>
              </Table>
              <Link className='cutsom-bttn' to="/products">Continue Shopping</Link>
              </>
            ) : (
              <>
                <p>Cart item not found.</p>
              </>
            )}
          </div>
        </Col>
        {countCheckedItems > 0  && (
        <Col xl={3}>
          <div className="coupon-code-box">
            <div className='box-header'>
              <h3>Coupon Code</h3>
              <p>Lorem ipsum is simply dummy text of the printing and typesetting industry.</p>
            </div>
            <div className="discount-code">
              <h4>Apply Discount Code</h4>
              <Form onSubmit={handleCouponSubmit}>
                <Form.Group controlId="formCoupon">
                  <Form.Control 
                    type="text" 
                    placeholder="Enter Promo Code"
                    value={coupon}
                    onChange={handleCouponChange}
                  />
                </Form.Group>
                <Button type="submit" className="cutsom-bttn">
                  {isCouponApplied ? 'Clear' : 'Apply'}  {/* Toggle button text */}
                </Button>
              </Form>
            </div>
            <CartSummary total={total} discountTotal={discountTotal} checkedItems={checkedItems} countCheckedItems={countCheckedItems} taxRate={taxRate} currency={(cart.length > 0) ? cart?.[0]?.currency : ''} promoCode={promoCode} isCouponApplied={isCouponApplied}></CartSummary>
          </div>
        </Col>
        )}
      </Row>
    </div>
  );
};

export default ShoppingCart;
