import React, { useState, useRef, useEffect } from 'react';
import PlayBtn from '../assets/images/play-icon.svg';

function PowerNature({ powerNatureSection }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const playPause = () => {
    const video = videoRef.current;
    if (video.paused) {
      video.play();
      setIsPlaying(true);
    } else {
      video.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    // Handle cases where dynamic data is not immediately available
    if (!powerNatureSection) return;
  }, [powerNatureSection]);

  if (!powerNatureSection) {
    // Show a loading state or fallback UI if powerNatureSection is not yet loaded
    return <div></div>;
  }

  return (
    <section className='power-nature'>
      <div className="power-nature-wrap">
        <div className="common-header">
          <span>{powerNatureSection?.title || 'Inspired by the'}</span>
          <h2>{powerNatureSection?.short_title || 'Power of Nature'}</h2>
          <p>{powerNatureSection?.description || 'Default description if missing.'}</p>
        </div>
        <div className="video-group">
          <video
            ref={videoRef}
            poster={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${powerNatureSection?.image_thumb || 'default-poster.png'}`}
            preload="auto"
          >
            <source src={powerNatureSection?.video_url} type="video/mp4" />
            <source src={powerNatureSection?.video_url} type="video/ogg" />
            Your browser does not support HTML video.
          </video>
          {!isPlaying && (
            <button className="play-btn" onClick={playPause}>
              <img src={PlayBtn} alt="Play Button" />
            </button>
          )}
        </div>
      </div>
    </section>
  );
}

export default PowerNature;
