// src/helpers/helpers.js

/**
 * Formats a phone number as the user types.
 * 
 * @param {string} value - The phone number input value.
 * @returns {string} - The formatted phone number.
 */
export const formatPhoneNumber = (value) => {
    if(!value){return;}
    // Remove all non-digit characters
    const cleaned = value.replace(/\D/g, '');
  
    // Format the cleaned number
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
    if (match) {
      return `${match[1]}${match[2] ? ' ' : ''}${match[2]}${match[3] ? ' ' : ''}${match[3]}`;
    }
    return value;
};

export const stripHtmlTags = (html) => {
  if(html === null){return;}
  return html.replace(/<[^>]*>/g, '');
};  

export const truncateText = (text, limit) => {
  
  if(text){
    // Remove HTML tags
    const strippedText = text.replace(/<[^>]*>/g, '');
    // Truncate text and add ellipses if needed
    return strippedText.length > limit ? strippedText.substring(0, limit) + '...' : strippedText;
  }else{
    return;
  }
};

export const formatDate = (dateString) => {
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', options);
};


export const numberFormatter = (number) => {
  if(number){
    // Format the number as per the Indian numbering system
    return new Intl.NumberFormat('en-IN').format(number);
  }else{
    return;
  }
}

export const addressStringCreate = (row) => {
  let addressString = "";
  if(row.address_line1 !== ""){
    addressString += row.address_line1;
  }
  if(row.address_line2 !== ""){
    addressString += ", "+row.address_line2;
  }
  if(row.city_name !== ""){
    addressString += ", "+row.city_name;
  }
  if(row.state_name !== ""){
    addressString += ", "+row.state_name;
  }
  if(row.country_name !== ""){
    addressString += ", "+row.country_name;
  }
  if(row.pincode !== ""){
    addressString += " - "+row.pincode;
  }

  return addressString;
}

export const formatPrice = (price) => {
  // Ensures price is shown with 2 decimal places
  return parseFloat(price).toFixed(2);
};