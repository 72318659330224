import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ProductDetailsSkeleton = () => {
  return (
    <section className='product-details'>
      <Row>
        {/* Left Column: Image Loader */}
        <Col lg="5" xl="4">
          <div className="product-list-slider">
            <Skeleton height={400} width="100%" />
            <div className="thumbs-swiper d-flex">
              {Array(3).fill().map((_, idx) => (
                <Skeleton key={idx} height={125} width={125} style={{ marginRight: '15px', marginTop:'25px' }} />
              ))}
            </div>
          </div>
        </Col>

        {/* Right Column: Content Loader */}
        <Col lg="7" xl="8">
          <div className="product-details-content">
            {/* Title & Category */}
            <Skeleton height={40} width="60%" />
            <Skeleton height={20} width="30%" style={{ marginBottom: '20px' }} />

            {/* Rating */}
            <Skeleton height={25} width="120px" />

            {/* Price */}
            <div className='price-value'>
              <Skeleton height={30} width="40%" />
              <Skeleton height={20} width="30%" />
            </div>

            {/* Short Description */}
            <Skeleton count={3} />

            {/* Variations */}
            <div className="size-box">
              <Skeleton height={25} width="40%" />
              <div className="size-box-group">
                {Array(4).fill().map((_, idx) => (
                  <Skeleton key={idx} height={50} width="100%" style={{ marginBottom: '10px' }} />
                ))}
              </div>
            </div>

            {/* Quantity */}
            <div className="quantity-box">
              <Skeleton height={25} width="40%" />
              <div className="quantity-box-group">
                <Skeleton height={50} width="150px" />
                <Skeleton height={50} width="200px" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default ProductDetailsSkeleton;
