import { createAction } from '@reduxjs/toolkit';
import {
    saveOrderToLocalService,
    getLocalOrderService,
    clearOrderFromLocalService
} from '../../services/checkoutService';

// Action Creators
export const clearCheckoutMessages = createAction('checkout/clearMessages');

// Store Order
export const storeCheckoutInfoStart = createAction('checkout/storeCheckoutInfoStart');
export const storeCheckoutInfoSuccess = createAction('checkout/storeCheckoutInfoSuccess');
export const storeCheckoutInfoFailure = createAction('checkout/storeCheckoutInfoFailure');

// Fetch Local Order
export const fetchLocalCheckoutStart = createAction('checkout/fetchLocalCheckoutStart');
export const fetchLocalCheckoutSuccess = createAction('checkout/fetchLocalCheckoutSuccess');
export const fetchLocalCheckoutFailure = createAction('checkout/fetchLocalCheckoutFailure');

// Clear Local Order
export const clearLocalCheckoutStart = createAction('checkout/clearLocalCheckoutStart');
export const clearLocalCheckoutSuccess = createAction('checkout/clearLocalCheckoutSuccess');
export const clearLocalCheckoutFailure = createAction('checkout/clearLocalCheckoutFailure');

// Thunks

// Store Order Information in Local Storage
export const storeCheckoutInfo = (checkoutOrder) => async (dispatch) => {
    dispatch(storeCheckoutInfoStart());
    try {
        // Store the order info (cart items, promo code, tax data) in local storage
        await saveOrderToLocalService(checkoutOrder);
        
        dispatch(storeCheckoutInfoSuccess(checkoutOrder));
    } catch (error) {
        dispatch(storeCheckoutInfoFailure(error.message));
    }
};

// Fetch Order Information from Local Storage
export const fetchLocalCheckoutInfo = () => async (dispatch) => {
    dispatch(fetchLocalCheckoutStart());
    try {
        const checkoutOrder = await getLocalOrderService();
        if (checkoutOrder) {
            dispatch(fetchLocalCheckoutSuccess(checkoutOrder));
        } else {
            dispatch(fetchLocalCheckoutFailure("Order not found."));
        }
    } catch (error) {
        dispatch(fetchLocalCheckoutFailure(error.message));
    }
};

// Clear Order Information from Local Storage
export const clearLocalCheckout = () => async (dispatch) => {
    dispatch(clearLocalCheckoutStart());
    try {
        // Clear the local order data
        await clearOrderFromLocalService();
        
        dispatch(clearLocalCheckoutSuccess());
    } catch (error) {
        dispatch(clearLocalCheckoutFailure(error.message));
    }
};
