import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import CareerForm from "../component/CareerForm";
import arrowDown from "../assets/images/arrow-down.svg";
import { Accordion } from "react-bootstrap";
import Experience from "../assets/images/experience.svg";
import WorkingHours from "../assets/images/working-hours.svg";
import WorkingDays from "../assets/images/working-days.svg";
import Salary from "../assets/images/salary.svg";
import Vacancy from "../assets/images/vacancy.svg";
import Deadline from "../assets/images/deadline.svg";
import careerWhyWorkWithImg from "../assets/images/career-why-work-with-img.png";
import whiteArrow from "../assets/images/button-arrow.svg";
import ShowMoreProductsIcon from "../assets/images/show-more-products-icon.svg";
import Skeleton from "react-loading-skeleton";

import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getJobs } from "../features/career/careerActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "../helpers/helpers";

function CareerComp() {
  const dispatch = useDispatch();
  const { jobListData, loading } = useSelector((state) => state.career);

  // State to manage pagination
  const [page, setPage] = useState(1);

  // State to store all fetched jobs
  const [allJobs, setAllJobs] = useState([]);

  // visible show more
  const [isVisibleShowMore, setIsVisibleShowMore] = useState(false);

  const [activeKey, setActiveKey] = useState(null);

  // Fetch jobs when the component mounts and reset data
  useEffect(() => {
    let data = { page: page, limit: 3 };
    dispatch(getJobs(data)); // Dispatch to fetch jobs
  }, [dispatch, page]);

  // Handle job list data update
  useEffect(() => {
    if (jobListData?.data !== undefined) {
      setAllJobs((prevJobs) => [...prevJobs, ...jobListData.data]);
      // Check if there are more pages to load
      if (jobListData?.current_page < jobListData?.total_pages) {
        setIsVisibleShowMore(true);
      } else {
        setIsVisibleShowMore(false);
      }
    }
  }, [jobListData]);

  // Load more jobs on "Show More" button click
  const loadMoreJobs = async () => {
    const nextPage = page + 1; // Calculate the next page
    setPage(nextPage); // Update the page number first
  };

  // Function to close all accordions
  const closeAllAccordions = () => {
    setActiveKey(null); // Set to null or '' to close all
  };

  // Reset the state when component mounts or re-mounts
  useEffect(() => {
    // Reset all jobs and page number when component loads
    setAllJobs([]); // Clear all jobs
    setPage(1); // Reset page to 1
    setIsVisibleShowMore(false);
  }, []);

  return (
    <>
      <section className="career">
        <div className="common-header">
          <h2>Current Openings</h2>
          <p>We're always looking for talented people</p>
        </div>
        <Row>
          <Col>
            <Accordion activeKey={activeKey} defaultActiveKey="p0">
              {loading ? (
                // Skeleton loader for when data is being fetched
                <>
                  {Array(3)
                    .fill(0)
                    .map((_, index) => (
                      <div key={index} className="skeleton-wrapper">
                        <Skeleton height={50} width={`40%`} />
                        <Skeleton
                          count={1}
                          height={15}
                          style={{ margin: "10px 0 35px" }}
                          width={`75%`}
                        />
                      </div>
                    ))}
                </>
              ) : (
                allJobs.map((job, index) => (
                  <Accordion.Item eventKey={"k" + index} key={index}>
                    <Accordion.Header onClick={() =>
                      setActiveKey(activeKey === "k" + index ? null : "k" + index)
                    }>
                      <div className="opening-header">
                        <div className="opening-text">
                          <h2>{job.job_title}</h2>
                          <div className="jld-group">
                            <p>
                              Job Type : <b>{job.job_type}</b>
                            </p>
                            <p>
                              Location : <b>{job.location}</b>
                            </p>
                            <p>
                              Date : <b>{formatDate(job.created_at)}</b>
                            </p>
                          </div>
                        </div>
                        <div className="opening-btn">
                          <p>
                            Jobs Details <img src={arrowDown} alt="" />
                          </p>
                        </div>
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="opening-body">
                        <ul>
                          <li>
                            <img src={Experience} alt="" />
                            <span>Experience</span>
                            <p>{job.experience} Years Experience</p>
                          </li>
                          <li>
                            <img src={WorkingHours} alt="" />
                            <span>Working Hours</span>
                            <p>{job.working_hours} Hours</p>
                          </li>
                          <li>
                            <img src={WorkingDays} alt="" />
                            <span>Working Days</span>
                            <p>{job.working_days} Days</p>
                          </li>
                          <li>
                            <img src={Salary} alt="" />
                            <span>Salary</span>
                            <p>{job.salary}</p>
                          </li>
                          <li>
                            <img src={Vacancy} alt="" />
                            <span>Vacancy</span>
                            <p>{job.no_vacancy} Vacancies</p>
                          </li>
                          <li>
                            <img src={Deadline} alt="" />
                            <span>Deadline</span>
                            <p>{formatDate(job.deadline)}</p>
                          </li>
                        </ul>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: job.job_description,
                          }}
                        ></div>
                        <div id="postOne">
                          <CareerForm job={job} closeAllAccordions={closeAllAccordions} />
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                ))
              )}
            </Accordion>
          </Col>
        </Row>

        <div className="d-flex justify-content-center mt-5">
          {isVisibleShowMore && (
            <Button
              className="cutsom-bttn"
              type="button"
              disabled={loading}
              onClick={loadMoreJobs}
            >
              {loading ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  size="lg"
                  className="spinner-icon"
                />
              ) : (
                <>
                  Show More Jobs <img src={ShowMoreProductsIcon} alt="" />
                </>
              )}
            </Button>
          )}
        </div>
      </section>
      <section className="about-section bg-primary-light padding-100">
        <Row className="align-items-center">
          <Col md="6" lg="6" className=" order-2 order-md-1">
            <div className="about-section-img">
              <img src={careerWhyWorkWithImg} alt="" />
            </div>
          </Col>
          <Col md="6" lg="6" className="order-1 order-md-2">
            <div className="about-section-text pr-100">
              <span>Why Work With</span>
              <h3>Smart Shopping</h3>
              <p>
                What sets us apart from other service providers is our
                commitment to personalization, community engagement, and
                open-mindedness. We understand that each person needs are
                unique, and we offer tailored solutions that resonate with their
                individual circumstances. Beyond our dedication to serving our
                members, we extend our support to the wider community, embodying
                a strong sense of responsibility to make a positive impact.
                Embracing diverse perspectives and innovative approaches, we
                foster an open-minded culture that continually seeks progressive
                solutions to evolving challenges. With trust, integrity, and a
                passion for what we do, we stand out as a provider dedicated to
                not only safeguarding your well-being but also enriching your
                life and the lives of those around you.
              </p>
              <Link href="/career" className="cutsom-bttn">
                Join Our Team <img src={whiteArrow} alt="" />
              </Link>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}
export default CareerComp;
