import React, { useState, useEffect } from "react";
import SubBanner from '../component/SubBanner'  
import { Row, Col, Tabs, Tab } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import PersonalInfo from '../component/PersonalInfo';
import MyOrdersComp from '../component/MyOrdersComp';
import WishlistProducts from '../component/WishlistProducts';

import AddressBookComp from '../component/AddressBookComp';
import PaymentInfoComp from '../component/PaymentInfoComp';
import Header from '../component/Header';
import SidebarBtn from '../assets/images/sidebar-icon.svg'
import CloseIcon from '../assets/images/close-white.svg'
import classNames from 'classnames';

import { useDispatch, useSelector } from "react-redux";
import { getProfile } from "../features/user/userActions";
import { toast } from "react-toastify"; // Import toast
import { Helmet } from 'react-helmet-async';

function MyAccount() {
  const [activeTab, setActiveTab] = useState('personalInfo');
  const [isVisible, setIsVisible] = useState(false);

  // Get the slug from the URL
  const { innerPage } = useParams();

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  const personalInfoClick = () => {
    setActiveTab('personalInfo'); // Set the active tab
    setIsVisible(false); // Toggle visibility
  };
  const ordersClick = () => {
    setActiveTab('orders'); // Set the active tab
    setIsVisible(false); // Toggle visibility
  };
  const wishlistClick = () => {
    setActiveTab('wishlist'); // Set the active tab
    setIsVisible(false); // Toggle visibility
  };
  const addressBookClick = () => {
    setActiveTab('addressBook'); // Set the active tab
    setIsVisible(false); // Toggle visibility
  };
  const paymentInfoClick = () => {
    setActiveTab('paymentInfo'); // Set the active tab
    setIsVisible(false); // Toggle visibility
  };

  const dispatch = useDispatch();
  const { successMessage, errorMessage, profile } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    dispatch(getProfile());
  }, [dispatch]);

  
  useEffect(() => {
    if (successMessage) {
      toast.success(successMessage, { autoClose: 3000, hideProgressBar: true });
    }
    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
    }
  }, [successMessage, errorMessage]);

  
  useEffect(() =>{
    if(innerPage !== undefined){
      if(innerPage === "wishlist"){
        setActiveTab('wishlist');
      }else if(innerPage === "address-book"){
        setActiveTab('addressBook');
      }else if(innerPage === "my-orders"){
        setActiveTab('orders');
      }else{
        setActiveTab('personalInfo');
      }
      setIsVisible(false);
    }else{
      setActiveTab('personalInfo');
      setIsVisible(false);
    }
  }, [innerPage])

  useEffect(() => {
    if(activeTab === "orders"){
      window.history.replaceState(null, "", '/profile/my-orders'); 
    }else if(activeTab === "wishlist"){
      window.history.replaceState(null, "", '/profile/wishlist'); 
    }else if(activeTab === "addressBook"){
      window.history.replaceState(null, "", '/profile/address-book'); 
    }else if(activeTab === "paymentInfo"){
      window.history.replaceState(null, "", '/profile/payment-info'); 
    }else {
      window.history.replaceState(null, "", '/profile'); 
    }
  }, [activeTab]);

  return (
    <>
      <Helmet>
        <title>My Account | HealthGonia</title>
        <meta name="description" content="Discover top-quality healthcare products and services at HealthGonia. Your health and well-being are our top priorities. Shop now!" />
        <meta name="keywords" content="Healthcare, HealthGonia, Health Products, Wellness, Medical Services" />
      </Helmet>
      <div className='main profile-page'>
        <Header />
        <SubBanner HeaderTitle="My Account" HeaderSubtitle=""/> 
        <div className="profile-main">
          <Row>
            <Col lg={3}>
              <div className={classNames("profile-sidebar ", { "filter-box--visible": isVisible })}>
                <h2>Welcome {profile?.name},</h2>
                <ul>
                  <li>
                    <Link to='#' onClick={personalInfoClick} className={activeTab === 'personalInfo' ? 'active' : ''}>
                      <svg className='stroke-svg' width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="6.5" r="4" strokeWidth="1.5"/>
                        <ellipse cx="12" cy="17.5" rx="7" ry="4" strokeWidth="1.5"/>
                      </svg>
                      Personal Information
                    </Link>
                  </li>
                  <li>
                    <Link to='#' onClick={ordersClick} className={activeTab === 'orders' ? 'active' : ''}>
                    <svg className='stroke-svg' width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M15.5777 3.88197L17.5777 4.93152C19.7294 6.06066 20.8052 6.62523 21.4026 7.63974C22 8.65425 22 9.91667 22 12.4415V12.5585C22 15.0833 22 16.3458 21.4026 17.3603C20.8052 18.3748 19.7294 18.9393 17.5777 20.0685L15.5777 21.118C13.8221 22.0393 12.9443 22.5 12 22.5C11.0557 22.5 10.1779 22.0393 8.42229 21.118L6.42229 20.0685C4.27063 18.9393 3.19479 18.3748 2.5974 17.3603C2 16.3458 2 15.0833 2 12.5585V12.4415C2 9.91667 2 8.65425 2.5974 7.63974C3.19479 6.62523 4.27063 6.06066 6.42229 4.93152L8.42229 3.88197C10.1779 2.96066 11.0557 2.5 12 2.5C12.9443 2.5 13.8221 2.96066 15.5777 3.88197Z" strokeWidth="1.5" strokeLinecap="round"/>
                      <path d="M21 8L17 10M12 12.5L3 8M12 12.5V22M12 12.5C12 12.5 14.7426 11.1287 16.5 10.25C16.6953 10.1524 17 10 17 10M17 10V13.5M17 10L7.5 5" strokeWidth="1.5" strokeLinecap="round"/>
                    </svg>
                      My Orders
                    </Link>
                  </li>
                  <li>
                    <Link to='#' onClick={wishlistClick} className={activeTab === 'wishlist' ? 'active' : ''}>
                    <svg className='path-svg' width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8.96173 19.4111L9.42605 18.8221L8.96173 19.4111ZM12 6.00088L11.4596 6.52098C11.601 6.66787 11.7961 6.75088 12 6.75088C12.2039 6.75088 12.399 6.66787 12.5404 6.52098L12 6.00088ZM15.0383 19.4111L15.5026 20.0001L15.0383 19.4111ZM9.42605 18.8221C7.91039 17.6273 6.25307 16.4605 4.93829 14.98C3.64922 13.5285 2.75 11.8347 2.75 9.63734H1.25C1.25 12.3029 2.3605 14.3363 3.81672 15.976C5.24723 17.5868 7.07077 18.8755 8.49742 20.0001L9.42605 18.8221ZM2.75 9.63734C2.75 7.48647 3.96537 5.68277 5.62436 4.92444C7.23607 4.18772 9.40166 4.38282 11.4596 6.52098L12.5404 5.48078C10.0985 2.94377 7.26409 2.52563 5.00076 3.5602C2.78471 4.57317 1.25 6.92527 1.25 9.63734H2.75ZM8.49742 20.0001C9.00965 20.4039 9.55954 20.8345 10.1168 21.1602C10.6739 21.4857 11.3096 21.7502 12 21.7502V20.2502C11.6904 20.2502 11.3261 20.1295 10.8736 19.8651C10.4213 19.6008 9.95208 19.2368 9.42605 18.8221L8.49742 20.0001ZM15.5026 20.0001C16.9292 18.8755 18.7528 17.5868 20.1833 15.976C21.6395 14.3363 22.75 12.3029 22.75 9.63734H21.25C21.25 11.8347 20.3508 13.5285 19.0617 14.98C17.7469 16.4605 16.0896 17.6273 14.574 18.8221L15.5026 20.0001ZM22.75 9.63734C22.75 6.92527 21.2153 4.57317 18.9992 3.5602C16.7359 2.52563 13.9015 2.94377 11.4596 5.48078L12.5404 6.52098C14.5983 4.38282 16.7639 4.18772 18.3756 4.92444C20.0346 5.68277 21.25 7.48647 21.25 9.63734H22.75ZM14.574 18.8221C14.0479 19.2368 13.5787 19.6008 13.1264 19.8651C12.6739 20.1295 12.3096 20.2502 12 20.2502V21.7502C12.6904 21.7502 13.3261 21.4857 13.8832 21.1602C14.4405 20.8345 14.9903 20.4039 15.5026 20.0001L14.574 18.8221Z" />
                    </svg>
                    Wishlist
                    </Link>
                  </li>
                  <li>
                    <Link to='#' onClick={addressBookClick} className={activeTab === 'addressBook' ? 'active' : ''}>
                      <svg className='stroke-svg' width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5 9.01464C5 5.4167 8.13401 2.5 12 2.5C15.866 2.5 19 5.4167 19 9.01464C19 12.5844 16.7658 16.7499 13.2801 18.2396C12.4675 18.5868 11.5325 18.5868 10.7199 18.2396C7.23416 16.7499 5 12.5844 5 9.01464Z" strokeWidth="1.5"/>
                        <path d="M14 9.5C14 10.6046 13.1046 11.5 12 11.5C10.8954 11.5 10 10.6046 10 9.5C10 8.39543 10.8954 7.5 12 7.5C13.1046 7.5 14 8.39543 14 9.5Z" strokeWidth="1.5"/>
                        <path d="M20.9605 16C21.6259 16.6025 22 17.2816 22 18C22 20.4853 17.5228 22.5 12 22.5C6.47715 22.5 2 20.4853 2 18C2 17.2816 2.37412 16.6025 3.03947 16" strokeWidth="1.5" strokeLinecap="round"/>
                      </svg>
                      Address Book
                    </Link>
                  </li>
                  <li>
                    <Link to='#' onClick={paymentInfoClick} className={activeTab === 'paymentInfo' ? 'active' : ''}>
                      <svg className='stroke-svg' width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 12.5C2 8.72876 2 6.84315 3.17157 5.67157C4.34315 4.5 6.22876 4.5 10 4.5H14C17.7712 4.5 19.6569 4.5 20.8284 5.67157C22 6.84315 22 8.72876 22 12.5C22 16.2712 22 18.1569 20.8284 19.3284C19.6569 20.5 17.7712 20.5 14 20.5H10C6.22876 20.5 4.34315 20.5 3.17157 19.3284C2 18.1569 2 16.2712 2 12.5Z" strokeWidth="1.5"/>
                        <path d="M10 16.5H6" strokeWidth="1.5" strokeLinecap="round"/>
                        <path d="M14 16.5H12.5" strokeWidth="1.5" strokeLinecap="round"/>
                        <path d="M2 10.5L22 10.5" strokeWidth="1.5" strokeLinecap="round"/>
                      </svg>
                      Payment Info
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col lg={9}>
              <div className="profile-content">
                <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k)} id="profile-tabs">
                  <Tab eventKey="personalInfo" title="Personal Information"> 
                    <div className='personal-information'>                                       
                      <PersonalInfo profile={profile} />
                    </div>
                  </Tab>
                  <Tab eventKey="orders" title="My Orders"> 
                  <div className='my-orders'>                     
                    <MyOrdersComp />
                  </div>
                  </Tab>
                  <Tab eventKey="wishlist" title="Wishlist">
                    <div className='wishlist product p-0'>
                      <WishlistProducts />
                    </div>
                  </Tab>
                  <Tab eventKey="addressBook" title="Address Book">                    
                    <div className='address-book'>
                      <AddressBookComp />
                    </div>
                  </Tab>
                  <Tab eventKey="paymentInfo" title="Payment Info">                    
                    <div className='payment-info'>
                      <PaymentInfoComp />
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </div>
        <Link onClick={toggleVisibility} className="sidebar-bttn"><img src={isVisible ? CloseIcon : SidebarBtn} alt="" /></Link>
      </div>
    </>
  )
}
export default MyAccount


