import React, {useEffect} from 'react'
import Header from '../component/Header'
import SubBanner from '../component/SubBanner'
import ContactUsComp from '../component/ContactUsComp' 
import { useDispatch, useSelector } from "react-redux";
import { getPageContent } from "../features/visitor/visitorActions";
import { Helmet } from 'react-helmet-async';

function ContactUs() {

  const dispatch = useDispatch();
  const { pageResponse } = useSelector((state) => state.visitor);

  useEffect(() => {
    let data = { code: 'contact-us' };
    dispatch(getPageContent(data));
  }, [dispatch]);

  return (
    <>
      <Helmet>
        <title>Contact Us | HealthGonia</title>
        <meta name="description" content="Get in touch with HealthGonia. We're here to answer your questions, provide support, and help you with any inquiries." />
        <meta name="keywords" content="Contact Us, Support, Inquiry, HealthGonia, Customer Service, Help" />
      </Helmet>

      <div className='main contact-us-page'>
        <Header/>
        <SubBanner HeaderTitle="Contact Us" HeaderSubtitle=""/>
        <ContactUsComp pageResponse={pageResponse} />     
      </div>
    </>
  )
}
export default ContactUs


