import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
// import VitaminsSuppliments1 from "../assets/images/vitamins-suppliments-1.svg";
import VitaminsSupplimentsArrow from "../assets/images/vitamins-suppliments-arrow.svg";

import { useDispatch, useSelector } from "react-redux";
import { getAdvertise } from "../features/visitor/visitorActions";

function OfferSection() {
  const [offerFirst, setOfferFirst] = useState("");
  const [offerSecond, setOfferSecond] = useState("");
  const [offerThird, setOfferThird] = useState("");
  const [offerFourth, setOfferFourth] = useState("");

  const dispatch = useDispatch();
  const { advertiseResponse } = useSelector((state) => state.visitor);

  useEffect(() => {
    dispatch(getAdvertise({ page: 1, limit: 4 }));
  }, [dispatch]);

  useEffect(() => {
    if (advertiseResponse !== null) {
      setOfferFirst(advertiseResponse.data[0]);
      setOfferSecond(advertiseResponse.data[1]);
      setOfferThird(advertiseResponse.data[2]);
      setOfferFourth(advertiseResponse.data[3]);
    }
  }, [advertiseResponse, offerFirst]);

  return (
    <>
      <section className="offer-section">
        <Row className="gy-4">
          <Col lg="12" xl="6">
            {offerFirst !== "" && (
              <div className="offer-section-box box-1">
                <span>{offerFirst.title}</span>
                <p>{offerFirst.short_desc}</p>
                <h2>{offerFirst.description}</h2>
                <a
                  href={offerFirst.button_link}
                  className="cutsom-bttn"
                  title={offerFirst.title}
                >
                  {offerFirst.button_title}{" "}
                  <img src={VitaminsSupplimentsArrow} alt="" />
                </a>
                <a
                  href={offerFirst.button_link}
                  title={offerFirst.title}
                  className="product-img"
                >
                  <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${offerFirst.image}`} alt="" />
                </a>
              </div>
            )}
          </Col>
          <Col lg="12" xl="6">
            <Row>
              <Col md="12" lg="6">
                {offerSecond !== "" && (
                  <a
                    className="offer-section-box offer-box box-2"
                    href={offerSecond.button_link}
                  >
                    <span>{offerSecond.title}</span>
                    <h2>{offerSecond.short_desc}</h2>
                    <p>{offerSecond.description}</p>
                  </a>
                )}
              </Col>
              <Col md="12" lg="6">
                {offerThird !== "" && (
                  <a
                    className="offer-section-box offer-box box-3"
                    href={offerThird.button_link}
                  >
                    <span>{offerThird.title}</span>
                    <h2>{offerThird.short_desc}</h2>
                    <p>{offerThird.description}</p>
                  </a>
                )}
              </Col>
              <Col md="12">
                {offerFourth !== "" && (
                  <div className="offer-section-box offer-twocol-box box-4">
                    <div>
                      <span>{offerFourth.title}</span>
                      <p>{offerFourth.short_desc}</p>
                      <h2>{offerFourth.description}</h2>

                      <a
                        href={offerFourth.button_link}
                        className="cutsom-bttn"
                        title={offerFourth.title}
                      >
                        {offerFourth.button_title}{" "}
                        <img src={VitaminsSupplimentsArrow} alt="" />
                      </a>
                    </div>
                    <a
                      href={offerFourth.button_link}
                      title={offerFourth.title}
                      className="product-img"
                    >
                      <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${offerFourth.image}`} alt="" />
                    </a>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </>
  );
}
export default OfferSection;
