// src/features/user/userReducer.js
import { createReducer } from '@reduxjs/toolkit';
import {
    clearMessages,
    getProfileStart, getProfileSuccess, getProfileFailure,
    updateProfileStart, updateProfileSuccess, updateProfileFailure,
    changePasswordStart, changePasswordSuccess, changePasswordFailure,
    changeEmailStart, changeEmailSuccess, changeEmailFailure,
    getAddressesStart, getAddressesSuccess, getAddressesFailure,
    addAddressesStart, addAddressesSuccess, addAddressesFailure,
    updateAddressesStart, updateAddressesSuccess, updateAddressesFailure,
    deleteAddressesStart, deleteAddressesSuccess, deleteAddressesFailure,
} from './userActions';

const initialState = {
    profile: null,
    addresses: [],
    paymentMethods: [],
    loading: false,
    successMessage: null,
    errorMessage: null,
};

const userReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(clearMessages, (state) => {
            state.successMessage = null;
            state.errorMessage = null;
        })

        .addCase(getProfileStart, (state) => {
            state.loading = false;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getProfileSuccess, (state, action) => {
            state.profile = action.payload.data;
            state.loading = false;
            state.successMessage = null;
        })
        .addCase(getProfileFailure, (state, action) => {
            state.profile = null;
            state.errorMessage = action.payload.message;
            state.loading = false;
        })
    
        .addCase(updateProfileStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(updateProfileSuccess, (state, action) => {
            state.profile = action.payload.data;
            state.loading = false;
            state.successMessage = action.payload.message;
        })
        .addCase(updateProfileFailure, (state, action) => {
            state.successMessage = null;
            state.errorMessage = action.payload.data;
            state.loading = false;
        })

        .addCase(changePasswordStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(changePasswordSuccess, (state, action) => {
            state.loading = false;
            state.successMessage = action.payload.message;
            state.errorMessage = null;
        })
        .addCase(changePasswordFailure, (state, action) => {
            state.errorMessage = action.payload.message;
            state.successMessage = null;
            state.loading = false;
        })

        .addCase(changeEmailStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(changeEmailSuccess, (state, action) => {
            state.loading = false;
            state.profile = action.payload.data;
            state.successMessage = action.payload.message;
            state.errorMessage = null;
        })
        .addCase(changeEmailFailure, (state, action) => {
            state.errorMessage = action.payload.message;
            state.successMessage = null;
            state.loading = false;
        })





        .addCase(getAddressesStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getAddressesSuccess, (state, action) => {
            state.addresses = action.payload.data;
            state.loading = false;
            state.successMessage = null;
        })
        .addCase(getAddressesFailure, (state, action) => {
            state.errorMessage = action.payload.message;
            state.loading = false;
        })

        .addCase(addAddressesStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(addAddressesSuccess, (state, action) => {
            state.addresses = action.payload.data;
            state.loading = false;
            state.successMessage = action.payload.message;
        })
        .addCase(addAddressesFailure, (state, action) => {
            state.errorMessage = action.payload.message;
            state.loading = false;
        })

        .addCase(updateAddressesStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(updateAddressesSuccess, (state, action) => {
            state.addresses = action.payload.data;
            state.loading = false;
            state.successMessage = action.payload.message;
        })
        .addCase(updateAddressesFailure, (state, action) => {
            state.errorMessage = action.payload.message;
            state.loading = false;
        })

        .addCase(deleteAddressesStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(deleteAddressesSuccess, (state, action) => {
            state.loading = false;
            state.successMessage = action.payload.message;
        })
        .addCase(deleteAddressesFailure, (state, action) => {
            state.errorMessage = action.payload.message;
            state.loading = false;
        })
});

export default userReducer;
