import React, { useState, useEffect, useRef } from "react";
import { Modal, Row, Col, Button, Form } from "react-bootstrap";
// import UserImg from "../assets/images/profile/user-img.png";
import Camera from "../assets/images/profile/camera.svg";
import MailIcon from "../assets/images/profile/mail.svg";
import PasswordIcon from "../assets/images/profile/password.svg";
import { Link } from "react-router-dom";
import EditIcon from "../assets/images/profile/edit.svg";

import { useDispatch, useSelector } from "react-redux";
import { changePassword, changeEmail, updateProfile } from "../features/user/userActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify"; // Import toast
import { formatPhoneNumber } from '../helpers/helpers';

function PersonalInfo(props) {
  const [ChangeEmail, setChangeEmail] = useState(false);
  const ChangeEmailClose = () => setChangeEmail(false);
  const ChangeEmailShow = () => setChangeEmail(true);

  const [UpdatePassword, setUpdatePassword] = useState(false);
  const UpdatePasswordClose = () => setUpdatePassword(false);
  const UpdatePasswordShow = () => setUpdatePassword(true);

  const [EditProfile, setEditProfile] = useState(false);
  const EditProfileClose = () => setEditProfile(false);
  const EditProfileShow = () => setEditProfile(true);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState(props.profile?.name);
  const [phone, setPhone] = useState(props.profile?.mobile_number);
  const [avatar, setAvatar] = useState('/users/default.png');

  const [profileImage, setProfileImage] = useState(null);

  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);


  const dispatch = useDispatch();
  const { loading, successMessage } = useSelector(
    (state) => state.user
  );

  const handleClickFile = () => {
    fileInputRef.current.click(); // Trigger file input click
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileImage(reader.result); // Store base64 string in state

        // Dismiss any currently visible toasts
        toast.dismiss();

        // Dispatch update profile action
        let data = {
          name: fullName,
          email: props.profile?.email,
          mobile_number: phone,
          image: file,
        };
        dispatch(updateProfile(data));
      };
      reader.readAsDataURL(file); // Read file as base64
    }
  };

  const validateChangePassword = () => {
    const errors = {};

    if (!password) errors.password = "Password is required.";
    if (password !== confirmPassword)
      errors.confirmPassword = "Passwords must match.";
 
    // Check password length
    if (password && password.length < 8)
    errors.password = "Password must be at least 8 characters long.";

    return errors;
  };

  const handleChangePasswordSubmit = (e) => {
    e.preventDefault();

    // Initial blank errors
    setErrors({});

    // Validate form inputs
    const validationErrors = validateChangePassword();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Dismiss any currently visible toasts
    toast.dismiss();

    // Dispatch change password action
    let data = {
      password: password,
      confirm_password: password,
    };

    dispatch(changePassword(data));
  };


  const validateChangeEmail = () => {
    const errors = {};

    // Check for empty fields
    if (!email) errors.email = "Email is required.";
  
    // Check for valid email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailRegex.test(email))
      errors.email = "Invalid email format.";

    return errors;
  };

  const handleChangeEmailSubmit = (e) => {
    e.preventDefault();

    // Initial blank errors
    setErrors({});

    // Validate form inputs
    const validationErrors = validateChangeEmail();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Dismiss any currently visible toasts
    toast.dismiss();

    // Dispatch change email action
    let data = {
      email: email,
    };

    dispatch(changeEmail(data));
  };


  const handlePhoneChange = (e) => {
    const { value } = e.target;
    setPhone(formatPhoneNumber(value));
  };


  const validateUpdateProfile = () => {
    const errors = {};

    // Check for empty fields
    if (!fullName) errors.fullName = "Full Name is required.";
    if (!phone) errors.phone = "Phone Number is required.";
   
    if (phone && phone.length !== 12)
    errors.phone = "Please enter valid phone number.";

    return errors;
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();

    // Initial blank errors
    setErrors({});

    // Validate form inputs
    const validationErrors = validateUpdateProfile();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Dismiss any currently visible toasts
    toast.dismiss();

    // Dispatch update profile action
    let data = {
      name: fullName,
      email: props.profile?.email,
      mobile_number: phone,
      image:''
    };

    dispatch(updateProfile(data));
  };

  // Show success and error toasts
  useEffect(() => {
    if (successMessage) {
      setPassword("");
      setConfirmPassword("");
      setEmail("");
      setErrors({});
      UpdatePasswordClose();
      ChangeEmailClose();
      EditProfileClose();
    }
  }, [successMessage]);

  useEffect(() => {
    if (props?.profile) {
      setFullName(props.profile.name);
      setPhone(formatPhoneNumber(props.profile.mobile_number));
      setAvatar(props.profile.avatar)
    }
  }, [props?.profile]);

  return (
    <>
      <div className="tab-content-header">
        <h3>Personal Information</h3>
        <Link to="" onClick={EditProfileShow} className="cutsom-bttn">
          <img src={EditIcon} alt="" /> Edit Profile
        </Link>
      </div>
      <div className="personal-info">
        <div className="personal-info-img">
          {profileImage ? (
            <img src={profileImage} alt="Profile" className="user-img" />
          ):(
            <>
              <img className="user-img" src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${avatar}`} alt="" />
            </>
          )}

          <Link to="#" className="img-change" onClick={handleClickFile}>
            {loading ? (
              <FontAwesomeIcon
                icon={faSpinner}
                spin
                size="lg"
                className="spinner-icon"
              />
            ) : (
              <img src={Camera} alt="" />
            )}
          </Link>
          
          {/* Hidden file input */}
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: 'none' }}
            accept="image/*"
          />
          
        </div>
        <div className="personal-info-box">
          <div className="info-box">
            <p>Full Name</p>
            <h3>{props.profile?.name}</h3>
          </div>
          <div className="info-box">
            <p>Email Address</p>
            <h3>{props.profile?.email}</h3>
          </div>
          <div className="info-box">
            <p>Phone Number</p>
            <h3>{formatPhoneNumber(props.profile?.mobile_number)}</h3>
          </div>
          <div className="button-group">
            <Link
              to=""
              onClick={ChangeEmailShow}
              className="cutsom-bttn mail-bttn"
            >
              <img src={MailIcon} alt="" /> Change Email
            </Link>
            <Link to="" onClick={UpdatePasswordShow} className="cutsom-bttn">
              <img src={PasswordIcon} alt="" /> Change Password
            </Link>
          </div>
        </div>
      </div>

      {/* Change Email Address */}
      <Modal
        show={ChangeEmail}
        onHide={ChangeEmailClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Change Email Address
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleChangeEmailSubmit}>
            <Row>
              <Col md="12">
                <Form.Group className="mb-4">
                  <Form.Label>Confirm Email Address</Form.Label>
                  <p>
                    Please enter your registered email address for user
                    verification.
                  </p>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Email Address <span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email Address"
                    value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      isInvalid={!!errors.email}
                  />
                   <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="12">
                <div className="bttn-group justify-content-center">
                  <Link
                    onClick={ChangeEmailClose}
                    className="cutsom-bttn cancel-bttn"
                    type="button"
                  >
                    Cancel
                  </Link>
                  <Button
                    className="cutsom-bttn"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        size="lg"
                        className="spinner-icon"
                      />
                    ) : (
                      "Send Verification Link"
                    )}
                  </Button>

                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Update Password */}
      <Modal
        show={UpdatePassword}
        onHide={UpdatePasswordClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Password
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleChangePasswordSubmit}>
            <Row>
              <Col md="12">
                <Form.Group className="mb-4">
                  <Form.Label>
                    New Password <span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter New Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    isInvalid={!!errors.password}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Confirm Password <span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Re-type New Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    isInvalid={!!errors.confirmPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md="12">
                <div className="bttn-group justify-content-center">
                  <Link
                    onClick={UpdatePasswordClose}
                    className="cutsom-bttn cancel-bttn"
                    type="button"
                  >
                    Cancel
                  </Link>
                  <Button
                    className="cutsom-bttn"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        size="lg"
                        className="spinner-icon"
                      />
                    ) : (
                      "Update Password"
                    )}
                  </Button>

                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Edit Profile */}
      <Modal
        show={EditProfile}
        onHide={EditProfileClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form onSubmit={handleUpdateProfile}>
            <Row>
              <Col md="12">
                <Form.Group className="mb-4">
                  <Form.Label>
                    Full Name <span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Full Name"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    isInvalid={!!errors.fullName}
                  />
                  <Form.Control.Feedback type="invalid">
                      {errors.fullName}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    Phone Number <span>*</span>
                  </Form.Label>
                  <Form.Control
                      type="tel"
                      placeholder="Enter Phone Number"
                      maxLength={12}
                      value={phone}
                      onChange={handlePhoneChange}
                      //onChange={(e) => setPhone(e.target.value)}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                </Form.Group>
                {/* 
                <Form.Group>
                  <Form.Label>
                    Email Address <span>*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter New Email Address"
                    required
                  />
                </Form.Group> */}
              </Col>
              <Col md="12">
                <div className="bttn-group justify-content-center">
                  <Link
                    onClick={EditProfileClose}
                    className="cutsom-bttn cancel-bttn"
                    type="button"
                  >
                    Cancel
                  </Link>
                  <Button
                    className="cutsom-bttn"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        size="lg"
                        className="spinner-icon"
                      />
                    ) : (
                      "Update Profile"
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default PersonalInfo;
