// src/services/wishlistService.js
import axios from 'axios';
import { toFormData } from './utils'; // Utility function
import { setAuthToken } from './setAuthToken';

const wishlistService = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Assuming you have a token from somewhere (e.g., after login)
const token = localStorage.getItem('token') || sessionStorage.getItem('token');
setAuthToken(wishlistService, token);

export const getWishlists = (data) => {
    const formData = toFormData(data);
    return wishlistService.post('/wishlists', formData);
};

export const addRemoveProductFromWishlist = (data) => {
    const formData = toFormData(data);
    return wishlistService.post('/wishlists/create-remove', formData);
};


export default wishlistService;
