// src/features/blog/blogActions.js
import { createAction } from '@reduxjs/toolkit';
import { getBlogs, getBlogDetails, getBlogCategory } from '../../services/blogService';

// Action Creators
export const clearMessages = createAction('blog/clearMessages');

// Get Blog Categories
export const getBlogCategoryStart = createAction('blog/getBlogCategoryStart');
export const getBlogCategorySuccess = createAction('blog/getBlogCategorySuccess');
export const getBlogCategoryFailure = createAction('blog/getBlogCategoryFailure');

// Get Blogs
export const getBlogsStart = createAction('blog/getBlogsStart');
export const getBlogsSuccess = createAction('blog/getBlogsSuccess');
export const getBlogsFailure = createAction('blog/getBlogsFailure');

// Get Blog Details
export const getBlogDetailsStart = createAction('blog/getBlogDetailsStart');
export const getBlogDetailsSuccess = createAction('blog/getBlogDetailsSuccess');
export const getBlogDetailsFailure = createAction('blog/getBlogDetailsFailure');

// Thunks
export const fetchBlogCategory = () => async (dispatch) => {
    dispatch(getBlogCategoryStart());
    try {
        const response = await getBlogCategory();
        if(response.data.status === false){
            dispatch(getBlogCategoryFailure(response.data));
        }else{
            dispatch(getBlogCategorySuccess(response.data));
        }
    } catch (error) {
        dispatch(getBlogCategoryFailure(error.message));
    }
};

export const fetchBlogs = (blogData) => async (dispatch) => {
    dispatch(getBlogsStart());
    try {
        const response = await getBlogs(blogData);
        if(response.data.status === false){
            dispatch(getBlogsFailure(response.data));
        }else{
            dispatch(getBlogsSuccess(response.data));
        }
    } catch (error) {
        dispatch(getBlogsFailure(error.message));
    }
};

export const fetchBlogDetails = (slug) => async (dispatch) => {
    dispatch(getBlogDetailsStart());
    try {
        const response = await getBlogDetails(slug);
        if(response.data.status === false){
            dispatch(getBlogDetailsFailure(response.data));
        }else{
            dispatch(getBlogDetailsSuccess(response.data));
        }
    } catch (error) {
        dispatch(getBlogDetailsFailure(error.message));
    }
};
