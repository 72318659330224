import React from 'react';
import { Table } from 'react-bootstrap';

import { Link, useNavigate } from 'react-router-dom';
import { formatPrice } from '../helpers/helpers';

import { storeCheckoutInfo } from '../features/checkout/checkoutActions';
import { useDispatch, useSelector } from 'react-redux';



const CartSummary = ({ total, discountTotal, checkedItems, countCheckedItems, taxRate, currency, promoCode, isCouponApplied}) => {
  
  let orderTotal = 0;
  let taxAmount = 0;
  let couponDiscountAmount = 0;
  let couponTitle = "";
  let totalItemAmount = parseFloat(total);

  if(isCouponApplied){
    if(promoCode.discount_type === 1){
      // Percentage  
      couponDiscountAmount = formatPrice((totalItemAmount * parseFloat(promoCode.discount_type_value)) / 100);
      couponTitle = "PromoCode ("+promoCode.code+")";
    }else{
      // Fixed
      couponDiscountAmount = parseFloat(promoCode.discount_type_value);
      couponTitle = "PromoCode ("+promoCode.code+")";
    }
  }

  
  if(couponDiscountAmount > 0){
    if(couponDiscountAmount < totalItemAmount){
      orderTotal = formatPrice(parseFloat(totalItemAmount) - parseFloat(couponDiscountAmount));
    }
  }else{
    orderTotal = totalItemAmount;
  }
  
  if(orderTotal > 0){
    if(taxRate !== null){
      taxAmount = formatPrice((orderTotal * parseFloat(taxRate.percentage)) / 100)
    }
  }

  orderTotal = formatPrice(parseFloat(orderTotal) + parseFloat(taxAmount));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { cart } = useSelector((state) => state.cart);

  const handleRedirectCheckout = () => {
    
      let cartItems = [];
      for (const key in checkedItems) {
          if (checkedItems.hasOwnProperty(key) && checkedItems[key]) {
              const [product_id, variation_id] = key.split('_').map(Number);
              const item = cart.find(item => (item.product_id === product_id && item.variation_id === variation_id));
              if (item) {
                cartItems.push(item);
              }
          }
      }
    
      let checkoutOrder = {
        cart_items : cartItems,
        currency : currency,
        total_item_amount : totalItemAmount,
        discount : discountTotal,
        promocode_discount_amount : couponDiscountAmount,
        promocode : promoCode,
        display_promocode_title : couponTitle,
        tax_rate : taxRate,
        tax_amount : taxAmount,
        order_total : orderTotal
      }
      dispatch(storeCheckoutInfo(checkoutOrder));
      navigate("/cart/checkout");
  }

  if(countCheckedItems > 0){
    return (
      <div className="summary-box">
          <div className='box-header'>
            <h3>Summary</h3>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
          </div>
          <h4>Order Summary</h4>
        <Table>
          <tbody>
            <tr>
              <td>Items Total({countCheckedItems})</td>
              <td>{currency}{formatPrice(totalItemAmount)}</td>
            </tr>

            <tr>
              <td>Discounts</td>
              <th className='discounts'>-{currency}{formatPrice(discountTotal)}</th>
            </tr>

            {couponDiscountAmount > 0 && (
            <tr>
              <td>{couponTitle}</td>
              <th>-{currency}{couponDiscountAmount}</th>
            </tr>
            )}


            {taxAmount > 0 && (
            <tr>
              <td>Tax</td>
              <td className='discounts'>+{currency}{taxAmount}</td>
            </tr>
            )}

            
            
            {/* <tr>
              <td>Shipping
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <img className='tooltip-icon' src={TooltipIcon} alt="" />
              </OverlayTrigger>
              </td>
              <td>${shipping.toFixed(2)}</td>
            </tr> */}
            
           

            <tr>
              <th>Order Total</th>
              <th>{currency}{formatPrice(orderTotal)}</th>
            </tr>
          </tbody>
        </Table>
        {(countCheckedItems) > 0 && (
          <Link to="" onClick={(event) => { event.preventDefault(); handleRedirectCheckout(); }} className='cutsom-bttn w-100'>
              Proceed To Checkout ({countCheckedItems})
          </Link>
        )}
  
      </div>
    );
  }
};


export default CartSummary;
