import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
// import BestSeller1 from '../assets/images/b-seller-img-1.png'
// import BestSeller2 from '../assets/images/b-seller-img-2.png'
// import BestSeller3 from '../assets/images/b-seller-img-3.png'
// import BestSeller4 from '../assets/images/b-seller-img-4.png'
// import BestSeller5 from '../assets/images/b-seller-img-5.png'
import whiteArrow from '../assets/images/view-all-arrow.svg'
import { Navigation} from 'swiper/modules'
import SwiperCore from 'swiper'
import { formatPrice } from '../helpers/helpers';

SwiperCore.use([Navigation]);

function MedicineHealthProducts({categoryOfferProductSection}) {
  
  // const swiperRef = useRef(null);
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  if(!categoryOfferProductSection){
    return null;
  }

  return (
    <>
     <section className='product-slider medicine-health-products'>
        <div className="product-slider-header">
           <div className='product-slider-header-left'> 
              <h2>{categoryOfferProductSection?.title}</h2>
              <p>{categoryOfferProductSection?.description}</p>
           </div>
           <a href="/products" className='cutsom-bttn'>View All Products <img src={whiteArrow} alt="" /></a>
        </div>
        <Swiper
        modules={[Navigation]}
        slidesPerView={5}
        spaceBetween={30}
        pagination={{ clickable: true }}
        crollbar={{ draggable: true }}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        onBeforeInit={(swiper) => {
          swiper.params.navigation.prevEl = navigationPrevRef.current;
          swiper.params.navigation.nextEl = navigationNextRef.current;
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1700: {
            slidesPerView: 5,
            spaceBetween: 30,
          },
        }}
      >
        
        {categoryOfferProductSection?.data?.length > 0 && (
         
          <>
          {categoryOfferProductSection.data.map((product, index) => (
            <SwiperSlide key={"product-" + index}>
             <a href={"/products/"+ product.slug} className="product-slider-box">
               <div className="product-slider-box-img">
                 <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${product.image}`} alt="" />
                 {product.is_new && (
                    <span>New</span>
                  )}
               </div>
               <div className="product-slider-box-text">
                 <p>{product.category_title}</p>
                 <h3>{product.title}</h3> 
                 <div className="price-box">
                   <span>Price</span>
                   <div className='price-value'>
                    <p className="new-price">{product.currency+formatPrice(product.sale_price)}</p>
                    <del className="old-price">{product.currency+formatPrice(product.regular_price)}</del>
                   </div>
                 </div>           
               </div>              
             </a>
           </SwiperSlide>
          ))}
          </>
          
        )}
        
       


        <div className='swiper-button-nav'>
        <div ref={navigationPrevRef} className="swiper-button-prev"></div>
        <div ref={navigationNextRef} className="swiper-button-next"></div>
        </div>
      </Swiper>     
     </section>     
    </>
  )
}
export default MedicineHealthProducts