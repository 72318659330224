import React from 'react'
import SubBanner from '../component/SubBanner'  
import ShoppingCart from '../component/ShoppingCart' 
// import MedicineHealthProducts from '../component/MedicineHealthProducts' 
// import OfferSection from '../component/OfferSection' 
// import PowerNature from '../component/PowerNature' 
// import OurBlogArticle from '../component/OurBlogArticle' 
import ATC from '../component/ATC' 
import Header from '../component/Header'
import { Helmet } from 'react-helmet-async';

function Cart() {
  return (
    <>
      <Helmet>
        <title>Your Cart | HealthGonia</title>
        <meta name="description" content="View and manage the items in your cart. Proceed to checkout to complete your purchase at HealthGonia." />
        <meta name="keywords" content="Cart, Shopping Cart, Checkout, HealthGonia, Purchase, Online Shopping" />
      </Helmet>

      <div className='main cart-page'>
      <Header /> 
        <SubBanner HeaderTitle="Cart" HeaderSubtitle=""/> 
        <ShoppingCart />       
        {/* 
        <MedicineHealthProducts />
        <OfferSection /> 
        <OurBlogArticle blogTitle="Our Blog & Article" />
        */}
        <ATC />
      </div>
    </>
  )
}
export default Cart


