// src/services/blogService.js
import axios from 'axios';
import { toFormData } from './utils'; // Utility function

const blogService = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});


export const getBlogCategory = () => {
    return blogService.get('/blog-categories');
};

export const getBlogs = (blogData) => {
    const formData = toFormData(blogData);
    return blogService.post('/blogs', formData);
};

export const getBlogDetails = (slug) => {
    return blogService.get('/blogs/'+slug);
};

export default blogService;
