import React from 'react'
import Header from '../component/Header'
import SubBanner from '../component/SubBanner'
import FAQList from '../component/FAQList' 
import { Helmet } from 'react-helmet-async';

function FAQ() {
  return (
    <>
      <Helmet>
        <title>Frequently Asked Questions (FAQ) | HealthGonia</title>
        <meta name="description" content="Find answers to common questions about HealthGonia's services, products, policies, and more." />
        <meta name="keywords" content="FAQ, Frequently Asked Questions, Support, Help, HealthGonia, Questions" />
      </Helmet>
      
      <div className='main faq-page'>
        <Header/>
        <SubBanner HeaderTitle="FAQ’s" HeaderSubtitle=""/>
        <FAQList />     
      </div>
    </>
  )
}
export default FAQ


