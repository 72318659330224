// src/features/geoLocation/geoLocationActions.js
import { createAction } from '@reduxjs/toolkit';
import { getCountries, getStates, getCities } from '../../services/geoLocationService';

// Action Creators
export const clearMessages = createAction('geo/clearMessages');

// Get Countries
export const getCountriesStart = createAction('geo/getCountriesStart');
export const getCountriesSuccess = createAction('geo/getCountriesSuccess');
export const getCountriesFailure = createAction('geo/getCountriesFailure');

// Get States
export const getStatesStart = createAction('geo/getStatesStart');
export const getStatesSuccess = createAction('geo/getStatesSuccess');
export const getStatesFailure = createAction('geo/getStatesFailure');

// Get Cities
export const getCitiesStart = createAction('geo/getCitiesStart');
export const getCitiesSuccess = createAction('geo/getCitiesSuccess');
export const getCitiesFailure = createAction('geo/getCitiesFailure');

// Thunks
export const fetchCountries = () => async (dispatch) => {
    dispatch(getCountriesStart());
    try {
        const response = await getCountries();
        if(response.data.status === false){
            dispatch(getCountriesFailure(response.data));
        }else{
            dispatch(getCountriesSuccess(response.data));
        }
    } catch (error) {
        dispatch(getCountriesFailure(error.message));
    }
};

export const fetchStates = (countryId) => async (dispatch) => {
    dispatch(getStatesStart());
    try {
        const response = await getStates(countryId);
        if(response.data.status === false){
            dispatch(getStatesFailure(response.data));
        }else{
            dispatch(getStatesSuccess(response.data));
        }
    } catch (error) {
        dispatch(getStatesFailure(error.message));
    }
};

export const fetchCities = (stateId) => async (dispatch) => {
    dispatch(getCitiesStart());
    try {
        const response = await getCities(stateId);
        if(response.data.status === false){
            dispatch(getCitiesFailure(response.data));
        }else{
            dispatch(getCitiesSuccess(response.data));
        }
    } catch (error) {
        dispatch(getCitiesFailure(error.message));
    }
};
