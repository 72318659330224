import { createReducer } from '@reduxjs/toolkit';
import {
    clearOrderMessages,
    createOrderStart,
    createOrderSuccess,
    createOrderFailure,
    fetchOrderListStart,
    fetchOrderListSuccess,
    fetchOrderListFailure
} from './orderActions';

const initialState = {
    loading: false,
    orderList: [],
    errorMessage: null,
    successMessage: null,
};

const orderReducer = createReducer(initialState, (builder) => {
    builder
        // Clear messages
        .addCase(clearOrderMessages, (state) => {
            state.successMessage = null;
            state.errorMessage = null;
        })

        // Create Order
        .addCase(createOrderStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(createOrderSuccess, (state, action) => {
            state.loading = false;
            state.orderDetails = action.payload.data;
            state.successMessage = action.payload.message;
        })
        .addCase(createOrderFailure, (state, action) => {
            state.loading = false;
            state.orderDetails = null
            state.errorMessage = action.payload.message;
        })

        // Fetch Order List
        .addCase(fetchOrderListStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(fetchOrderListSuccess, (state, action) => {
            state.loading = false;
            state.orderList = action.payload.data;
        })
        .addCase(fetchOrderListFailure, (state, action) => {
            state.loading = false;
            state.orderList = [];
            state.errorMessage = action.payload.message;
        });
});

export default orderReducer;
