// src/features/auth/authActions.js
import { createAction } from '@reduxjs/toolkit';
import { 
    login as loginAPI, 
    register as registerAPI, 
    forgotPassword as forgotPasswordAPI,  
    verifyEmail as verifyEmailAPI,  
    resetPassword as resetPasswordAPI } from '../../services/authService';

// Action Creators
export const clearMessages = createAction('auth/clearMessages');

export const loginStart = createAction('auth/loginStart');
export const loginSuccess = createAction('auth/loginSuccess');
export const loginFailure = createAction('auth/loginFailure');

export const registerStart = createAction('auth/registerStart');
export const registerSuccess = createAction('auth/registerSuccess');
export const registerFailure = createAction('auth/registerFailure');

export const forgotPasswordStart = createAction('auth/forgotPasswordStart');
export const forgotPasswordSuccess = createAction('auth/forgotPasswordSuccess');
export const forgotPasswordFailure = createAction('auth/forgotPasswordFailure');

export const verifyEmailStart = createAction('auth/verifyEmailStart');
export const verifyEmailSuccess = createAction('auth/verifyEmailSuccess');
export const verifyEmailFailure = createAction('auth/verifyEmailFailure');

export const resetPasswordStart = createAction('auth/resetPasswordStart');
export const resetPasswordSuccess = createAction('auth/resetPasswordSuccess');
export const resetPasswordFailure = createAction('auth/resetPasswordFailure');

export const logout = createAction('auth/logout');


export const login = (credentials) => async (dispatch) => {
    dispatch(loginStart());
    try {
        const response = await loginAPI(credentials);
        if(response.data.status === false){
            dispatch(loginFailure(response.data));
        }else{
            localStorage.setItem('token', response.data.data.auth_token); 
            if(credentials.keep_me_login === true){
                sessionStorage.setItem('token', response.data.data.auth_token);
            }

            dispatch(loginSuccess(response.data));
        }
    } catch (error) {
        dispatch(loginFailure(error.message));
    }
};

export const register = (userData) => async (dispatch) => {
    dispatch(registerStart());
    try {
        const response = await registerAPI(userData);
        if(response.data.status === false){
            dispatch(registerFailure(response.data));
        }else{
            dispatch(registerSuccess(response.data));
        }
    } catch (error) {
        dispatch(registerFailure(error.message));
    }
};

export const forgotPassword = (data) => async (dispatch) => {
    dispatch(forgotPasswordStart());
    try {
        const response = await forgotPasswordAPI(data);
        if(response.data.status === false){
            dispatch(forgotPasswordFailure(response.data));
        }else{
            dispatch(forgotPasswordSuccess(response.data));
        }
    } catch (error) {
        dispatch(forgotPasswordFailure(error.message));
    }
};

export const verifyEmail = (token) => async (dispatch) => {
    dispatch(verifyEmailStart());
    try {
        const response = await verifyEmailAPI(token);
        if(response.data.status === false){
            dispatch(verifyEmailFailure(response.data));
        }else{
            dispatch(verifyEmailSuccess(response.data));
        }
    } catch (error) {
        dispatch(verifyEmailFailure(error.message));
    }
};


export const resetPassword = (data) => async (dispatch) => {
    dispatch(resetPasswordStart());
    try {
        const response = await resetPasswordAPI(data);
        if(response.data.status === false){
            dispatch(resetPasswordFailure(response.data));
        }else{
            dispatch(resetPasswordSuccess(response.data));
        }
    } catch (error) {
        dispatch(resetPasswordFailure(error.message));
    }
};
