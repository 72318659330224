// src/services/cartService.js
import axios from 'axios';
import { toFormData } from './utils'; // Utility function
import { setAuthToken } from './setAuthToken';

const cartService = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Assuming you have a token from somewhere (e.g., after login)
const token = localStorage.getItem('token') || sessionStorage.getItem('token');
setAuthToken(cartService, token);

// Get Cart from Local Storage
export const getCartService = () => {
    const cart = localStorage.getItem('cart');
    return cart ? JSON.parse(cart) : [];
};

// Save Cart to Local Storage
const saveCartService = (cart) => {
    localStorage.setItem('cart', JSON.stringify(cart));
};

// Add Item to Cart
export const addToCartService = (product) => {
    let cart = getCartService();

    const productIndex = cart.findIndex(item => item.product_id === product.product_id && item.variation_id === product.variation_id);

    if (productIndex !== -1) {
        // Update quantity and sub_total
        cart[productIndex].qty += product.qty;
        cart[productIndex].sub_total = cart[productIndex].price * cart[productIndex].qty;
    } else {
        // Calculate sub_total for new product
        product.sub_total = product.price * product.qty;
        cart.push(product);
    }

    saveCartService(cart);
};

// Remove Item from Cart
export const removeFromCartService = (product_id, variation_id) => {
    let cart = getCartService();
    cart = cart.filter(item => !(item.product_id === product_id && item.variation_id === variation_id));
    saveCartService(cart);
};

// Update Product Quantity in Cart
export const updateCartQtyService = (product_id, variation_id, qty) => {
    let cart = getCartService();
    const productIndex = cart.findIndex(item => item.product_id === product_id && item.variation_id === variation_id);

    if (productIndex !== -1) {
        cart[productIndex].qty = qty;
        cart[productIndex].sub_total = cart[productIndex].price * qty; // Update sub_total
        saveCartService(cart);
    }
};

// Move cart to checkout by flag
export const moveCartToCheckoutByflag = (product_id, variation_id, flag) => {
    let cart = getCartService();

    if (product_id === null && variation_id === null) {
        // Bulk update logic: toggle is_selected_to_checkout for all items
        cart.forEach(item => {
            item.is_selected_to_checkout = flag;
        });
    } else {
        // Single item update logic
        const productIndex = cart.findIndex(item => item.product_id === product_id && item.variation_id === variation_id);

        if (productIndex !== -1) {
            if (flag) {
                cart[productIndex].is_selected_to_checkout = true;
            } else {
                cart[productIndex].is_selected_to_checkout = !cart[productIndex].is_selected_to_checkout;
            }
        }
    }

    // Save the updated cart
    saveCartService(cart);
};

// Clear Cart
export const clearCartService = () => {
    localStorage.removeItem('cart');
};

// Sync Cart to Database API
export const syncCartToDatabase = (cart) => {
    const formData = toFormData(cart);
    return cartService.post('/cart/sync', formData);
};

// Get Tax Rate
export const getTaxRate = () => {
    return cartService.get('/tax-rate');
};

// Get Promocode by code
export const getPromocode = (data) => {
    const formData = toFormData(data);
    return cartService.post('/promo-code', formData);
};