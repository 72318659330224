const ReactSelectStyles = {
    control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? 'none' : provided.boxShadow,
    borderColor: '#fff',
    '&:hover': {
        borderColor: '#fff',
    },
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isFocused
          ? '#f8f9fa'
          : state.isSelected
          ? '#00A991'
          : provided.backgroundColor,
        color: state.isSelected ? '#000000' : provided.color,
        '&:active': {
          backgroundColor: '#00A991', // Change active background color here
          color: '#fff', // Change active text color here
        },
    }),
};

export default ReactSelectStyles;