// src/features/cart/cartReducer.js
import { createReducer } from '@reduxjs/toolkit';
import {
    clearMessages,
    addToCartSuccess, removeFromCartSuccess, updateCartQtySuccess, getCartSuccess,
    taxRateStart, taxRateSuccess, taxRateFailure,
    getPromocodeStart, getPromocodeSuccess, getPromocodeFailure,
} from './cartActions';

const initialState = {
    loading: false,
    cart: [],
    taxRate: null,
    promoCode: null,
    error: null,
    successMessage: null,
    errorMessage: null,
};

const cartReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(clearMessages, (state) => {
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(addToCartSuccess, (state, action) => {
            const existingProductIndex = state.cart.findIndex(item =>
                item.product_id === action.payload.product_id && item.variation_id === action.payload.variation_id
            );
            if (existingProductIndex !== -1) {
                state.cart[existingProductIndex].qty += action.payload.qty;
            } else {
                state.cart.push(action.payload);
            }
            state.successMessage = "Product added to cart successfully!";
        })
        
        .addCase(removeFromCartSuccess, (state, action) => {
            state.cart = state.cart.filter(item =>
                !(item.product_id === action.payload.product_id && item.variation_id === action.payload.variation_id)
            );
            state.successMessage = "Product removed from cart successfully!";
        })
        .addCase(updateCartQtySuccess, (state, action) => {
            const productIndex = state.cart.findIndex(item =>
                item.product_id === action.payload.product_id && item.variation_id === action.payload.variation_id
            );
            if (productIndex !== -1) {
                state.cart[productIndex].qty = action.payload.qty;
            }
            state.successMessage = "Product quantity updated successfully!";
        })
        .addCase(getCartSuccess, (state, action) => {
            state.cart = action.payload;
        })


        .addCase(taxRateStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(taxRateSuccess, (state, action) => {
            state.loading = false;
            state.taxRate = action.payload.data;
        })
        .addCase(taxRateFailure, (state, action) => {
            state.loading = false;
            state.taxRate = null;
            state.errorMessage = action.payload.message;
        })

        .addCase(getPromocodeStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getPromocodeSuccess, (state, action) => {
            state.loading = false;
            state.promoCode = action.payload.data;
            state.successMessage = action.payload.message;
        })
        .addCase(getPromocodeFailure, (state, action) => {
            state.loading = false;
            state.promoCode = null;
            state.errorMessage = action.payload.message;
        })


});

export default cartReducer;
