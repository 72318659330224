import React from 'react'
import Header from '../component/Header'
import SubBanner from '../component/SubBanner'
import CareerComp from '../component/CareerComp'
import { Helmet } from 'react-helmet-async';


function Career() {
  return (
    <>
    <Helmet>
        <title>Careers | HealthGonia</title>
        <meta name="description" content="Join our team at HealthGonia. Explore exciting career opportunities and be part of our mission to provide top-quality healthcare services." />
        <meta name="keywords" content="Careers, Jobs, Employment, Healthcare, HealthGonia, Work with us" />
      </Helmet>

    <div className='main career-page'>
      <Header/>
      <SubBanner HeaderTitle="Career" HeaderSubtitle=""/> 
      <CareerComp />  
    </div>
    </>
  )
}
export default Career


