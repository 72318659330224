// src/services/geoLocationService.js
import axios from 'axios';

const geoLocationService = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const getCountries = () => {
    return geoLocationService.get('/geo/countries');
};

export const getStates = (countryId) => {
    return geoLocationService.get('/geo/states?country_id='+countryId);
};

export const getCities = (stateId) => {
    return geoLocationService.get('/geo/cities?state_id='+stateId);
};

export default geoLocationService;
