import { createReducer } from '@reduxjs/toolkit';
import {
    clearCheckoutMessages,
    storeCheckoutInfoStart,
    storeCheckoutInfoSuccess,
    storeCheckoutInfoFailure,
    fetchLocalCheckoutStart,
    fetchLocalCheckoutSuccess,
    fetchLocalCheckoutFailure,
    clearLocalCheckoutStart,
    clearLocalCheckoutSuccess,
    clearLocalCheckoutFailure,
} from './checkoutActions';

const initialState = {
    loading: false,
    checkoutOrder: null,
    errorMessage: null,
    successMessage: null,
};

const checkoutReducer = createReducer(initialState, (builder) => {
    builder
        // Clear messages
        .addCase(clearCheckoutMessages, (state) => {
            state.successMessage = null;
            state.errorMessage = null;
        })

        // Store Order Info
        .addCase(storeCheckoutInfoStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(storeCheckoutInfoSuccess, (state, action) => {
            state.loading = false;
            state.checkoutOrder = action.payload;
            state.successMessage = "Order information saved successfully.";
        })
        .addCase(storeCheckoutInfoFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload; // Assuming payload contains the error message
        })

        // Fetch Local Order Info
        .addCase(fetchLocalCheckoutStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(fetchLocalCheckoutSuccess, (state, action) => {
            state.loading = false;
            state.checkoutOrder = action.payload;
            state.successMessage = "Order information fetched successfully.";
        })
        .addCase(fetchLocalCheckoutFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload; // Assuming payload contains the error message
        })

        // Clear Local Order Info
        .addCase(clearLocalCheckoutStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(clearLocalCheckoutSuccess, (state) => {
            state.loading = false;
            state.checkoutOrder = null;
            state.successMessage = "Order information cleared successfully.";
        })
        .addCase(clearLocalCheckoutFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload; // Assuming payload contains the error message
        });
});

export default checkoutReducer;
