import { createAction } from '@reduxjs/toolkit';
import {
    createOrderService,
    getOrderListService
} from '../../services/orderService';

// Action Creators
export const clearOrderMessages = createAction('order/clearMessages');

// Create Order
export const createOrderStart = createAction('order/createOrderStart');
export const createOrderSuccess = createAction('order/createOrderSuccess');
export const createOrderFailure = createAction('order/createOrderFailure');


// Fetch Local List
export const fetchOrderListStart = createAction('order/fetchOrderListStart');
export const fetchOrderListSuccess = createAction('order/fetchOrderListSuccess');
export const fetchOrderListFailure = createAction('order/fetchOrderListFailure');

// Thunks

export const createOrder = (orderData) => async (dispatch) => {
    dispatch(createOrderStart());
    try {
        // Simulate API call to create the order
        const response = await createOrderService(orderData); // replace with actual service/API call
        if(response.data.status === false){
            dispatch(createOrderFailure(response.data));
        }else{
            dispatch(createOrderSuccess(response.data)); // Dispatch success action with the response
        }
    } catch (error) {
        dispatch(createOrderFailure(error.message)); // Dispatch failure action with the error message
    }
};


export const fetchOrderList = (data) => async (dispatch) => {
    dispatch(fetchOrderListStart());
    try {
        // Simulate API call to get the list of orders
        const response = await getOrderListService(data); // replace with actual service/API call
        if(response.data.status === false){
            dispatch(fetchOrderListFailure(response.data)); // Dispatch success action with the order list
        }else{
            dispatch(fetchOrderListSuccess(response.data)); // Dispatch success action with the order list
        }
    } catch (error) {
        dispatch(fetchOrderListFailure(error.message)); // Dispatch failure action with error message
    }
};