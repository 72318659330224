import React from 'react'
import { Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import Banner2 from '../assets/images/banner-2.png'
import BannerProduct from '../assets/images/banner-product.svg'
import whiteArrow from '../assets/images/button-arrow.svg'
function Banner() {
  return (
    <section className='home-banner banner-option-one'>
      <Swiper
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{ clickable: true }}
        crollbar={{ draggable: true }}
      >
        <SwiperSlide>
          <div className='banner-content-main'>
            <div className="banner-content-left">
              <div className="banner-content-wrap">
                <span>
                  Over <Link to="/products">6,000 Products</Link> from our favorite brands
                </span>
                <h1>HEALTHY <b>CHOICE</b><br /> <b>BETTER</b> VALUE </h1>
                <p>
                  Meet our new gummies, made with plant-based sweeteners and
                  <br /> no sugar alcohols.
                </p>
                <div className="promo-code-wrap">
                  <div className="promo-code">
                    <p>Promocode</p>
                    <h3>20% OFF</h3>
                  </div>
                  <h2>BIGTIME</h2>
                </div>
                <Link to="/shop" className='custom-btn'>
                  Shop Now <img src={whiteArrow} alt="Arrow" />
                </Link>
              </div>
            </div>
            <div className="banner-content-right">
              <img src={Banner2} alt="Banner" />
              <img className='banner-product' src={BannerProduct} alt="Banner Product" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='banner-content-main'>
            <div className="banner-content-left">
              <div className="banner-content-wrap">
                <span>
                  Over <Link to="/products">6,000 Products</Link> from our favorite brands
                </span>
                <h1>HEALTHY <b>CHOICE</b><br /> <b>BETTER</b> VALUE </h1>
                <p>
                  Meet our new gummies, made with plant-based sweeteners and
                  <br /> no sugar alcohols.
                </p>
                <div className="promo-code-wrap">
                  <div className="promo-code">
                    <p>Promocode</p>
                    <h3>20% OFF</h3>
                  </div>
                  <h2>BIGTIME</h2>
                </div>
                <Link to="/shop" className='custom-btn'>
                  Shop Now <img src={whiteArrow} alt="Arrow" />
                </Link>
              </div>
            </div>
            <div className="banner-content-right">
              <img src={Banner2} alt="Banner" />
              <img className='banner-product' src={BannerProduct} alt="Banner Product" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='banner-content-main'>
            <div className="banner-content-left">
              <div className="banner-content-wrap">
                <span>
                  Over <Link to="/products">6,000 Products</Link> from our favorite brands
                </span>
                <h1>HEALTHY <b>CHOICE</b><br /> <b>BETTER</b> VALUE </h1>
                <p>
                  Meet our new gummies, made with plant-based sweeteners and
                  <br /> no sugar alcohols.
                </p>
                <div className="promo-code-wrap">
                  <div className="promo-code">
                    <p>Promocode</p>
                    <h3>20% OFF</h3>
                  </div>
                  <h2>BIGTIME</h2>
                </div>
                <Link to="/shop" className='custom-btn'>
                  Shop Now <img src={whiteArrow} alt="Arrow" />
                </Link>
              </div>
            </div>
            <div className="banner-content-right">
              <img src={Banner2} alt="Banner" />
              <img className='banner-product' src={BannerProduct} alt="Banner Product" />
            </div>
          </div>
        </SwiperSlide>
        
      </Swiper>
    </section>
  )
}
export default Banner