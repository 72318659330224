// src/features/cart/cartActions.js
import { createAction } from '@reduxjs/toolkit';
import {
    addToCartService, removeFromCartService, updateCartQtyService, moveCartToCheckoutByflag,
    getCartService, clearCartService, syncCartToDatabase, getTaxRate,
    getPromocode,
} from '../../services/cartService';

// Action Creators
export const clearMessages = createAction('cart/clearMessages');

// Add to Cart
export const addToCartStart = createAction('cart/addToCartStart');
export const addToCartSuccess = createAction('cart/addToCartSuccess');
export const addToCartFailure = createAction('cart/addToCartFailure');

// Remove from Cart
export const removeFromCartStart = createAction('cart/removeFromCartStart');
export const removeFromCartSuccess = createAction('cart/removeFromCartSuccess');
export const removeFromCartFailure = createAction('cart/removeFromCartFailure');

// Update Cart Quantity
export const updateCartQtyStart = createAction('cart/updateCartQtyStart');
export const updateCartQtySuccess = createAction('cart/updateCartQtySuccess');
export const updateCartQtyFailure = createAction('cart/updateCartQtyFailure');

// Move Cart to checkout
export const moveCartToCheckoutStart = createAction('cart/moveCartToCheckoutStart');
export const moveCartToCheckoutSuccess = createAction('cart/moveCartToCheckoutSuccess');
export const moveCartToCheckoutFailure = createAction('cart/moveCartToCheckoutFailure');


// Get Cart
export const getCartStart = createAction('cart/getCartStart');
export const getCartSuccess = createAction('cart/getCartSuccess');
export const getCartFailure = createAction('cart/getCartFailure');

// Sync Cart
export const syncCartStart = createAction('cart/syncCartStart');
export const syncCartSuccess = createAction('cart/syncCartSuccess');
export const syncCartFailure = createAction('cart/syncCartFailure');

// Tax Rate
export const taxRateStart = createAction('cart/taxRateStart');
export const taxRateSuccess = createAction('cart/taxRateSuccess');
export const taxRateFailure = createAction('cart/taxRateFailure');


// Get Promocode on cart
export const getPromocodeStart = createAction('cart/getPromoCodeStart');
export const getPromocodeSuccess = createAction('cart/getPromocodeSuccess');
export const getPromocodeFailure = createAction('cart/getPromocodeFailure');



// Thunks
export const addToCart = (product) => async (dispatch) => {
    dispatch(addToCartStart());
    try {
        await addToCartService(product);
        dispatch(addToCartSuccess(product));
    } catch (error) {
        dispatch(addToCartFailure(error.message));
    }
};

export const removeFromCart = (product_id, variation_id) => async (dispatch) => {
    dispatch(removeFromCartStart());
    try {
        await removeFromCartService(product_id, variation_id);
        dispatch(removeFromCartSuccess({ product_id, variation_id }));
    } catch (error) {
        dispatch(removeFromCartFailure(error.message));
    }
};

export const updateCartQty = (product_id, variation_id, qty) => async (dispatch) => {
    dispatch(updateCartQtyStart());
    try {
        await updateCartQtyService(product_id, variation_id, qty);
        dispatch(updateCartQtySuccess({ product_id, variation_id, qty }));
    } catch (error) {
        dispatch(updateCartQtyFailure(error.message));
    }
};


export const moveCartToCheckout = (product_id, variation_id, flag = false) => async (dispatch) => {
    dispatch(moveCartToCheckoutStart());
    try {
        // If flag is true and product_id/variation_id are not provided, handle bulk update
        if (flag && product_id === null && variation_id === null) {
            await moveCartToCheckoutByflag(null, null, true); // Implement your bulk update logic here
        } else {
            await moveCartToCheckoutByflag(product_id, variation_id, flag);
        }
        dispatch(moveCartToCheckoutSuccess({ product_id, variation_id, flag }));
    } catch (error) {
        dispatch(moveCartToCheckoutFailure(error.message));
    }
};


export const fetchCart = () => async (dispatch) => {
    dispatch(getCartStart());
    try {
        const cart = await getCartService();
        dispatch(getCartSuccess(cart));
    } catch (error) {
        dispatch(getCartFailure(error.message));
    }
};

// Action to sync cart from localStorage to the database
export const syncCart = () => async (dispatch) => {
    dispatch(syncCartStart());
    try {
        const cart = fetchCart();
        // Make the API call to sync cart
        const response = await syncCartToDatabase(cart);

        if(response.data.status === false){
            dispatch(syncCartFailure(response.data));
        }else{
            dispatch(syncCartSuccess(response.data));
            clearCartService();
        }
    } catch (error) {
        dispatch(syncCartFailure(error.message));
    }
};

// Get tax rate
export const fetchTaxRate = () => async (dispatch) => {
    dispatch(taxRateStart());
    try {
        const response = await getTaxRate();
        if(response.data.status === false){
            dispatch(taxRateFailure(response.data));
        }else{
            dispatch(taxRateSuccess(response.data));
        }
    } catch (error) {
        dispatch(taxRateFailure(error.message));
    }
};

export const fetchPromocode = (formData) => async (dispatch) => {
    dispatch(getPromocodeStart());
    try {
        const response = await getPromocode(formData);
        if(response.data.status === false){
            dispatch(getPromocodeFailure(response.data));
        }else{
            dispatch(getPromocodeSuccess(response.data));
        }
    } catch (error) {
        dispatch(getPromocodeFailure(error.message));
    }
};