// src/features/user/visitorActions.js
import { createAction } from '@reduxjs/toolkit';
import { 
    createSubscribe as createSubscribeAPI, 
    createContactInquiry as createContactInquiryAPI, 
    getFaqs as getFaqsAPI, 
    getPageContent as getPageContentAPI,
    getSliders as getSlidersAPI,
    getTestimonials as getTestimonialsAPI,
    getAdvertise as getAdvertiseAPI
} from '../../services/visitorService';

export const clearMessages = createAction('visitor/clearMessages');

// Subscribe Create
export const createSubscribeStart = createAction('visitor/createSubscribeStart');
export const createSubscribeSuccess = createAction('visitor/createSubscribeSuccess');
export const createSubscribeFailure = createAction('visitor/createSubscribeFailure');

// Contact Create
export const createContactInquiryStart = createAction('visitor/createContactInquiryStart');
export const createContactInquirySuccess = createAction('visitor/createContactInquirySuccess');
export const createContactInquiryFailure = createAction('visitor/createContactInquiryFailure');

// Faqs
export const getFaqsStart = createAction('visitor/getFaqsStart');
export const getFaqsSuccess = createAction('visitor/getFaqsSuccess');
export const getFaqsFailure = createAction('visitor/getFaqsFailure');

// Page Contents
export const getPageContentStart = createAction('visitor/getPageContentStart');
export const getPageContentSuccess = createAction('visitor/getPageContentSuccess');
export const getPageContentFailure = createAction('visitor/getPageContentFailure');

// Sliders
export const getSliderStart = createAction('visitor/getSliderStart');
export const getSliderSuccess = createAction('visitor/getSliderSuccess');
export const getSliderFailure = createAction('visitor/getSliderFailure');

// getTestimonials
export const getTestimonialStart = createAction('visitor/getTestimonialStart');
export const getTestimonialSuccess = createAction('visitor/getTestimonialSuccess');
export const getTestimonialFailure = createAction('visitor/getTestimonialFailure');

// getAdvertises
export const getAdvertiseStart = createAction('visitor/getAdvertiseStart');
export const getAdvertiseSuccess = createAction('visitor/getAdvertiseSuccess');
export const getAdvertiseFailure = createAction('visitor/getAdvertiseFailure');


// Thunks
export const createSubscribe = (data) => async (dispatch) => {
    dispatch(createSubscribeStart());
    try {
        const response = await createSubscribeAPI(data);
        if(response.data.status === false){
            dispatch(createSubscribeFailure(response.data));
        }else{
            dispatch(createSubscribeSuccess(response.data));
        }
    } catch (error) {
        dispatch(createSubscribeFailure(error.message));
    }
};

export const createContactInquiry = (data) => async (dispatch) => {
    dispatch(createContactInquiryStart());
    try {
        const response = await createContactInquiryAPI(data);
        if(response.data.status === false){
            dispatch(createContactInquiryFailure(response.data));
        }else{
            dispatch(createContactInquirySuccess(response.data));
        }
    } catch (error) {
        dispatch(createContactInquiryFailure(error.message));
    }
};

export const getFaqs = (data) => async (dispatch) => {
    dispatch(getPageContentStart());
    try {
        const response = await getFaqsAPI(data);
        if(response.data.status === false){
            dispatch(getFaqsFailure(response.data));
        }else{
            dispatch(getFaqsSuccess(response.data));
        }
    } catch (error) {
        dispatch(getFaqsFailure(error.message));
    }
};

export const getPageContent = (data) => async (dispatch) => {
    dispatch(getPageContentStart());
    try {
        const response = await getPageContentAPI(data);
        if(response.data.status === false){
            dispatch(getPageContentFailure(response.data));
        }else{
            dispatch(getPageContentSuccess(response.data));
        }
    } catch (error) {
        dispatch(getPageContentFailure(error.message));
    }
};

export const getSliders = (data) => async (dispatch) => {
    dispatch(getSliderStart());
    try {
        const response = await getSlidersAPI(data);
        if(response.data.status === false){
            dispatch(getSliderFailure(response.data));
        }else{
            dispatch(getSliderSuccess(response.data));
        }
    } catch (error) {
        dispatch(getSliderFailure(error.message));
    }
};

export const getTestimonials = (data) => async (dispatch) => {
    dispatch(getTestimonialStart());
    try {
        const response = await getTestimonialsAPI(data);
        if(response.data.status === false){
            dispatch(getTestimonialFailure(response.data));
        }else{
            dispatch(getTestimonialSuccess(response.data));
        }
    } catch (error) {
        dispatch(getTestimonialFailure(error.message));
    }
};

export const getAdvertise = (data) => async (dispatch) => {
    dispatch(getAdvertiseStart());
    try {
        const response = await getAdvertiseAPI(data);
        if(response.data.status === false){
            dispatch(getAdvertiseFailure(response.data));
        }else{
            dispatch(getAdvertiseSuccess(response.data));
        }
    } catch (error) {
        dispatch(getAdvertiseFailure(error.message));
    }
};




