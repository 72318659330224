import React from 'react'
import SubBanner from '../component/SubBanner'  
import CheckoutComp from '../component/CheckoutComp' 
// import MedicineHealthProducts from '../component/MedicineHealthProducts' 
// import OfferSection from '../component/OfferSection' 
// import OurBlogArticle from '../component/OurBlogArticle' 
import ATC from '../component/ATC' 
import Header from '../component/Header'
import { Helmet } from 'react-helmet-async';

function Checkout() {
  return (
    <>
      <Helmet>
        <title>Checkout | HealthGonia</title>
        <meta name="description" content="Complete your purchase at HealthGonia. Review your items, enter shipping information, and proceed with payment." />
        <meta name="keywords" content="Checkout, Purchase, Shopping, HealthGonia, Payment, Review Order" />
      </Helmet>

      <div className='main cart-page'>
      <Header />
        <SubBanner HeaderTitle="Checkout" HeaderSubtitle=""/> 
        <CheckoutComp />       
        {/* 
        <MedicineHealthProducts />
        <OfferSection />
        <OurBlogArticle blogTitle="Our Blog & Article" /> 
        */}
        <ATC />
      </div>
    </>
  )
}
export default Checkout


