// src/features/career/careerActions.js
import { createAction } from '@reduxjs/toolkit';
import { 
    getJobs as getJobsAPI, 
    applyJob as applyJobAPI, 
    getJobDetails as getJobDetailsAPI 
} from '../../services/careerService';

export const clearMessages = createAction('career/clearMessages');

// Get Jobs
export const getJobsStart = createAction('career/getJobsStart');
export const getJobsSuccess = createAction('career/getJobsSuccess');
export const getJobsFailure = createAction('career/getJobsFailure');

// Apply for Job
export const applyJobStart = createAction('career/applyJobStart');
export const applyJobSuccess = createAction('career/applyJobSuccess');
export const applyJobFailure = createAction('career/applyJobFailure');

// Get Job Details by Slug
export const getJobDetailsStart = createAction('career/getJobDetailsStart');
export const getJobDetailsSuccess = createAction('career/getJobDetailsSuccess');
export const getJobDetailsFailure = createAction('career/getJobDetailsFailure');

// Thunks

// Fetch job listings
export const getJobs = (postData) => async (dispatch) => {
    dispatch(getJobsStart());
    try {
        const response = await getJobsAPI(postData);
        if (response.data.status === false) {
            dispatch(getJobsFailure(response.data));
        } else {
            dispatch(getJobsSuccess(response.data));
        }
    } catch (error) {
        console.log(error)
        dispatch(getJobsFailure(error.message));
    }
};

// Fetch job details by job slug
export const getJobDetails = (slug) => async (dispatch) => {
    dispatch(getJobDetailsStart());
    try {
        const response = await getJobDetailsAPI(slug);
        if (response.data.status === false) {
            dispatch(getJobDetailsFailure(response.data));
        } else {
            dispatch(getJobDetailsSuccess(response.data));
        }
    } catch (error) {
        dispatch(getJobDetailsFailure(error.message));
    }
};

// Apply for a specific job
export const applyJob = (data) => async (dispatch) => {
    dispatch(applyJobStart());
    try {
        const response = await applyJobAPI(data);
        if (response.data.status === false) {
            dispatch(applyJobFailure(response.data));
        } else {
            dispatch(applyJobSuccess(response.data));
        }
    } catch (error) {
        dispatch(applyJobFailure(error.message));
    }
};
