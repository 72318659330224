// src/features/user/userActions.js
import { createAction } from '@reduxjs/toolkit';
import { 
    getProfile as getProfileAPI, 
    updateProfile as updateProfileAPI, 
    changePassword as changePasswordAPI, 
    changeEmail as changeEmailAPI, 
    getAddresses as getAddressesAPI, 
    addAddresses as addAddressesAPI, 
    updateAddresses as updateAddressesAPI, 
    deleteAddresses as deleteAddressesAPI, 
} from '../../services/userService';

export const clearMessages = createAction('user/clearMessages');

// Get Profile
export const getProfileStart = createAction('user/getProfileStart');
export const getProfileSuccess = createAction('user/getProfileSuccess');
export const getProfileFailure = createAction('user/getProfileFailure');

// Profile Update
export const updateProfileStart = createAction('user/updateProfileStart');
export const updateProfileSuccess = createAction('user/updateProfileSuccess');
export const updateProfileFailure = createAction('user/updateProfileFailure');

// Change Password
export const changePasswordStart = createAction('user/changePasswordStart');
export const changePasswordSuccess = createAction('user/changePasswordSuccess');
export const changePasswordFailure = createAction('user/changePasswordFailure');

// Change Email
export const changeEmailStart = createAction('user/changeEmailStart');
export const changeEmailSuccess = createAction('user/changeEmailSuccess');
export const changeEmailFailure = createAction('user/changeEmailFailure');

// Addresses
export const getAddressesStart = createAction('user/getAddressesStart');
export const getAddressesSuccess = createAction('user/getAddressesSuccess');
export const getAddressesFailure = createAction('user/getAddressesFailure');

export const addAddressesStart = createAction('user/addAddressesStart');
export const addAddressesSuccess = createAction('user/addAddressesSuccess');
export const addAddressesFailure = createAction('user/addAddressesFailure');

export const updateAddressesStart = createAction('user/updateAddressesStart');
export const updateAddressesSuccess = createAction('user/updateAddressesSuccess');
export const updateAddressesFailure = createAction('user/updateAddressesFailure');

export const deleteAddressesStart = createAction('user/deleteAddressesStart');
export const deleteAddressesSuccess = createAction('user/deleteAddressesSuccess');
export const deleteAddressesFailure = createAction('user/deleteAddressesFailure');


// Thunks
export const getProfile = () => async (dispatch) => {
    dispatch(getProfileStart());
    try {
        const response = await getProfileAPI();
        if(response.data.status === false){
            dispatch(getProfileFailure(response.data));
        }else{
            dispatch(getProfileSuccess(response.data));
        }
    } catch (error) {
        dispatch(getProfileFailure(error.message));
    }
};

export const updateProfile = (profileData) => async (dispatch) => {
    dispatch(updateProfileStart());
    try {
        const response = await updateProfileAPI(profileData);
        if(response.data.status === false){
            dispatch(updateProfileFailure(response.data));
        }else{
            dispatch(updateProfileSuccess(response.data));
        }
    } catch (error) {
        dispatch(updateProfileFailure(error.message));
    }
};

export const changePassword = (passwordData) => async (dispatch) => {
    dispatch(changePasswordStart());
    try {
        const response = await changePasswordAPI(passwordData);
        if(response.data.status === false){
            dispatch(changePasswordFailure(response.data));
        }else{
            dispatch(changePasswordSuccess(response.data));
        }
    } catch (error) {
        dispatch(changePasswordFailure(error.message));
    }
};

export const changeEmail = (emailData) => async (dispatch) => {
    dispatch(changeEmailStart());
    try {
        const response = await changeEmailAPI(emailData);
        if(response.data.status === false){
            dispatch(changeEmailFailure(response.data));
        }else{
            dispatch(changeEmailSuccess(response.data));
        }
    } catch (error) {
        dispatch(changeEmailFailure(error.message));
    }
};

export const getAddresses = (data) => async (dispatch) => {
    dispatch(getAddressesStart());
    try {
        const response = await getAddressesAPI(data);
        if(response.data.status === false){
            dispatch(getAddressesFailure(response.data));
        }else{
            dispatch(getAddressesSuccess(response.data));
        }
    } catch (error) {
        dispatch(getAddressesFailure(error.message));
    }
};

export const addAddresses = (addressesData) => async (dispatch) => {
    dispatch(addAddressesStart());
    try {
        const response = await addAddressesAPI(addressesData);
        if(response.data.status === false){
            dispatch(addAddressesFailure(response.data));
        }else{
            dispatch(addAddressesSuccess(response.data));
        }
    } catch (error) {
        dispatch(addAddressesFailure(error.message));
    }
};

export const updateAddresses = (addressesData) => async (dispatch) => {
    dispatch(updateAddressesStart());
    try {
        const response = await updateAddressesAPI(addressesData);
        if(response.data.status === false){
            dispatch(updateAddressesFailure(response.data));
        }else{
            dispatch(updateAddressesSuccess(response.data));
        }
    } catch (error) {
        dispatch(updateAddressesFailure(error.message));
    }
};

export const deleteAddresses = (addressesData) => async (dispatch) => {
    dispatch(deleteAddressesStart());
    try {
        const response = await deleteAddressesAPI(addressesData);
        if(response.data.status === false){
            dispatch(deleteAddressesFailure(response.data));
        }else{
            dispatch(deleteAddressesSuccess(response.data));
        }
    } catch (error) {
        dispatch(deleteAddressesFailure(error.message));
    }
};
