import React, {  useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'

import Header from '../component/Header'
import OurBlogArticle from '../component/OurBlogArticle'
import ATC from '../component/ATC' 

//import Aboutvideo from '../assets/images/about/about-video.mp4'
import whiteArrow from '../assets/images/button-arrow.svg'
import Skeleton from 'react-loading-skeleton';

import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { getTestimonials, getPageContent } from "../features/visitor/visitorActions";


function AboutUs() {

  const [aboutUsData, setAboutUsData] = useState(null);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  const dispatch = useDispatch();
  const { testimonialResponse, pageResponse } = useSelector((state) => state.visitor);

  useEffect(() => {
    let data = { page: 1, limit: 10 };
    dispatch(getTestimonials(data));

    let aboutData = { code: 'about-us' };
    dispatch(getPageContent(aboutData));

  }, [dispatch]);


  useEffect(() => {
    if (pageResponse?.about_us_section) {
      let aboData = pageResponse?.about_us_section;
      if(aboData !== undefined){
        aboData = JSON.parse(aboData);
        setAboutUsData(aboData);
      }
    }
  }, [pageResponse]);
  
  
  
  return (
    <>
      <Helmet>
        <title>About Us | HealthGonia</title>
        <meta name="description" content="Learn more about our company, our mission, and the dedicated team behind Your Site Name. We are committed to delivering top-quality services and products." />
        <meta name="keywords" content="About Us, Company, Mission, Team, Services" />
      </Helmet>

      <div className='main about-page'>
        <Header/>
        <section className='about-banner common-padding'>
        {(!aboutUsData) ? (
          // Skeleton loader
          <>
            <div className="skeleton-video">
              <Skeleton height={400} width="100%" />
            </div>
            <div className='about-banner-box'>
              <Skeleton height={40} width="60%" />
              <Skeleton height={20} width="80%" count={2} />
            </div>
          </>
        ) : (
          // Actual content when data is fetched
          <>
            <video id="background-video" width="100%" autoPlay={true} controls muted src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${aboutUsData?.main_video}`} preload="auto" loop></video>
            <div className='about-banner-box'>
              <h1>{aboutUsData?.title}</h1>
              <p>{aboutUsData?.description}</p>
            </div>
          </>
        )}
      </section>
        <section className="about-who-we-are">
            {aboutUsData !== null &&
              <img className='"about-who-we-are-img' src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${aboutUsData?.bg_image_section_1}`} alt="Who We Are" />
            }
            <div className="about-who-we-are-box">
                  <span>{aboutUsData?.subtitle_section_1}</span>
                  <h3>{aboutUsData?.title_section_1}</h3>
                  <p>{aboutUsData?.description_section_1}</p>  
                  <Link to={aboutUsData?.btn_title_section_1} className='cutsom-bttn'>{aboutUsData?.btn_title_section_1} <img src={whiteArrow} alt="" /></Link>
            </div>
        </section>
        <section className='about-section bg-primary-light'>        
          <Row className='align-items-center'>
            <Col md="6" lg="6" className='order-2 order-md-1'>
                <div className="about-section-text pr-100">
                    <span>{aboutUsData?.subtitle_section_2}</span>
                    <h3>{aboutUsData?.title_section_2}</h3>
                    <p>{aboutUsData?.description_section_2}</p>
                    <Link to={aboutUsData?.btn_link_section_2} className='cutsom-bttn'>{aboutUsData?.btn_title_section_2} <img src={whiteArrow} alt="" /></Link>
                </div>
              </Col>   
              <Col md="6" lg="6" className='order-1 order-md-2'>
              <div className="about-section-img">
                    <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${aboutUsData?.bg_image_section_2}`} alt="" />
                  </div>              
              </Col>
          </Row>
        </section> 
        <section className='about-section'>        
          <Row className='align-items-center'>
              <Col md="6" lg="4">
              <div className="about-section-img">
                    <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${aboutUsData?.bg_image_section_3}`} alt="" />
                  </div>              
              </Col>
              <Col md="6" lg="8">
                <div className="about-section-text">
                    <span>{aboutUsData?.subtitle_section_3}</span>
                    <h3>{aboutUsData?.title_section_3}</h3>
                    <p>{aboutUsData?.description_section_3}</p>
                    <Link to={aboutUsData?.btn_link_section_3} className='cutsom-bttn'>{aboutUsData?.btn_title_section_3} <img src={whiteArrow} alt="" /></Link>
                </div>
              </Col>           
          </Row>
        </section> 
        <section className='about-section we-ar-here bg-primary-light'>        
          <Row className='align-items-center'>             
              <Col lg="6" className='order-2 order-md-1'>
                <div className="about-section-text pr-100">
                    <span>{aboutUsData?.subtitle_section_4}</span>
                    <h3>{aboutUsData?.title_section_4}</h3>
                    <p>{aboutUsData?.description_section_4}</p>
                    <div className="we-are-here-help-list">
                      <div className="we-are-here-help-box">
                        <div className="we-are-here-help-box-img">
                          <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${aboutUsData?.card_icon_4_1}`} alt="" />
                        </div>
                        <div className="we-are-here-help-box-text">
                          <h3>{aboutUsData?.card_title_4_1}</h3>
                          <p>{aboutUsData?.card_description_4_1}</p>
                        </div>
                      </div>
                      <div className="we-are-here-help-box">
                        <div className="we-are-here-help-box-img">
                        <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${aboutUsData?.card_icon_4_2}`} alt="" />
                        </div>                        
                        <div className="we-are-here-help-box-text">
                          <h3>{aboutUsData?.card_title_4_2}</h3>
                          <p>{aboutUsData?.card_description_4_2}</p>
                        </div>
                      </div>
                      <div className="we-are-here-help-box">
                        <div className="we-are-here-help-box-img">
                          <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${aboutUsData?.card_icon_4_3}`} alt="" />
                        </div>                        
                        <div className="we-are-here-help-box-text">
                          <h3>{aboutUsData?.card_title_4_3}</h3>
                          <p>{aboutUsData?.card_description_4_3}</p>
                        </div>
                      </div>
                    </div>
                    <Link to={aboutUsData?.btn_link_section_4} className='cutsom-bttn'>{aboutUsData?.btn_title_section_4} <img src={whiteArrow} alt="" /></Link>
                </div>
              </Col> 
              <Col lg="6" className='order-1 order-md-2'>
                  <div className="about-section-img">
                    <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${aboutUsData?.bg_image_section_4}`} alt="" />
                  </div>              
              </Col>          
          </Row>
        </section> 
        <section className='about-section'>        
          <Row className='align-items-center'>
              <Col md="6" lg="6">
                  <div className="about-section-img">
                    <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${aboutUsData?.bg_image_section_5}`} alt="" />
                  </div>              
              </Col>
              <Col md="6" lg="6">
                <div className="about-section-text pl-0">
                    <span>{aboutUsData?.subtitle_section_5}</span>
                    <h3>{aboutUsData?.title_section_5}</h3>
                    <p>{aboutUsData?.description_section_5}</p>
                    <Link to={aboutUsData?.btn_link_section_5} className='cutsom-bttn'>{aboutUsData?.btn_title_section_5} <img src={whiteArrow} alt="" /></Link>
                </div>
              </Col> 
          </Row>
        </section> 
        <section className='about-section our-mindset bg-primary-light'>        
          <Row className='align-items-center'>
            <Col md="12">
                <div className="about-section-text px-0">
                    <span className='about-section-text-sub'>{aboutUsData?.subtitle_section_6}</span>
                    <h3>{aboutUsData?.title_section_6}</h3>
                    <Row>
                      <Col md="12" lg="6">
                        <div className="our-mindset-box">
                          <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${aboutUsData?.card_image_6_1}`} alt="" />
                          <div className='our-mindset-box-content'>
                            <h4>{aboutUsData?.card_title_6_1}</h4>
                            <div className='' dangerouslySetInnerHTML={{__html: aboutUsData?.card_description_6_1}}></div>
                          </div>
                        </div>
                      </Col>
                      <Col md="12" lg="6">
                      <div className="our-mindset-box">
                          <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${aboutUsData?.card_image_6_2}`} alt="" />
                          <div className='our-mindset-box-content'>
                            <h4>{aboutUsData?.card_title_6_2}</h4>
                            <div className='' dangerouslySetInnerHTML={{__html: aboutUsData?.card_description_6_2}}></div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Link to={aboutUsData?.btn_link_section_6} className='cutsom-bttn'>{aboutUsData?.btn_title_section_6} <img src={whiteArrow} alt="" /></Link>
                </div>
              </Col>                       
          </Row>
        </section> 
        <section className='about-section'>        
          <Row className='align-items-center'>              
              <Col md="6" lg="8" className='order-2 order-md-1'>
                <div className="about-section-text pr-100">
                    <span>{aboutUsData?.subtitle_section_7}</span>
                    <h3>{aboutUsData?.title_section_7}</h3>
                    <p>{aboutUsData?.description_section_7}</p>
                    <Link to={aboutUsData?.btn_link_section_7} className='cutsom-bttn'>{aboutUsData?.btn_title_section_7} <img src={whiteArrow} alt="" /></Link>
                </div>
              </Col>  
              <Col md="6" lg="4" className='order-1 order-md-2'>
                  <div className="about-section-img">
                    <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${aboutUsData?.bg_image_section_7}`} alt="" />
                  </div>              
              </Col>         
          </Row>
        </section> 
        
        <section className="testimonials our-partners">
          <div className="common-header">
            <p>What Our Partners say!</p>
            <h2>Our <span>Partners</span></h2>
          </div>

          
          {testimonialResponse?.data.length > 0  && (
            <div className="testimonials-slider">
              <Swiper
                slidesPerView={1}
                navigation={{
                  prevEl: navigationPrevRef.current,
                  nextEl: navigationNextRef.current,
                }}
                onBeforeInit={(swiper) => {
                  swiper.params.navigation.prevEl = navigationPrevRef.current;
                  swiper.params.navigation.nextEl = navigationNextRef.current;
                }}
                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                loop={true}
                className="main-swiper"
              >

                <>
                {testimonialResponse?.data?.map((row, index) => (
                  <SwiperSlide key={"slider"+index}>
                      <div className="testimonials-box">
                        <span><img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${row?.customer_image}`} alt="" /></span>
                      
                        <div className="testimonials-user-details">
                          <p>{row.description}</p>
                          <h3>{row.customer_name}</h3>
                          <span>{row.designation}</span>
                        </div>
                      </div>
                  </SwiperSlide>
                ))}
                </>
        
                <div className='swiper-button-nav'>
                  <div ref={navigationPrevRef} className="swiper-button-prev"></div>
                  <div ref={navigationNextRef} className="swiper-button-next"></div>
                </div>
              </Swiper>
              
              {/* Thumbnail Slider */}
              <Swiper
                onSwiper={setThumbsSwiper}
                spaceBetween={10}
                slidesPerView={5}
                freeMode={true}
                watchSlidesProgress={true}
                loop={true}
                className="thumbs-swiper"
                breakpoints={{
                  320: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  480: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                  1200: {
                    slidesPerView: 5,
                    spaceBetween: 40,
                  },
                }}
              >

                <>
                  {testimonialResponse?.data?.map((row, index) => (
                  <SwiperSlide key={'thumb'+index}>
                    <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${row?.customer_image}`} alt={row.customer_name} />
                  </SwiperSlide>
                  ))}
                </>
                
              </Swiper>
            </div>
          )}
              
        </section>


        <OurBlogArticle blogTitle="Our Blog & Article" />
        <ATC />
      </div>
    </>
  )
}
export default AboutUs


