import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "react-bootstrap";
import { CDBStepper, CDBStep, CDBBtn } from "cdbreact";
// import modalClose from "../assets/images/modal-close.svg";
import buttonArowPrimary from "../assets/images/button-arrow-primary.svg";
import buttonArowWhite from "../assets/images/button-arrow.svg";
import BackIcon from "../assets/images/back-icon-white.svg";

import { useDispatch, useSelector } from "react-redux";
import { clearMessages, applyJob } from "../features/career/careerActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { formatPhoneNumber } from "../helpers/helpers";
import { TERMS_AND_CONDITIONS } from "../Constants";


function CareerForm(props) {

  const dispatch = useDispatch();
  const { successMessage, errorMessage } = useSelector((state) => state.career);
 
  const [activeStep, setActiveStep] = useState(1); // State to track the active step

  // Handler to navigate between steps
  const handleNextPrevClick = (step) => {
    setActiveStep(step);
  };

  useEffect(() => {
    if (successMessage) {
      const timer = setTimeout(() => {
        props.closeAllAccordions();
        toast.success(successMessage, { autoClose: 3000, hideProgressBar: true });
        localStorage.removeItem("firstForm");
        localStorage.removeItem("secondForm");
        setActiveStep(1);
        dispatch(clearMessages());
      }, 0);
  
      return () => clearTimeout(timer); // Cleanup timer
    }
  
    if (errorMessage) {
      const timer = setTimeout(() => {
        toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
        dispatch(clearMessages());
      }, 0);
  
      return () => clearTimeout(timer); // Cleanup timer
    }
  }, [successMessage, errorMessage, dispatch, props]);

  useEffect(() => {
    localStorage.removeItem("firstForm");
    localStorage.removeItem("secondForm");
    localStorage.removeItem("fileDetails");
    setActiveStep(1);
  }, [])
  
  const steps = [
    {
      id: 1,
      icon: "check",
      name: "My Information",
    },
    {
      id: 2,
      icon: "check",
      name: "Voluntary Disclosures",
    },
    {
      id: 3,
      icon: "check",
      name: "Self Identity",
    },
  ];

  return (
    <>
      <div className="career-form">
        <div className="career-form-header">
          <p className="career-form-header-top">Apply here</p>
          <h3>Please fill your basic details.</h3>
        </div>

        <CDBStepper steps={steps}>
          {steps.map((step) => (
            <CDBStep
              key={step.id}
              id={step.id}
              icon={step.icon}
              name={step.name}
              handleClick={() => console.log("test")}
              active={activeStep}
              component={
                step.id === 1 ? (
                  <Step1 activeStep={activeStep} setActiveStep={setActiveStep} handleNextPrevClick={handleNextPrevClick} job={props.job} />
                ) : step.id === 2 ? (
                  <Step2 activeStep={activeStep} setActiveStep={setActiveStep} handleNextPrevClick={handleNextPrevClick} job={props.job} />
                ) : (
                  <Step3 activeStep={activeStep} setActiveStep={setActiveStep} handleNextPrevClick={handleNextPrevClick} job={props.job} />
                )
              }
            />
          ))}
        </CDBStepper>

        {/* 
        <CDBStepper>
          <CDBStep
            id={1}
            icon="check"
            name="My Information"
            handleClick={() => handleNextPrevClick(1)}
            active={activeStep}
            component={<Step1 activeStep={activeStep} setActiveStep={setActiveStep} handleNextPrevClick={handleNextPrevClick} job={job} />}
          />
          <CDBStep
            id={2}
            icon="check"
            name="Voluntary Disclosures"
            handleClick={() => handleNextPrevClick(2)}
            active={activeStep}
            component={<Step2 activeStep={activeStep} setActiveStep={setActiveStep} handleNextPrevClick={handleNextPrevClick} job={job} />}
          />
          <CDBStep
            id={3}
            icon="check"
            name="Self Identity"
            handleClick={() => handleNextPrevClick(3)}
            active={activeStep}
            component={<Step3 activeStep={activeStep} setActiveStep={setActiveStep} handleNextPrevClick={handleNextPrevClick} job={job} />}
          />
        </CDBStepper> */}
      </div>
    </>
  );
}

export default CareerForm;

const Step1 = (props) => {

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [jobTitle, setJobTitle] = useState(props.job.job_title);
  const [jobId, setJobId] = useState(props.job.id);
  const [about, setAbout] = useState("");
  const [file, setFile] = useState(null);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (props.activeStep === 1) {
      var retrievedObject = JSON.parse(localStorage.getItem('firstForm'));
      if (retrievedObject !== null) {
        setFirstName(retrievedObject.firstName);
        setLastName(retrievedObject.lastName);
        setPhone(retrievedObject.phone);
        setEmail(retrievedObject.email);
        setAbout(retrievedObject.about);
        setJobTitle(retrievedObject.jobTitle);
        setJobId(retrievedObject.jobId);
        setFile(retrievedObject.file);
      }
    }
  }, [props]);

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    setFirstName(value);
    if (!value) {
      setErrors({ ...errors, firstName: "First Name is required" });
    } else {
      setErrors({ ...errors, firstName: "" });
    }
  };


  const handleLastNameChange = (e) => {
    const value = e.target.value;
    setLastName(value);
    if (!value) {
      setErrors({ ...errors, lastName: "Last Name is required" });
    } else {
      setErrors({ ...errors, lastName: "" });
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setErrors({ ...errors, email: "Please enter a valid email address" });
    } else {
      setErrors({ ...errors, email: "" });
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhone(formatPhoneNumber(value));
  };



  // Example file input change handler
  const handleFileChange = (e) => {
    const fileObj = e.target.files[0];

    if (!fileObj) {
      setErrors({ ...errors, file: "Resume file is required" });
      return;
    } else {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64File = reader.result; // Base64 encoded file data
        // Save file details
        localStorage.setItem('fileDetails', JSON.stringify({
          name: fileObj.name,
          type: fileObj.type,
          size: fileObj.size,
          data: base64File // optional, for preview purposes
        }));
        
        setFile(base64File);
      };

      reader.readAsDataURL(fileObj); // Convert the file to base64
    }
  };

  // const handleFileChange = (e) => {
  //   const fileObj = e.target.files[0];

  //   if (!fileObj) {
  //     // Set error if no file is selected
  //     setErrors({ ...errors, file: "Resume file is required" });
  //     return;
  //   }else{
  //     setErrors({ ...errors, file: "" });
  //     setFile(fileObj); 
  //     console.log(fil)
  //   }
  // };

  const handleAboutChange = (e) => {
    const value = e.target.value;
    setAbout(value);
    if (!value) {
      setErrors({ ...errors, about: "Tell us about yourself is required" });
    } else {
      setErrors({ ...errors, about: "" });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!firstName) newErrors.firstName = "First Name is required";
    if (!lastName) newErrors.lastName = "Last Name is required";
    if (!email) newErrors.email = "Email Address is required";
    if (!phone) newErrors.phone = "Phone Number is required";
    if (phone && phone.length !== 12) newErrors.phone = "Please enter valid phone number.";

    if (!file) newErrors.file = "Resume is required";
    if (!about) newErrors.about = "Tell us about yourself is required";
    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      toast.error("Please fill out all required fields.");
      return;
    }
    let formDataObj = {
      jobId: jobId,
      jobTitle: jobTitle,
      firstName: firstName,
      lastName: lastName,
      file: file,
      email: email,
      phone: phone,
      about: about
    }

    localStorage.setItem('firstForm', JSON.stringify(formDataObj));
    props.setActiveStep(2);
  };
  
  return (
    <div className="StepContainer">
      <div className="FlexColumnContainer">
        <form onSubmit={handleSubmit}>
            <div className="mb-3 form-group">
              <input
                className="form-control"
                type="text"
                name="firstName"
                placeholder="First Name*"
                value={firstName}
                onChange={handleFirstNameChange}
              />
              {errors.firstName && (
                <div className="error-text">{errors.firstName}</div>
              )}
            </div>

            <div className="mb-3 form-group">
              <input
                className="form-control"
                type="text"
                name="lastName"
                placeholder="Last Name*"
                value={lastName}
                onChange={handleLastNameChange}
              />
              {errors.lastName && (
                <div className="error-text">{errors.lastName}</div>
              )}
            </div>
            <div className="mb-3 form-group">
              <input
                className="form-control"
                type="email"
                name="email"
                placeholder="Email Address*"
                value={email}
                onChange={handleEmailChange}
              />
              {errors.email && <div className="error-text">{errors.email}</div>}
            </div>
            <div className="mb-3 form-group">
              <input
                className="form-control"
                type="text"
                name="phone"
                placeholder="Phone Number*"
                value={phone}
                maxLength={12}
                onChange={handlePhoneChange}
              />
              {errors.phone && <div className="error-text">{errors.phone}</div>}
            </div>
            <div className="mb-3 form-group">
              <input
                className="form-control"
                type="text"
                readOnly
                value={jobTitle}
                placeholder="Job Title*"
              />
            </div>

            <div className="mb-3 form-group">
              <div className="">
                <input
                  className="form-control"
                  id="file-upload-input"
                  type="file"
                  onChange={handleFileChange}
                  accept=".pdf,.doc,.docx"
                />
                {errors.file && (
                  <div className="error-text">
                    {errors.file}
                  </div>
                )}
              </div>
            </div>
            <div className="mb-3 form-group w-full">
              <textarea
                name="about"
                rows={5}
                placeholder="Tell us about yourself*"
                value={about}
                onChange={handleAboutChange}
              />
              {errors.about && <div className="error-text">{errors.about}</div>}
            </div>

            <div className="d-flex justify-content-between w-full">
              <CDBBtn className="cutsom-bttn" type="submit">
                Next <img className="on-hover" src={buttonArowWhite} alt="" />
              </CDBBtn>
            </div>
        </form>
      </div>
    </div>
  );
};

const Step2 = (props) => {

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //  disability
  const [showDisability, setShowDisability] = useState(false);
  const handleDisabilityClose = () => setShowDisability(false);
  const handleDisabilityShow = () => setShowDisability(true);

  const [disability, setDisability] = useState('Yes');

  useEffect(() => {
    if (props.activeStep === 2) {
      const retrievedObject = JSON.parse(localStorage.getItem('secondForm'));
      if (retrievedObject !== null) {
        setDisability(retrievedObject.disability);
      }
    }
  }, [props]);

  const handleDisabilityChange = (e) => {
    setDisability(e.target.value);
  };

  function handleSubmit(event) {
    event.preventDefault();
    let formDataObj = {
      disability: disability,
    }
    localStorage.setItem('secondForm', JSON.stringify(formDataObj));
    props.setActiveStep(3);
  }

  return (
    <div className="StepContainer">
      <div className="FlexColumnContainer">
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3 form-group w-full">
            <Form.Label className="checkbox-header">
              Please check one of the boxes below if you have a disability?
              <span>*</span>
            </Form.Label>
            <div className="radio-group">
              <input
                id="checkOne"
                name="radio"
                type="radio"
                value="Yes"
                checked={disability === "Yes"}
                onChange={handleDisabilityChange}
              />
              
              <label htmlFor="checkOne">
                Yes, I have a disability, or have had one in the past
              </label>

              <input
                id="checkTwo"
                name="radio"
                type="radio"
                value="No"
                checked={disability === "No"}
                onChange={handleDisabilityChange}
              />
              <label htmlFor="checkTwo">
                No, I do not have a disability and have not had one in the past
              </label>

              <input
                id="checkThree"
                name="radio"
                type="radio"
                value="I do not want to answer"
                checked={disability === "I do not want to answer"}
                onChange={handleDisabilityChange}
              />
              <label htmlFor="checkThree">I do not want to answer</label>
              

            </div>
          </Form.Group>
          
          <div className="mb-3 form-group w-full">
            <div className="form-info">
              <p>
                Why are you being asked to complete this form?{" "}
                <span  onClick={handleShow}>
                  Know more <img src={buttonArowPrimary} alt="" />
                </span>
              </p>
              <p>
                How do you know if you have a disability?{" "}
                <span onClick={handleDisabilityShow}>
                  Know more <img src={buttonArowPrimary} alt="" />
                </span>
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-between w-full mt-4">
            <CDBBtn
              className="cutsom-bttn  back-bttn"
              onClick={() => props.setActiveStep(1)}
            >
              <img src={BackIcon} alt="" /> Back
            </CDBBtn>
            <CDBBtn
              className="cutsom-bttn"
              type="submit"
            >
              Next <img className="on-hover" src={buttonArowWhite} alt="" />
            </CDBBtn>
          </div>
        </Form>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        className="common-modal"
        backdropClassName="common-backdrop-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>
            Why are you being asked to complete this form?{" "}
          </Modal.Title>
          <Button onClick={handleClose}>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p>
            We are a federal contractor or subcontractor. The law requires us to
            provide equal employment opportunity to qualified people with
            disabilities. We have a goal of having at least 7% of our workers as
            people with disabilities. The law says we must measure our progress
            towards this goal. To do this, we must ask applicants and employees
            if they have a disability or have ever had one. People can become
            disabled, so we need to ask this question at least every five years.
          </p>
          <p>
            Completing this form is voluntary, and we hope that you will choose
            to do so. Your answer is confidential. No one who makes hiring
            decisions will see it. Your decision to complete the form and your
            answer will not harm you in any way. If you want to learn more about
            the law or this form, visit the U.S. Department of Labor’s Office of
            Federal Contract Compliance Programs (OFCCP) website at
            www.dol.gov/ofccp. How do you know if you have a disability
          </p>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDisability}
        onHide={handleDisabilityClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        className="common-modal"
        backdropClassName="common-backdrop-modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>How do you know if you have a disability? </Modal.Title>
          <Button onClick={handleDisabilityClose}>
            
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p>
            A disability is a condition that substantially limits one or more of
            your “major life activities.” If you have or have ever had such a
            condition, you are a person with a disability.{" "}
            <span>Disabilities include, but are not limited to:</span>
          </p>
          <div className="disabilities">
            <ul>
              <li>
                Alcohol or other substance use disorder (not currently using
                drugs illegally)
              </li>
              <li>
                Autoimmune disorder, for example, lupus, fibromyalgia,
                rheumatoid arthritis, HIV/AIDS
              </li>
              <li>Blind or low vision</li>
              <li>Cancer (past or present)</li>
              <li>Cardiovascular or heart disease</li>
              <li>Celiac disease</li>
              <li>Cerebral palsy</li>
              <li>Deaf or serious difficulty hearing</li>
              <li>Diabetes</li>
            </ul>
            <ul>
              <li>
                Disfigurement, for example, disfigurement caused by burns,
                wounds, accidents, or congenital disorders
              </li>
              <li>Epilepsy or other seizure disorde</li>
              <li>
                Gastrointestinal disorders, for example, Crohn's Disease,
                irritable bowel syndrome
              </li>
              <li>Intellectual or developmental disability</li>
              <li>
                Mental health conditions, for example, depression, bipolar
                disorder, anxiety disorder, schizophrenia, PTSD
              </li>
              <li>Missing limbs or partially missing limbs</li>
              <li>
                Mobility impairment, benefiting from the use of a wheelchair,
                scooter, walker, leg brace(s) and/or other supports
              </li>
            </ul>
            <ul>
              <li>
                Nervous system condition, for example, migraine headaches,
                Parkinson’s disease, multiple sclerosis (MS)
              </li>
              <li>
                Neurodivergence, for example, attention-deficit/hyperactivity
                disorder (ADHD), autism spectrum disorder, dyslexia, dyspraxia,
                other learning disabilities
              </li>
              <li>Partial or complete paralysis (any cause)</li>
              <li>
                Pulmonary or respiratory conditions, for example, tuberculosis,
                asthma, emphysema
              </li>
              <li>Short stature (dwarfism)</li>
              <li>Traumatic brain injury</li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

const Step3 = (props) => {

  const [nationality, setNationality] = useState('Yes');
  const [race, setRace] = useState([]);
  const [veteran, setVeteran] = useState("I identity as one or more of the classifications of protected veteran listed below");
  const [termsCondition, setTermsCondition] = useState(false); // Use boolean for checkbox state

  const [nationalityError, setNationalityError] = useState('');
  const [raceError, setRaceError] = useState('');
  const [veteranError, setVeteranError] = useState('');
  const [termsConditionError, setTermsConditionError] = useState('');

  const handleChangeNationality = (e) => {
    setNationality(e.target.value);
  };

  const handleChangeRace = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setRace([...race, value]); // Add to array
    } else {
      setRace(race.filter(item => item !== value)); // Remove from array
    }
  };

  const handleChangeVeteran = (e) => {
    setVeteran(e.target.value);
  };

  const handleChangeTermsCondition = (e) => {
    setTermsCondition(e.target.checked);
  };

  const dispatch = useDispatch();
  const { jobAppliedLoading } = useSelector((state) => state.career);

  function handleSubmit(event) {
    event.preventDefault();

    // Perform validation
    let formValid = true;

    if (!nationality) {
      setNationalityError('Please select your Hispanic origin.');
      formValid = false;
    } else {
      setNationalityError('');
    }

    if (race.length === 0) {
      setRaceError('Please select at least one race.');
      formValid = false;
    } else {
      setRaceError('');
    }

    if (!veteran) {
      setVeteranError('Please confirm your veteran status.');
      formValid = false;
    } else {
      setVeteranError('');
    }

    if (!termsCondition) {
      setTermsConditionError('You must agree to the terms and conditions.');
      formValid = false;
    } else {
      setTermsConditionError('');
    }

    if (formValid) {
      
      var personalInformation = JSON.parse(localStorage.getItem('firstForm'));
      if (personalInformation === null) {
        toast.error('Please fill out personal information.');
        return;
      }

      var disabilityInformation = JSON.parse(localStorage.getItem('secondForm'));
      if (disabilityInformation === null) {
        toast.error('Please fill out disability information.');
        return;
      }

      const retrievedFileDetails = JSON.parse(localStorage.getItem('fileDetails'));

      const formDataObj = new FormData();
      formDataObj.append('job_id', personalInformation.jobId);
      formDataObj.append('job_title', personalInformation.jobTitle);
      formDataObj.append('first_name', personalInformation.firstName);
      formDataObj.append('last_name', personalInformation.lastName);
      formDataObj.append('email', personalInformation.email);
      formDataObj.append('phone_no', personalInformation.phone);
      
      // Reconstruct the File object from the base64 data if necessary
      if (retrievedFileDetails) {
        const byteCharacters = atob(retrievedFileDetails.data.split(',')[1]);
        const byteNumbers = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const blob = new Blob([byteNumbers], { type: retrievedFileDetails.type });
        const file = new File([blob], retrievedFileDetails.name, { type: retrievedFileDetails.type });
        formDataObj.append('resume_file', file); // Append reconstructed file
      } else {
        console.error('File is not valid:', retrievedFileDetails);
      }


      formDataObj.append('tell_us_about_your_self', personalInformation.about);
      formDataObj.append('is_disability', disabilityInformation.disability);
      formDataObj.append('nationality', nationality);
      formDataObj.append('race', race);
      formDataObj.append('veteran', veteran);

      // Dismiss any currently visible toasts
      toast.dismiss();
      dispatch(applyJob(formDataObj));
    }
  }

  return (
    <div className="StepContainer">
      <div className="FlexColumnContainer">
        <Form onSubmit={handleSubmit}>

          <div className="mb-4 form-group w-full">
            <div className="form-info">
              <p>Please answer the question about hispanic origin and the question about race. For this survey, Hispanic origins are not races.
                Hispanic origin can viewed as the heritage, nationality, lineage, or country of birth of your parents or ancestors  before arriving
                In the United States. People who identify as Hispanic, Latino, or Spanish may be any race.</p>
            </div>
          </div>

          <div className="mb-4 form-group">
            <label htmlFor="veteranStatus1" className="form-label">Are you of Hispanic, Latino, or Spanish origin ?</label>
            <div className="radio-group">
              <input
                id="checkOne"
                name="radio"
                type="radio"
                value="Yes"
                checked={nationality === "Yes"}
                onChange={handleChangeNationality}
              />
              <label htmlFor="checkOne">
                Yes
              </label>

              <input
                id="checkTwo"
                name="radio"
                type="radio"
                value="No"
                checked={nationality === "No"}
                onChange={handleChangeNationality}
              />
              <label htmlFor="checkTwo">
                No
              </label>

              <input
                id="checkThree"
                name="radio"
                type="radio"
                value="I prefer not to answer"
                checked={nationality === "I prefer not to answer"}
                onChange={handleChangeNationality}
              />
              <label htmlFor="checkThree">I prefer not to answer</label>
              {nationalityError && <div className="text-danger">{nationalityError}</div>}
            </div>
          </div>

          <div className="mb-4 form-group">
            <label htmlFor="race" className="form-label">
              What is your race? Please select all that apply. (Required)
            </label>
            <div className="check-group d-flex flex-column justify-content-start align-items-start">
              <div className="form-check mr-2">
                <input
                  id="raceWhite"
                  name="race"
                  type="checkbox"
                  value="White"
                  checked={race.includes("White")}
                  onChange={handleChangeRace}
                  className="form-check-input"
                />
                <label htmlFor="raceWhite" className="form-check-label">
                  White
                </label>
              </div>
              <div className="form-check mr-2">
                <input
                  id="raceBlack"
                  name="race"
                  type="checkbox"
                  value="Black or African American"
                  checked={race.includes("Black or African American")}
                  onChange={handleChangeRace}
                  className="form-check-input"
                />
                <label htmlFor="raceBlack" className="form-check-label">
                  Black or African American
                </label>
              </div>
              <div className="form-check mr-2">
                <input
                  id="raceNativeAmerican"
                  name="race"
                  type="checkbox"
                  value="Native American or Alaska Native"
                  checked={race.includes("Native American or Alaska Native")}
                  onChange={handleChangeRace}
                  className="form-check-input"
                />
                <label htmlFor="raceNativeAmerican" className="form-check-label">
                  Native American or Alaska Native
                </label>
              </div>
              <div className="form-check mr-2">
                <input
                  id="raceAsian"
                  name="race"
                  type="checkbox"
                  value="Asian"
                  checked={race.includes("Asian")}
                  onChange={handleChangeRace}
                  className="form-check-input"
                />
                <label htmlFor="raceAsian" className="form-check-label">
                  Asian
                </label>
              </div>
              <div className="form-check mr-2">
                <input
                  id="racePacificIslander"
                  name="race"
                  type="checkbox"
                  value="Native Hawaiian, Samoan, or other Pacific Islander"
                  checked={race.includes("Native Hawaiian, Samoan, or other Pacific Islander")}
                  onChange={handleChangeRace}
                  className="form-check-input"
                />
                <label htmlFor="racePacificIslander" className="form-check-label">
                  Native Hawaiian, Samoan, or other Pacific Islander
                </label>
              </div>
              <div className="form-check mr-2">
                <input
                  id="raceOther"
                  name="race"
                  type="checkbox"
                  value="Other"
                  checked={race.includes("Other")}
                  onChange={handleChangeRace}
                  className="form-check-input"
                />
                <label htmlFor="raceOther" className="form-check-label">
                  Other
                </label>
              </div>
              <div className="form-check mr-2">
                <input
                  id="racePreferNotToAnswer"
                  name="race"
                  type="checkbox"
                  value="I prefer not to answer"
                  checked={race.includes("I prefer not to answer")}
                  onChange={handleChangeRace}
                  className="form-check-input"
                />
                <label htmlFor="racePreferNotToAnswer" className="form-check-label">
                  I prefer not to answer
                </label>
              </div>
            </div>
            {raceError && <div className="text-danger">{raceError}</div>}
          </div>


          <div className="mb-4 form-group">
            <label htmlFor="veteranStatus2" className="form-label">Please confirm your veteran status :</label>
            <div className="radio-group">
              <input
                id="checkTwo1"
                name="veteran"
                type="radio"
                value="I identity as one or more of the classifications of protected veteran listed below"
                checked={veteran === "I identity as one or more of the classifications of protected veteran listed below"}
                onChange={handleChangeVeteran}
              />
              <label htmlFor="checkTwo1">
                I identity as one or more of the classifications of protected veteran listed below
              </label>

              <input
                id="checkThree1"
                name="veteran"
                type="radio"
                value="I am not a protected veteran"
                checked={veteran === "I am not a protected veteran"}
                onChange={handleChangeVeteran}
              />
              <label htmlFor="checkThree1">I am not a protected veteran</label>

              <input
                id="checkOne1"
                name="veteran"
                type="radio"
                value="I do not wish to answer"
                checked={veteran === "I do not wish to answer"}
                onChange={handleChangeVeteran}
              />
              <label htmlFor="checkOne1">
                I do not wish to answer
              </label>

            </div>
            {veteranError && <div className="text-danger">{veteranError}</div>}
          </div>

          <div className="mb-3 form-group w-full">
            <div className="check-group">
              <input id="CheckMeOut" type="checkbox" checked={termsCondition} className="form-check-input" onChange={handleChangeTermsCondition} />
              <label className="form-check-label" htmlFor="CheckMeOut">
                Yes, I have read and consent to the <a target="_blank" rel="noreferrer" href={TERMS_AND_CONDITIONS}>terms and conditions</a>.
              </label>
            </div>
            {termsConditionError && <div className="text-danger">{termsConditionError}</div>}
          </div>
          
        
          <div className="d-flex justify-content-between w-full  mt-4">
            <CDBBtn
              className="cutsom-bttn back-bttn"
              onClick={() => props.setActiveStep(2)}
            >
              <img src={BackIcon} alt="" /> Back
            </CDBBtn>
            <CDBBtn
              className="cutsom-bttn"
              type="submit"
            >
              
              {jobAppliedLoading ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  size="lg"
                  className="spinner-icon"
                />
              ) : (
                <>
                Finish <img className="on-hover" src={buttonArowWhite} alt="" />
                </>
              )}
            </CDBBtn>
          </div>
        </Form>
      </div>
    </div>
  );
};





