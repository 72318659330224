// src/features/geoLocation/geoLocationReducer.js
import { createReducer } from '@reduxjs/toolkit';
import {
    clearMessages,
    getCountriesStart, getCountriesSuccess, getCountriesFailure,
    getStatesStart, getStatesSuccess, getStatesFailure,
    getCitiesStart, getCitiesSuccess, getCitiesFailure,
} from './geoLocationActions';

const initialState = {
    countries: [],
    states: [],
    cities: [],
    loading: false,
    successMessage: null,
    errorMessage: null,
};

const geoLocationReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(clearMessages, (state) => {
            state.successMessage = null;
            state.errorMessage = null;
        })

        .addCase(getCountriesStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getCountriesSuccess, (state, action) => {
            state.loading = false;
            state.countries = action.payload;
            state.successMessage = action.payload.message;
        })
        .addCase(getCountriesFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })
        
        .addCase(getStatesStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getStatesSuccess, (state, action) => {
            state.loading = false;
            state.states = action.payload;
            state.successMessage = action.payload.message;
        })
        .addCase(getStatesFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })
        .addCase(getCitiesStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getCitiesSuccess, (state, action) => {
            state.loading = false;
            state.cities = action.payload;
            state.successMessage = action.payload.message;
        })
        .addCase(getCitiesFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        });
});

export default geoLocationReducer;
