// src/services/authService.js
import axios from 'axios';
import { toFormData } from './utils'; // Utility function

const authService = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const login = (credentials) => {
    const formData = toFormData(credentials);
    return authService.post('/auth/login', formData);
};

export const register = (userData) => {
    const formData = toFormData(userData);
    return authService.post('/auth/register', formData);
};

export const forgotPassword = (data) => {
    const formData = toFormData(data);
    return authService.post('/auth/forgot-password', formData);
};

export const verifyEmail = (token) => {
    return authService.get('/auth/verify-email?token='+token);
};

export const resetPassword = (data) => {
    const formData = toFormData(data);
    return authService.post('/auth/reset-password', formData);
};

export default authService;
