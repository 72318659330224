// src/services/careerService.js
import axios from 'axios';
import { toFormData } from './utils'; // Utility function

const careerService = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL, // Ensure this is set correctly in your .env file
});

// Fetch job listings
export const getJobs = (jobData) => {
    const formData = toFormData(jobData); // If there are filters or data to send, convert to formData
    return careerService.post('/jobs', formData);
};

// Fetch job details by slug
export const getJobDetails = (slug) => {
    return careerService.get(`/jobs/${slug}`);
};

// Apply for a job
export const applyJob = (formData) => {
    // Assuming job application requires form data
    return careerService.post('/jobs/apply', formData);
};

export default careerService;
