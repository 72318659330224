import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createSubscribe, clearMessages as atcClearMessage } from "../features/visitor/visitorActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

function ATC({ subscribeSection }) {
  const [email, setEmail] = useState("");

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();
  const { atcLoading, successMessage:atcSuccessMessage, errorMessage:atcErrorMessage } = useSelector(
    (state) => state.visitor
  );

  // Save title and description in localStorage when subscribeSection updates
  useEffect(() => {
    if (subscribeSection?.title && subscribeSection?.description) {
      localStorage.setItem("subscribeTitle", subscribeSection.title);
      localStorage.setItem("subscribeDescription", subscribeSection.description);
    }
  }, [subscribeSection]);

  // Retrieve title and description from localStorage on component mount
  useEffect(() => {
    const storedTitle = localStorage.getItem("subscribeTitle");
    const storedDescription = localStorage.getItem("subscribeDescription");

    if (storedTitle) setTitle(storedTitle);
    if (storedDescription) setDescription(storedDescription);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Dismiss any currently visible toasts
    toast.dismiss();

    let data = {
      email: email,
    };

    dispatch(createSubscribe(data));
    setEmail("");
  };

  // Show success and error toasts
  useEffect(() => {
    if (atcSuccessMessage) {
      const timer = setTimeout(() => {
        toast.success(atcSuccessMessage, { autoClose: 3000, hideProgressBar: true });
        dispatch(atcClearMessage());
      }, 0);
  
      return () => clearTimeout(timer); // Cleanup timer
    }
  
    if (atcErrorMessage) {
      const timer = setTimeout(() => {
        toast.error(atcErrorMessage, { autoClose: 3000, hideProgressBar: true });
        dispatch(atcClearMessage());
      }, 0);
  
      return () => clearTimeout(timer); // Cleanup timer
    }
  }, [atcSuccessMessage, atcErrorMessage, dispatch]);

  return (
    <>
      <section className="act">
        <Row>
          <Col lg="12">
            <div className="act-box">
              <div className="act-box-text">
                <h3>{title || subscribeSection?.title}</h3> {/* Use stored title or prop */}
                <p>{description || subscribeSection?.description}</p> {/* Use stored description or prop */}
              </div>
              <div className="act-form">
                <Form className="subscriber-form" name="subscriber-form">
                  <Row>
                    <Col md={8} xl={9}>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Col>
                    <Col md={4} xl={3}>
                      <Link
                        type="submit"
                        onClick={handleSubmit}
                        className="cutsom-bttn"
                        disabled={atcLoading}
                      >
                        {atcLoading ? (
                          <FontAwesomeIcon
                            icon={faSpinner}
                            spin
                            size="lg"
                            className="spinner-icon"
                          />
                        ) : (
                          "Subscribe"
                        )}
                      </Link>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}

export default ATC;
