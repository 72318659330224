import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";

const ConfirmModal = ({ show, onConfirm, onCancel, message }) => (
  <Modal show={show} onHide={onCancel}>
    <Modal.Header closeButton>
      <Modal.Title>Confirm Action</Modal.Title>
    </Modal.Header>
    <Modal.Body>{message}</Modal.Body>
    <Modal.Footer>
      <Link className='danger-bttn me-2' onClick={onCancel}>
        Cancel
      </Link>
      <Button className='cutsom-bttn' onClick={onConfirm}>
        Confirm
      </Button>
    </Modal.Footer>
  </Modal>
);

export default ConfirmModal;
