import React, { useEffect, useState } from "react";
import Facebook from "../assets/images/facebook.svg";
import Twitter from "../assets/images/twitter.svg";
import Linkedin from "../assets/images/linkedin.svg";
import Instagram from "../assets/images/instagram.svg";
import Youtube from "../assets/images/youtube.svg";
import { Link } from "react-router-dom";

function Footer() {
  const [footerContent, setFooterContent] = useState(null);

  useEffect(() => {
    const checkLocalStorage = setInterval(() => {
      let localFooterContent = localStorage.getItem("footerSection");
      if (localFooterContent) {
        setFooterContent(JSON.parse(localFooterContent));
        clearInterval(checkLocalStorage); // Stop the interval once data is found
      }
    }, 1000); // Check every second

    return () => clearInterval(checkLocalStorage); // Cleanup on unmount
  }, []);

  return (
    <footer>
      <div className="footer-main">
        <div className="footer-box">
          <Link to="/" className="footer-logo">
            {footerContent?.bottom_logo && (
              <img
                src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${footerContent.bottom_logo}`}
                alt={footerContent.company_name}
              />
            )}
          </Link>
          <p>{footerContent?.footer_address}</p>
          <p>
            <a href={`tel:${footerContent?.office_phone_no}`}>
              {footerContent?.office_phone_no}
            </a>
          </p>
          <p>
            <a href={`mailto:${footerContent?.info_email}`}>
              {footerContent?.info_email}
            </a>
          </p>
        </div>
        <div className="footer-box">
          <h3>Company</h3>
          <ul>
            <li>
              <Link to="/about-us" title="About Us">
                About Us
              </Link>
            </li>
            <li>
              <Link to="/careers" title="Careers">
                Careers
              </Link>
            </li>
            <li>
              <Link to="/blogs" title="Blogs">
                Blogs
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-box">
          <h3>Customer Support</h3>
          <ul>
            <li>
              <Link to="/contact-us" title="Contact Us">
                Contact Us
              </Link>
            </li>
            <li>
              <Link to="/faqs" title="FAQ’s">
                FAQ’s
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-box">
          <h3>Legal Policies</h3>
          <ul>
            <li>
              <Link to="/page/terms-conditions" title="Terms & Conditions">
                Terms & Conditions
              </Link>
            </li>
            <li>
              <Link to="/page/privacy-policy" title="Privacy Policy">
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link to="/page/legal" title="Legal">
                Legal
              </Link>
            </li>
            <li>
              <Link to="/page/return-policy" title="Return Policy">
                Return Policy
              </Link>
            </li>
          </ul>
        </div>
        <div className="footer-box">
          <h3>My Account</h3>
          <ul>
            <li>
              <Link to="/sign-in" title="SignIn">
                Sign-In
              </Link>
            </li>
            <li>
              <Link to="/sign-up" title="SignUp">
                Sign-Up
              </Link>
            </li>
            <li>
              <Link to="/profile" title="Profile">
                Profile
              </Link>
            </li>
            <li>
              <Link to="/profile/my-orders" title="My Orders">
                My Orders
              </Link>
            </li>
            <li>
              <Link to="/profile/wishlist" title="Wishlist">
                Wishlist
              </Link>
            </li>
            <li>
              <Link to="/profile/address-book" title="Address Book">
                Address Book
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <div dangerouslySetInnerHTML={{__html: footerContent?.footer_copyright_text}}></div>
        <ul>
          {footerContent?.facebook_url && (
            <li>
              <a href={footerContent?.facebook_url} title="Facebook">
                <img src={Facebook} alt="Facebook" />
              </a>
            </li>
          )}

          {footerContent?.twitter_url && (
            <li>
              <a href={footerContent?.twitter_url} title="Twitter">
                <img src={Twitter} alt="Twitter" />
              </a>
            </li>
          )}

          {footerContent?.linkedin_url && (
            <li>
              <a href={footerContent?.linkedin_url} title="LinkedIn">
                <img src={Linkedin} alt="LinkedIn" />
              </a>
            </li>
          )}

          {footerContent?.instagram_url && (
            <li>
              <a href={footerContent?.instagram_url} title="Instagram">
                <img src={Instagram} alt="Instagram" />
              </a>
            </li>
          )}

          {footerContent?.youtube_url && (
            <li>
              <a href={footerContent?.youtube_url} title="YouTube">
                <img src={Youtube} alt="YouTube" />
              </a>
            </li>
          )}
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
