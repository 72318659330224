import React, { useState, useEffect } from "react";
import Header from '../component/Header'
import { Row, Col } from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import Google from '../assets/images/google-icon.svg'
import Facebook from '../assets/images/facebook-icon.svg'
import Apple from '../assets/images/apple-icon.svg'

import { useDispatch, useSelector } from "react-redux";
import { login } from "../features/auth/authActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify"; // Import toast
import { useNavigate } from 'react-router-dom';

import { PRIVACY_POLICY, TERMS_AND_CONDITIONS } from "../Constants";
import { Helmet } from 'react-helmet-async';

function SignIn() {

  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [password, setPassword] = useState("");
  const [keepMeLogin, setKeepMeLogin] = useState(false);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, successMessage, errorMessage } = useSelector((state) => state.auth);

  const validate = () => {
    const errors = {};

    // Check for empty fields
    if (!emailOrPhone) errors.emailOrPhone = "Email or Phone Number is required.";
    if (!password) errors.password = "Password is required.";
    
    // Check password length
    if (password && password.length < 8)
      errors.password = "Password must be at least 8 characters long.";

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Initial blank errors
    setErrors({});

    // Validate form inputs
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    // Dismiss any currently visible toasts
    toast.dismiss();

    // Dispatch registration action
    let data = {
      email_or_mobile: emailOrPhone,
      password: password,
      keep_me_login:keepMeLogin
    };
  
    dispatch(login(data));
  };


  // Show success and error toasts
  useEffect(() => {
    if (successMessage) {
      // toast.success(successMessage, { autoClose: 3000, hideProgressBar: true });
      navigate('/profile'); // Redirect to profile after 3 seconds
      window.location.reload();
    }
    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
    }
  }, [successMessage, errorMessage, navigate]);


  return (
    <>
      <Helmet>
        <title>Sign In | HealthGonia</title>
        <meta name="description" content="Access your HealthGonia account. Sign in to view your orders, manage your account, and more." />
        <meta name="keywords" content="Sign In, Login, HealthGonia, Account, Healthcare" />
      </Helmet>
      
      <Header />
      <div className='sign-in-main'>
        <div className='sign-box'>
          <div className='sign-box-wrap'>
            <Row>
              <Col lg="6">
                <h2>Sign-In</h2>
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="Email or Phone Number"
                      value={emailOrPhone}
                      onChange={(e) => setEmailOrPhone(e.target.value)}
                      isInvalid={!!errors.emailOrPhone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.emailOrPhone}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      isInvalid={!!errors.password}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.password}
                    </Form.Control.Feedback>
                  </Form.Group>


                  <Form.Group className="mb-3 form-group" controlId="formBasicCheckbox">
                    <Form.Check className='custom-checkbox'  checked={keepMeLogin ? "checked" : ""}
                      onChange={(e) => setKeepMeLogin(e.target.checked)} type="checkbox" label="Keep me signed in" />
                    <Link to="/forgot-password" className='forgot-password'> Forgot Password?</Link>
                  </Form.Group>

                  <Button
                    className="cutsom-bttn"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <FontAwesomeIcon
                        icon={faSpinner}
                        spin
                        size="lg"
                        className="spinner-icon"
                      />
                    ) : (
                      "Sign In"
                    )}
                  </Button>


                  <Form.Text>Need help signing in?</Form.Text>
                  <Form.Text className="or-text">OR</Form.Text>
                  <Link to="/sign-up" className='cutsom-bttn create-btn' >Create an Account</Link>
                </Form>

              </Col>
              <Col lg="6">
                <h2>Or Sign In With</h2>
                <p>By signing in through a third party, I represent that I am 18 years or older, I agree to the <Link to={TERMS_AND_CONDITIONS}>Terms and Conditions</Link> and acknowledge I have read the <Link to={PRIVACY_POLICY}>Privacy Policy.</Link></p>
                <Link className='cutsom-bttn login-btn'><img src={Google} alt="" /> Sign In with Google</Link>
                <Link className='cutsom-bttn login-btn'><img src={Facebook} alt="" /> Sign In with Facebook</Link>
                <Link className='cutsom-bttn login-btn'><img src={Apple} alt="" /> Sign In with Apple</Link>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  )
}
export default SignIn


