// src/features/wishlist/wishlistReducer.js
import { createReducer } from '@reduxjs/toolkit';
import {
    clearMessages,
    getWishlistProductsStart, getWishlistProductsSuccess, getWishlistProductsFailure,
    addRemoveWishlistStart, addRemoveWishlistSuccess, addRemoveWishlistFailure,
} from './wishlistActions';

const initialState = {
    wishlistItems: [],
    loading: false,
    successMessage: null,
    errorMessage: null,
};

const wishlistReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(clearMessages, (state) => {
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getWishlistProductsStart, (state) => {
            state.loading = true;
            state.wishlistItems = [];
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getWishlistProductsSuccess, (state, action) => {
            state.loading = false;
            state.wishlistItems = action.payload.data;
            state.successMessage = null;
        })
        .addCase(getWishlistProductsFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })

        .addCase(addRemoveWishlistStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(addRemoveWishlistSuccess, (state, action) => {
            state.loading = false;
            state.successMessage = action.payload.message;
        })
        .addCase(addRemoveWishlistFailure, (state, action) => {
            state.loading = false;
            state.successMessage = action.payload.message;
        });
});

export default wishlistReducer;
