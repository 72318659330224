// src/features/blog/blogReducer.js
import { createReducer } from '@reduxjs/toolkit';
import {
    clearMessages,
    getBlogCategoryStart, getBlogCategorySuccess, getBlogCategoryFailure,
    getBlogsStart, getBlogsSuccess, getBlogsFailure,
    getBlogDetailsStart, getBlogDetailsSuccess, getBlogDetailsFailure,
} from './blogActions';

const initialState = {
    blogCategories: null,
    blogs: null,
    blogDetails: null,
    loading: false,
    successMessage: null,
    errorMessage: null,
};

const blogReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(clearMessages, (state) => {
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getBlogCategoryStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getBlogCategorySuccess, (state, action) => {
            state.loading = false;
            state.blogCategories = action.payload.data;
            state.successMessage = action.payload.message;
        })
        .addCase(getBlogCategoryFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })
        .addCase(getBlogsStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getBlogsSuccess, (state, action) => {
            state.loading = false;
            state.blogs = action.payload.data;
            state.successMessage = action.payload.message;
        })
        .addCase(getBlogsFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        })
        .addCase(getBlogDetailsStart, (state) => {
            state.loading = true;
            state.successMessage = null;
            state.errorMessage = null;
        })
        .addCase(getBlogDetailsSuccess, (state, action) => {
            state.loading = false;
            state.blogDetails = action.payload.data;
            state.successMessage = action.payload.message;
        })
        .addCase(getBlogDetailsFailure, (state, action) => {
            state.loading = false;
            state.errorMessage = action.payload.message;
        });
});

export default blogReducer;
