import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { clearMessages, getFaqs } from "../features/visitor/visitorActions";

import { toast } from "react-toastify"; // Import toast

function FAQList() {
  const dispatch = useDispatch();
  const { errorMessage, faqResponse } = useSelector((state) => state.visitor);

  useEffect(() => {
    let data = { page: 1, limit: 100 };
    dispatch(getFaqs(data));
  }, [dispatch]);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
      dispatch(clearMessages()); // Reset the messages after showing the toast
    }
  }, [errorMessage, dispatch]);

  return (
    <>
      <section className="faqs">
        <div className="common-header">
          <h2>Frequently Asked Questions</h2>
          <p>
            We’ve compiled a list of some of the most frequently asked questions
            below. Don't
            <br /> see your question below? Reach out - we'd love to hear from
            you!
          </p>
          <Link to="/contact-us" className="cutsom-bttn" title="">
            Contact Us
          </Link>
        </div>
        {faqResponse?.data?.length > 0 ? (
          <>
            {faqResponse?.data?.map((faqCategory, categoryIndex) => (
              <div
                key={faqCategory.id}
                className={`faqs-main ${
                  categoryIndex % 2 === 1 ? "bg-faq-light" : ""
                }`}
              >
                <Row>
                  <Col>
                    <div className="faqs-wrap">
                      <h2>{faqCategory.title}</h2>
                      <Accordion defaultActiveKey="0">
                        {faqCategory.faqs.map((faq, index) => (
                          <Accordion.Item
                            eventKey={index.toString()}
                            key={faq.id}
                          >
                            <Accordion.Header>
                              <span>
                                {index + 1 < 10 ? `0${index + 1}` : index + 1}
                              </span>
                              {faq.question}
                            </Accordion.Header>
                            <Accordion.Body>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: faq.answer,
                                }}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          </>
        ) : (
          <>
            {Array(3)
              .fill(0)
              .map((_, categoryIndex) => (
                <div
                  key={categoryIndex}
                  className={`faqs-main`}
                >
                  <Row>
                      <Skeleton height={80} width="100%" />
                  </Row>
                </div>
              ))}
          </>
        )}
      </section>
    </>
  );
}

export default FAQList;
