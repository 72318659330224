import React, {  useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/thumbs'
import { Navigation, Thumbs } from 'swiper/modules'
import SwiperCore from 'swiper'

import TestimonialIcon from '../assets/images/testimonial-icon.svg'
import TestimonialProductImg from '../assets/images/testimonial-product-img.png'
import TestimonialProductArrow from '../assets/images/testimonial-product-arrow.svg'
import testimonialUser1 from '../assets/images/testimonial-user-1.png'
import testimonialUser2 from '../assets/images/testimonial-user-2.png'
import testimonialUser3 from '../assets/images/testimonial-user-3.png'
import testimonialUser4 from '../assets/images/testimonial-user-4.png'
import testimonialUser5 from '../assets/images/testimonial-user-5.png'

// Initialize Swiper core
SwiperCore.use([Navigation, Thumbs]);

function Testimonials() {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

  return (
    <section className="testimonials">
      <div className="common-header">
        <p>What people say!</p>
        <h2>Testimonials from Satisfied <span>Clients</span>.</h2>
      </div>
      <div className="testimonials-slider">
        <Swiper
          slidesPerView={1}
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = navigationPrevRef.current;
            swiper.params.navigation.nextEl = navigationNextRef.current;
          }}
          thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
          loop={true}
          className="main-swiper"
        >
          <SwiperSlide key="slide1">
              <div className="testimonials-box">
                <span><img src={TestimonialIcon} alt="" /></span>
                <div className='product-details'>
                  <div className="product-left">
                    <img src={TestimonialProductImg} alt="" />
                  </div>
                  <div className="product-right">
                    <h3>Alive!® Zero Sugar Women's Gummy Multivitamin</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                    <a href="/" className='cutsom-bttn'>Shop Now <img src={TestimonialProductArrow} alt="" /></a>
                  </div>
                </div>
                <div className="testimonials-user-details">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    <h3>Sophia Martinez</h3>
                    <span>Project Manager</span>
                </div>
              </div>
          </SwiperSlide>
          <SwiperSlide key="slide2">
            <div className="testimonials-box">
              <span><img src={TestimonialIcon} alt="" /></span>
              <div className='product-details'>
                <div className="product-left">
                  <img src={TestimonialProductImg} alt="" />
                </div>
                <div className="product-right">
                  <h3>Alive!® Zero Sugar Women's Gummy Multivitamin</h3>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                  <a href="/" className='cutsom-bttn'>Shop Now <img src={TestimonialProductArrow} alt="" /></a>
                </div>
              </div>
              <div className="testimonials-user-details">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                  <h3>Sophia Martinez</h3>
                  <span>Project Manager</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide key="slide3">
            <div className="testimonials-box">
              <span><img src={TestimonialIcon} alt="" /></span>
              <div className='product-details'>
                <div className="product-left">
                  <img src={TestimonialProductImg} alt="" />
                </div>
                <div className="product-right">
                  <h3>Alive!® Zero Sugar Women's Gummy Multivitamin</h3>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                  <a href="/" className='cutsom-bttn'>Shop Now <img src={TestimonialProductArrow} alt="" /></a>
                </div>
              </div>
              <div className="testimonials-user-details">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                  <h3>Sophia Martinez</h3>
                  <span>Project Manager</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide key="slide4">
            <div className="testimonials-box">
              <span><img src={TestimonialIcon} alt="" /></span>
              <div className='product-details'>
                <div className="product-left">
                  <img src={TestimonialProductImg} alt="" />
                </div>
                <div className="product-right">
                  <h3>Alive!® Zero Sugar Women's Gummy Multivitamin</h3>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                  <a href="/" className='cutsom-bttn'>Shop Now <img src={TestimonialProductArrow} alt="" /></a>
                </div>
              </div>
              <div className="testimonials-user-details">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                  <h3>Sophia Martinez</h3>
                  <span>Project Manager</span>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide key="slide5">
              <div className="testimonials-box">
                <span><img src={TestimonialIcon} alt="" /></span>
                <div className='product-details'>
                  <div className="product-left">
                    <img src={TestimonialProductImg} alt="" />
                  </div>
                  <div className="product-right">
                    <h3>Alive!® Zero Sugar Women's Gummy Multivitamin</h3>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                    <a href="/" className='cutsom-bttn'>Shop Now <img src={TestimonialProductArrow} alt="" /></a>
                  </div>
                </div>
                <div className="testimonials-user-details">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    <h3>Sophia Martinez</h3>
                    <span>Project Manager</span>
                </div>
              </div>
          </SwiperSlide>
          <div className='swiper-button-nav'>
            <div ref={navigationPrevRef} className="swiper-button-prev"></div>
            <div ref={navigationNextRef} className="swiper-button-next"></div>
          </div>
        </Swiper>
        {/* Thumbnail Slider */}
        <Swiper
          onSwiper={setThumbsSwiper}
          spaceBetween={10}
          slidesPerView={5}
          freeMode={true}
          watchSlidesProgress={true}
          loop={true}
          className="thumbs-swiper"
          breakpoints={{
            320: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 3,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1200: {
              slidesPerView: 5,
              spaceBetween: 40,
            },
          }}
        >
          <SwiperSlide key="thumb1">
            <img src={testimonialUser1} alt="Thumbnail 1" />
          </SwiperSlide>
          <SwiperSlide key="thumb2">
            <img src={testimonialUser2} alt="Thumbnail 2" />
          </SwiperSlide>
          <SwiperSlide key="thumb3">
            <img src={testimonialUser3} alt="Thumbnail 3" />
          </SwiperSlide>
          <SwiperSlide key="thumb4">
            <img src={testimonialUser4} alt="Thumbnail 4" />
          </SwiperSlide>
          <SwiperSlide key="thumb5">
            <img src={testimonialUser5} alt="Thumbnail 5" />
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  );
}

export default Testimonials;
