// src/services/cartService.js
import axios from 'axios';
// import { toFormData } from './utils'; // Utility function
import { setAuthToken } from './setAuthToken';

const checkoutService = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Assuming you have a token from somewhere (e.g., after login)
const token = localStorage.getItem('token') || sessionStorage.getItem('token');
setAuthToken(checkoutService, token);

// Get Cart from Local Storage
export const getLocalOrderService = () => {
    const checkoutOrder = localStorage.getItem('checkoutOrder');
    return checkoutOrder ? JSON.parse(checkoutOrder) : [];
};

// Save Cart to Local Storage
export const saveOrderToLocalService = (checkoutOrder) => {
    localStorage.setItem('checkoutOrder', JSON.stringify(checkoutOrder));
};

export const clearOrderFromLocalService = async () => {
    localStorage.removeItem('checkoutOrder'); // Or whichever key you're using
    localStorage.removeItem('cart');
};