// src/services/visitorService.js
import axios from 'axios';
import { toFormData } from './utils'; // Utility function

const visitorService = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const createSubscribe = (subscribeData) => {
    const formData = toFormData(subscribeData);
    return visitorService.post('/subscribers/create', formData);
};

export const createContactInquiry = (subscribeData) => {
    const formData = toFormData(subscribeData);
    return visitorService.post('/inquiry/contact-create', formData);
};

export const getFaqs = (data) => {
    const formData = toFormData(data);
    return visitorService.post('/faqs', formData);
};

export const getPageContent = (data) => {
    const formData = toFormData(data);
    return visitorService.post('/page-contents', formData);
};

export const getSliders = (data) => {
    const formData = toFormData(data);
    return visitorService.post('/sliders', formData);
};

export const getTestimonials = (data) => {
    const formData = toFormData(data);
    return visitorService.post('/testimonials', formData);
};

export const getAdvertise = (data) => {
    const formData = toFormData(data);
    return visitorService.post('/advertises', formData);
};



export default visitorService;
