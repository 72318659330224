import React, { useEffect, useState } from "react";
import Header from '../component/Header'
import SubBanner from '../component/SubBanner' 
import LegalPoliciesComp from '../component/LegalPoliciesComp' 
import ATC from '../component/ATC' 

import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { clearMessages, getPageContent } from "../features/visitor/visitorActions";

import { toast } from "react-toastify"; // Import toast
import { Helmet } from 'react-helmet-async';

function LegalPolicies() {

  // Get the slug from the URL
  const { slug } = useParams();
  const [legalPageData, setLegalPageData] = useState(null);

  const dispatch = useDispatch();
  const { errorMessage, legalPageResponse } = useSelector((state) => state.visitor);

  useEffect(() => {
    let data = { code: slug };
    dispatch(getPageContent(data));
  }, [dispatch, slug]);

  useEffect(() => {
    if (legalPageResponse) {
      setLegalPageData(legalPageResponse);
    }
  }, [legalPageResponse]);


  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage, { autoClose: 3000, hideProgressBar: true });
      dispatch(clearMessages()); // Reset the messages after showing the toast
    }
  }, [errorMessage, dispatch]);


  return (
    <>
      <Helmet>
        {legalPageData && (
        <title>{legalPageData?.page_name} | HealthGonia</title>
        )}
        <meta name="description" content="Discover top-quality healthcare products and services at HealthGonia. Your health and well-being are our top priorities. Shop now!" />
        <meta name="keywords" content="Healthcare, HealthGonia, Health Products, Wellness, Medical Services" />
      </Helmet>

      <div className='main legal-policies-page'>
        <Header/>
        <SubBanner HeaderTitle={legalPageData?.page_name} HeaderSubtitle=""/>  
        <LegalPoliciesComp legalPageData={legalPageData} slug={slug} />      
        <ATC />
      </div>
    </>
  )
}
export default LegalPolicies


