// src/services/orderService.js
import axios from 'axios';
import { toFormData } from './utils'; // Utility function
import { setAuthToken } from './setAuthToken';

const orderService = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Assuming you have a token from somewhere (e.g., after login)
const token = localStorage.getItem('token') || sessionStorage.getItem('token');
setAuthToken(orderService, token);

export const createOrderService = (orderData) => {
    const formData = toFormData(orderData);
    return orderService.post('/order/create', formData);
};

export const getOrderListService = (data) => {
    const formData = toFormData(data);
    return orderService.post('/order/list', formData);
};

