import React from 'react'
import { Row, Col } from 'react-bootstrap'

function WhyChooseUs({whyChooseUsSection}) {

  if(!whyChooseUsSection){
    return null;
  }

  return (
    <>
     <section className='why-choose-us'>
        <div className="why-choose-us-wrap">
          <div className="common-header">
            <h2>{whyChooseUsSection?.title}</h2>
            <p>{whyChooseUsSection?.description}</p>
          </div>
          <Row >
              
            {whyChooseUsSection?.data?.length > 0 && (
              <>
                {whyChooseUsSection.data.map((row, index) => (
                <Col lg="4" key={index}>
                  <div className="why-choose-us-box">
                    <div className="why-choose-us-box-img">
                      <img src={`${process.env.REACT_APP_PHP_BACKEND_MEDIA_URL}${row.image}`} alt="" />
                    </div>
                    <h3>{row.title}</h3>
                    <p>{row.description}</p>
                  </div>
                </Col>
                ))}
              </>
            )}
              
          </Row>
        </div>
     </section>     
    </>
  )
}
export default WhyChooseUs