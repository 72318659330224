// src/services/userService.js
import axios from 'axios';
import { toFormData } from './utils'; // Utility function
import { setAuthToken } from './setAuthToken';

const userService = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

// Assuming you have a token from somewhere (e.g., after login)
const token = localStorage.getItem('token') || sessionStorage.getItem('token');
setAuthToken(userService, token);

export const getProfile = () => {
    return userService.get('/user/profile');
};

export const updateProfile = (profileData) => {
    const formData = toFormData(profileData);
    return userService.post('/user/update', formData);
};

export const changePassword = (passwordData) => {
    const formData = toFormData(passwordData);
    return userService.post('/user/change-password', formData);
};

export const changeEmail = (emailData) => {
    const formData = toFormData(emailData);
    return userService.post('/user/update-email', formData);
};

export const getAddresses = (data) => {
    const formData = toFormData(data);
    return userService.post(`/user/addresses`, formData);
};

export const addAddresses = (addressesData) => {
    const formData = toFormData(addressesData);
    return userService.post('/user/address/create', formData);
};

export const updateAddresses = (addressesData) => {
    const formData = toFormData(addressesData);
    return userService.put('/user/address/update/'+addressesData.id, formData);
};

export const deleteAddresses = (addressesData) => {
    const formData = toFormData(addressesData);
    return userService.delete('/user/address/delete/'+addressesData.id, formData);
};

export default userService;
